import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";


export const themeDark = createTheme({
    palette: {
        background: {
            default: "#0a0509"
        },
        text: {
            primary: "#ffffff"
        },
    },
    typography: {
        fontFamily: "'arial', 'Texturina', sans-serif",
    },
    components: {
        MuiAppBar: {
            defaultProps: {
                style: {
                    background: grey[900]
                }
            }
        }
    }
});
