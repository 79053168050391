import DialogContentText from '@mui/material/DialogContentText';
import { useState, forwardRef, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Button } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import TextField from "@mui/material/TextField";
import Close from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Save from "@mui/icons-material/Save";
import { mToast } from 'Root/Common/Toast';
import Dialog from '@mui/material/Dialog';
import Switch from "@mui/material/Switch";
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import { root } from "Root/root";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateMTMModal({ open = false, handleClose = () => undefined, userId = undefined }) {

	const [schema, setSchema] = useState([
		{ label: "Limit MTM", key: "limitMtm", type: "checkbox", value: false, optional: false, disableInMkt: false },
		{ 
			label: "Min MTM", key: "minMtm", type: "number", value: 0, optional: false, disableInMkt: false, isNotVisible: true, 
			InputProps: { inputProps: { max: 0, min: -999999 } } },
		{ 
			label: "Max MTM", key: "maxMtm", type: "number", value: 0, optional: false, disableInMkt: false, isNotVisible: true, 
			InputProps: { inputProps: { min: 0 } } },
		{ 
			label: "Include Manual", key: "inclManual", type: "checkbox", value: false, optional: false, disableInMkt: false,
			toolTipText: "Whether to include the manual trades while calculating MTM for the limit check."
		}
	]);
    const [, setIsPermsChecked] = useState(true);

	const EnableDisableSwitchElem = styled(Switch)(() => ({
		padding: 8,
		'& .MuiSwitch-track': {
			borderRadius: 22,
			backgroundColor: "#C62828", 
			opacity: 1,
	
			'&:before, &:after': {
				content: '""',
				position: 'absolute',
				top: '50%',
				transform: 'translateY(-50%)',
				width: 16,
				height: 16,
			},
		},
		'& .MuiSwitch-thumb': {
			width: 16,
			height: 16,
			margin: 2,
			backgroundColor: "#E0E0E0"
		},
		'& .MuiSwitch-switchBase': {
			'&.Mui-checked': {
				'& + .MuiSwitch-track': {
					backgroundColor: '#2E7D32',
					opacity: 1
				},
			},
		},
	}));

	const EnableDisableSwitch = ({ initialValue, onChangeInputs, inputIndex, isChecked }) => {

        useEffect(() => {
            setIsPermsChecked(initialValue);
        }, [initialValue])  
      
        const handleChange = (e) => {
            setIsPermsChecked(e.target.checked);
            onChangeInputs(e, inputIndex);
        };
      
        return (
            <EnableDisableSwitchElem
                checked={isChecked}
                onChange={handleChange}
            />
        );
    };

	const showHideMtm = (isVisible=true) => {
		schema.forEach(schInp => {
			if (["minMtm", "maxMtm", "inclManual"].includes(schInp.key)) {
				schInp.isNotVisible = isVisible
			}
		})
    }

	const onChangeInputs = (e, inputIndex) => {
        const schemaObj = schema[inputIndex]

        if (schemaObj.type === "checkbox") {
            schemaObj.value = e.target.checked

            if (schemaObj.key === "limitMtm") {
                showHideMtm(!schemaObj.value)
            }
        }
        else {
            schemaObj.value = e.target.value
        }

        setSchema([...schema])
    }

	const saveLimit = () => {
		const payload = {}

		for (let input of schema) {
			payload[input.key] = input.value
        }

		root.apis.users.edit(userId, payload)
		.then((data) => {
			if (data.data.msg) {
				mToast.success("Updated MTM limits successfully!")
			}
		})
		.catch(err => {
			if (err.response) {
				err = err.response.data.err
			}
			else {
				err = err.statusText
			}

			mToast.error(err)
		})
	}

	useEffect(() => {
		root.apis.users.get(userId)
		.then(data => {
			const mtmObj = data.data.mtm

			const assignDataToSchema = (prevSchema) => {
				var isVisibleMtm = false

				if (mtmObj) {
					prevSchema.forEach(input => {
						const value = mtmObj[input.key]

						if (value) {
							input.value = value
						}
						
						if (input.key === "limitMtm") {
							isVisibleMtm = input.value
						}
					})
				}

				showHideMtm(!isVisibleMtm)

				return [...prevSchema]
			}

			if ("err" in data.data) {
				mToast.error(data.data.err)

			} else {
				setSchema(assignDataToSchema)
			}
		})
		.catch(err => {
			console.log("getUser error = ", userId, err)
			mToast.error(err.statusText)
		})
	}, [userId])

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			PaperProps={{
				sx: {
					minWidth: "40%",
					minHeight: "30%",
					color: "#000"
				}
			}}
		>
			<DialogTitle>
				<Box display="flex" alignContent="center" justifyContent="space-between">
					<Box>Update MTM Limit At Account Level</Box>
					<Box><IconButton onClick={handleClose}><Close /></IconButton></Box>
				</Box>
				<Box
					fontSize={14}
				>You have the option to define minimum and maximum thresholds for both loss and profit right here for your account.</Box>
			</DialogTitle>

			<DialogContent>
				<Box
					sx={{
						width: '100%',
						marginTop: 2
					}}
					className="custom-inputs"
					noValidate
					autoComplete="off"
				>
					<Grid container spacing={{ xs: 2, md: 4 }}>
						{
							schema.map(({isNotVisible, ...input}, inputIndex) => {

								if (isNotVisible) return ("");

								return (
									<Grid
										key={input.label}
										item
										xs={12}
										sm={6}
										md={4}
									>

										{
											input.type === "checkbox" ?
												<FormGroup>
													<FormControlLabel
														control={
															<EnableDisableSwitch 
																initialValue={input.value} onChangeInputs={onChangeInputs} 
																inputIndex={inputIndex} isChecked={input.value}
															/>
														}
														label={
															<Box display="flex">
																{input.label}
																<Box ml={1} mt={0.5}>
																	{
																		input.toolTipText ?
																		<Tooltip title={input.toolTipText}> 
																			<HelpOutlineIcon fontSize="small" />
																		</Tooltip>    
																		: ""
																	}
																</Box>
															</Box>
														}
													/>
												</FormGroup>
											:
												<TextField
													fullWidth
													onChange={(e) => { onChangeInputs(e, inputIndex) }}
													value={input.value ?? ""}
													variant="standard"
													disabled={input.isDisabled}
													{...input}
												/>
										}

									</Grid>
								)
							})
						}
					</Grid>
				</Box>

				<Box display={'flex'} justifyContent={'end'} gap={5} mt={2}>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<Save />}
                        onClick={saveLimit}
                    >
                        Save
                    </Button>
                </Box>

			</DialogContent>
		</Dialog>
	);
}
