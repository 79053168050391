import { SectionHeading } from "helpers/SectionHeading";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import yellow from "@mui/material/colors/yellow";
import green from "@mui/material/colors/green";
import grey from "@mui/material/colors/grey";
import Tooltip from "@mui/material/Tooltip";
import red from "@mui/material/colors/red";
import Button from "@mui/material/Button";
import { Table } from "helpers/Table";
import Box from "@mui/material/Box";
import moment from "moment/moment";
import { ExportMultiSheetsXLSX } from "helpers/ExportXLSX";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from "react";

// orderBookObj={
//     "time": "14:25:00",
//     "symbol": "NIFTYBANK",
//     "side": "Sell",
//     "mType": "Market 1",
//     "stg": "S-6",
//     "qty": 7,
//     "price": 2006,
//     "status": "Completed",
//     "statusInfo": "Status Info - NIFTYBANK"
// }

export const orderBookColumns = [
    { id: "date", label: "Date", format(value){ return moment(value).format("YYYY-MM-DD")} },
    { id: "time", label: "Time", format(value){ return moment(value).format("hh:mm:ss a")} },
    // { id: "exchange", label: "Exchange", align: "center" },
    { id: "symbol", label: "(Exchange) Symbol", format(value, rowData={}) {
        return `(${rowData.exchange}) ${value}`
    } },
    { id: "side", label: "(Type) Side - Qty", format(value, rowData={}) {
        return `(${rowData.mType.toUpperCase()}) ${value} - ${rowData.qty}`
    } },
    // { id: "mType", label: "Type", align: "center", format(value) { return value.toUpperCase() } },
    { id: "stg", label: "Strategy", align: "center" },
    // { id: "qty", label: "Qty", align: "center" },
    { id: "oRefId", label: "Order Ref ID", align: "center" },
    // { id: "isEntry", label: "Entry/Exit", align: "center", format(value) { return value ? "ENTRY" : "EXIT" } },
    { id: "tag", label: "Tag", align: "center", format(value) { return value.toUpperCase() } },
    { id: "tagInfo", label: "Remarks", align: "left" },
    // { 
    //     id: "tag", 
    //     label: "Remarks",
    //     align: "center",
    //     format(value, rowData={}) {
    //         value = value.toUpperCase()

    //         return(
    //             rowData.tagInfo ?
    //                 <Tooltip
    //                     title={rowData.tagInfo}
    //                 >
    //                     <Box style={{ display: "flex", justifyContent: "center" }}>
    //                         { value }
    //                         <ErrorOutlineIcon style={{ transform: "scale(.8) rotate(180deg)" }} />
    //                     </Box>
    //                 </Tooltip>
    //             : value
    //         )
    //     }
    // },
]

export const bOrderBookColumns = [
    { id: "date", label: "Date", format(value){ return moment(value).format("YYYY-MM-DD")} },
    { id: "time", label: "Order Received Time", format(value){ return moment(value).format("hh:mm:ss a")} },
    { id: "exchange", label: "Exchange", align: "center" },
    { id: "symbol", label: "Symbol" },
    { id: "side", label: "Side" },
    { id: "mType", label: "Type", align: "center", format(value) { return value.toUpperCase() } },
    { id: "stg", label: "Strategy", align: "center" },
    { id: "qty", label: "Qty", align: "center", format(value, row) {return <Box>{row.cumQty} / {value}</Box>} },
    { id: "oRefId", label: "Order Ref ID", align: "center" },
    { 
        id: "boStatus", 
        label: "Status",
        align: "center",
        format(value, rowData={}) {
            value = value.toUpperCase()

            return(
                rowData.statusInfo ?
                    <Tooltip
                        title={rowData.statusInfo}
                    >
                        <Box style={{ display: "flex", justifyContent: "center" }}>
                            { value }
                            <ErrorOutlineIcon style={{ transform: "scale(.8) rotate(180deg)" }} />
                        </Box>
                    </Tooltip>
                : value
            )
        }
    },
]

export const tradeBookColumns = [
    { id: "date", label: "Date", format(value){ return moment(value).format("YYYY-MM-DD")} },
    { id: "time", label: "Time", format(value){ return moment(value).format("hh:mm:ss a")} },
    { id: "exchange", label: "Exchange", align: "center" },
    { id: "symbol", label: "Symbol" },
    { id: "side", label: "Side" },
    { id: "mType", label: "Type", align: "center", format(value) { return value.toUpperCase() } },
    { id: "stg", label: "Strategy", align: "center" },
    { id: "qty", label: "Qty", align: "center" },
    { id: "price", label: "Price", align: "center" },
    { id: "oRefId", label: "Order Ref ID", align: "center" },
    { 
        id: "status", 
        label: "Status",
        align: "center",
        format(value, rowData={}) {
            value = value.toUpperCase()
            return(
                rowData.statusInfo ?
                    <Tooltip
                        title={rowData.statusInfo}
                    >
                        <Box style={{ display: "flex", justifyContent: "center" }}>
                            { value }
                            <ErrorOutlineIcon style={{ transform: "scale(.8) rotate(180deg)" }} />
                        </Box>
                    </Tooltip>
                : value
            )
        }
    },
]

export function OrderBook({ 
    orderCount=0, bOrderCount=0, tradeCount=0, orderBookData=[], bOrderBookData=[], tradeBookData=[], isHiddenOrderBookList=false, setIsHiddenOrderBookList=()=>undefined, 
    isHiddenBOrderBookList=false, setIsHiddenBOrderBookList=()=>undefined, isHiddenTradeBookList=false, setIsHiddenTradeBookList=()=>undefined 
}){
    const [activeTab, setActiveTab] = useState(false);
    
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)

        if (newValue === "1") {
            setIsHiddenOrderBookList(!isHiddenOrderBookList)
            setIsHiddenTradeBookList(false)
            setIsHiddenBOrderBookList(false)
        }
        
        if (newValue === "2") {
            setIsHiddenTradeBookList(!isHiddenTradeBookList)
            setIsHiddenOrderBookList(false)
            setIsHiddenBOrderBookList(false)
        }

        if (newValue === "3") {
            setIsHiddenBOrderBookList(!isHiddenBOrderBookList)
            setIsHiddenTradeBookList(false)
            setIsHiddenOrderBookList(false)
        }
    }

    const renderTable = (colsData, rowsData) => {
        return (
            <Box>
                <Table 
                    columns={colsData}
                    rows={rowsData}
                    rowAttr={(row)=>{
                        if (row.status) {
                            const status = row.status.toLowerCase()
                            const side = row.side.toLowerCase()
                            var value = ""
                            
                            if (status === "completed") {
                                if (side === "buy") {
                                    value = green[600]
                                }
                                else {
                                    value = red[600]
                                }
                            } 
    
                            else if (status === "rejected") {
                                value = yellow[800]
                            }
    
                            else {
                                value = grey[600]
                            }
                        }
                        else {
                            const side = row.side.toLowerCase()

                            if (side === "buy") {
                                value = green[600]
                            }
                            else {
                                value = red[600]
                            }
                        }

                        return {
                            sx: {
                                bgcolor: value,
                            },
                            hover: false
                        }
                    }}
                />
            </Box>
        )
    }

    return(
        <Box>
            <SectionHeading
                // heading={"Orderbook "+"("+String(orderCount)+")"}
                withDivider={true}
            >
                <Box sx={{ width: activeTab ? '90%' : '100%' }}>
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        // textColor='inherit'
                    >
                        <Tab value="3" label={"Orderbook "+"("+String(bOrderCount)+")"} style={{ color: "White" }} />
                        <Tab value="2" label={"Tradebook "+"("+String(tradeCount)+")"} style={{ color: "White" }} />
                        <Tab value="1" label={"Propbook "+"("+String(orderCount)+")"} style={{ color: "White" }} />
                    </Tabs>
                </Box>

                <Box>
                    <Button
                        variant="contained"
                        sx={{ textTransform: "initial", my: 1 }}
                        size="small"
                        onClick={()=>{
                            const tradeColumns = [...tradeBookColumns.map(column => column.label), "Status Info"]
                            const tradeRows = [...tradeBookColumns.map(column=>column.id), "statusInfo"]
                            const orderColumns = orderBookColumns.map(column => column.label)
                            const orderRows = orderBookColumns.map(column=>column.id)
                            
                            ExportMultiSheetsXLSX("trades_n_orders", [
                                {
                                    columns: tradeColumns,
                                    rows: tradeBookData.map(log=>tradeRows.map(column=>log[column])),
                                    sheetName: "trade_book"
                                },
                                {
                                    columns: orderColumns,
                                    rows: orderBookData.map(log=>orderRows.map(column=>log[column])),
                                    sheetName: "order_book"
                                }
                            ])
                        }
                        } 
                    >
                        Export
                    </Button>

                    { activeTab ?
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ textTransform: "initial", my: 1, ml: 2 }}
                            onClick={() => {
                                setIsHiddenTradeBookList(false)
                                setIsHiddenOrderBookList(false)
                                setIsHiddenBOrderBookList(false)
                                setActiveTab(false)
                            }}
                        >
                            Hide
                        </Button>
                    : "" }
                </Box>
            </SectionHeading>
            {
                isHiddenTradeBookList ? renderTable(tradeBookColumns, tradeBookData) : null
            }
            {
                isHiddenOrderBookList ? renderTable(orderBookColumns, orderBookData) : null
            }
            {
                isHiddenBOrderBookList ? renderTable(bOrderBookColumns, bOrderBookData) : null
            }
        </Box>
    )
}