import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { useMarketWatchColumns } from "../common/useMarketWatchColumns";
import { Box, Button, IconButton, TextField } from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useMemo, forwardRef } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import CommitIcon from '@mui/icons-material/Commit';
import SaveIcon from '@mui/icons-material/Save';
import Close from "@mui/icons-material/Close";
import { marketWatch } from "redux/Reducer";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Table } from "helpers/Table";


export function PreviewOfOrderDialog({ 
    open = false, rows = [], dispatch = () => undefined, onClose = () => undefined, handleOnSaveTemplate = () => undefined, handleExecuteOrder = () => undefined, 
    tmptName = null, orderTag = null, setTmptName = () => undefined, setOrderTag = () => undefined, mktUsers = []
}) {
    const columns = useMarketWatchColumns({
        exceptColumns: ["selected", "ltp", "delta", "gamma", "theta", "iv", "vega", "delete", "sell"],
        customOptions: [
            { id: "exchange", align: "left", format(value) { return <Box ml={2}>{value}</Box>}},
            { id: "qty", align: "center", format(value) { return value } },
            { id: "buy", "label": "Side", format(_, row) { return <Box mr={2}>{row.isBuy === true ? <Box color="green">Buy</Box> : row.isBuy === false ? <Box color="red">Sell</Box> : ""}</Box> } },
        ],
        columnsOrder: ["exchange", "symbol", "qty", "buy"]
    })

    const Transition = useMemo(() => forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    }), [])
    const selUsersList = mktUsers.filter(user => user.selected).map(user => user.value)

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { }}
            scroll="paper"
            maxWidth="md"
            PaperProps={{
                sx: {
                    minHeight: "80%",
                    minWidth: "60%",
                    color: "#000",
                    "& table": {
                        "thead tr th": {
                            p: 2
                        },
                        "tbody tr td": {
                            color: "#000",
                            py: 2
                        }
                    },
                    "& button": {
                        textTransform: "initial"
                    },
                    "& .mk-flex": {
                        mt: 3,
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 2,
                        "input": {
                            color: "black",
                        },
                    }
                }
            }}
        >
            <DialogTitle>
                <Box display="flex" alignContent="center" justifyContent="space-between">
                    <Box>Preview Of Order</Box>
                    <Box><IconButton onClick={onClose}><Close /></IconButton></Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Table
                        columns={columns}
                        rows={rows.filter(col => 'boolean' === typeof col.isBuy).map(row => {
                            return {...row, user: selUsersList.join(", ")}
                        })}
                    />

                </Box>
                <Box className="mk-flex">
                    <Box>
                        <Button disabled={!rows.some(r => 'boolean' === typeof r.isBuy)} onClick={() => dispatch(marketWatch.actions.setData({ type: "reverse" }))} variant="contained" color="warning" startIcon={<FlipCameraAndroidIcon />}>Reverse Trade</Button>
                    </Box>
                    <Box display="flex" alignItems="center" gap={2}>
                        <TextField
                            size='small'
                            label="Order Tag"
                            value={orderTag}
                            onChange={(e) => { setOrderTag(e.target.value) }}
                        />
                        <Button onClick={handleExecuteOrder} variant="contained" color="success" startIcon={<CommitIcon />}>Execute Order</Button>
                    </Box>
                </Box>
            </DialogContent>
            {/* <DialogActions>
                <Box
                    sx={{
                        width: "100%",
                        px: 2
                    }}
                >
                    <Box className="mk-flex" style={{ justifyContent: "end" }}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <TextField
                                size='small'
                                label="Template Name"
                                value={tmptName}
                                onChange={(e) => { setTmptName(e.target.value) }}
                            />
                            <Button onClick={handleOnSaveTemplate} variant="contained" startIcon={<SaveIcon />}>Save Template</Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions> */}
        </Dialog>
    );
}