import { useCustomSearchbar } from "../common/useCustomSearchbar";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { DropDown } from "helpers/DropDown";
import { marketWatch } from "redux/Reducer";
import { mToast } from "Root/Common/Toast";
import { Box } from "@mui/material";
import { root } from "Root/root";

export function SymbolsDropdown({ excInst = [], setExcInst = () => undefined }) {
    const ref = useRef({
        searchDelay: null,
        defaultSym: []
    })
    const dispatch = useDispatch()
    const CustomSearchbar = useCustomSearchbar()
    const selExcSeg = useSelector(selector => selector.marketWatch.selectedExchange)

    const [searchVal, setSearchVal] = useState("")

    const getSymbols = ({ isDefault=false, value }) => {
        root.apis.marketWatch.getSymbols(selExcSeg, value)
        .then(data => {
            const _data = (data.data || []).map((elem) => {
                return { label: elem.name, value: elem.name, id: elem.excInstId, data: elem }
            })

            setExcInst(_data)

            if (isDefault) {
                ref.current.defaultSym = [..._data]
            }
        
        })
        .catch(e => {
            // console.log("getSymbols API error:", e)
            mToast.error(e.message)
        })
    }

    useEffect(() => {
        if (selExcSeg) {
            getSymbols({ isDefault: true })
        }
    }, [selExcSeg])

    return (
        <Box>
            <DropDown
                defaultValue="Search Symbol..."
                searchPlaceholder="Search Symbol..."
                value={excInst.filter(obj => obj.selected)}
                options={excInst}
                searchValue={searchVal}
                onSearchInput={e => {
                    setSearchVal(e.target.value)

                    if (ref.current.searchDelay) window.clearTimeout(ref.current.searchDelay);

                    if (e.target.value) {
                        ref.current.searchDelay = window.setTimeout(() => {
                            if (selExcSeg) {
                                getSymbols({value: e.target.value})
                            }
                        }, 500) // Waiting for 500ms
                    }
                    else {
                        setExcInst([...ref.current.defaultSym])
                    }
                }}
                onSelect={(excInstElem, isSelected, isArray)=> {
                    // Set the segment symbol details name in the reducer
                    dispatch(marketWatch.actions.setSelectSymbol(excInstElem.data))

                    excInst.forEach(obj => {
                        obj.selected = false
                    })

                    excInstElem.selected = isSelected
                    setExcInst([...excInst])
                }}
            >
                <CustomSearchbar />
            </DropDown>
        </Box>
    )
}