import { StrategyPositionDialog } from "./StrategyPosition";
import { IntradayPAndLChart } from "./IntradayPAndLChart";
import { SectionHeading } from "helpers/SectionHeading";
import { OrderStatusList } from "utils/OrderStatusList";
import { useRef, useEffect, useState } from "react";
import CachedIcon from '@mui/icons-material/Cached';
import { useUrlParams } from 'utils/useUrlParams';
import StrategyTracker from "./StrategyTracker";
import { useNavigate } from "react-router-dom";
import { NetPosition } from "./NetPosition";
import { Box, Button } from '@mui/material';
import { Socket } from 'Root/Common/Socket';
import { ConfigModal } from "./ConfigModal";
import { mToast } from "Root/Common/Toast";
import { useSelector } from 'react-redux';
import { OrderBook } from "./OrderBook";
import { root } from "Root/root";

export function Strategy(){
    const [searchParams, updateParams] = useUrlParams()
    const navigate = useNavigate();
    
    const commonSel = useSelector((state)=> state.common)
    const lUser = commonSel.user
    const loginId = commonSel.loginId

    const [stgTracker, setStgTracker] = useState("")
    const [stgStatus, setStgStatus] = useState("")
    const [stgConfig, setStgConfig] = useState({})
    const [showSettings, setShowSettings] = useState(0)

    const [isStgTrackerOpen, setIsStgTrackerOpen] = useState(false)
    const [isStgPosOpen, setIsStgPosOpen] = useState(false)
    const [isCfgModalOpen, setIsCfgModalOpen] = useState(false)
    const [isReEntryEnabled, setIsReEntryEnabled] = useState(true)

    const socketFunHandler = useRef({
        posData() {},
        liveMtm() {}
    })
    const userId = searchParams.get("userId")
    const strategyId = searchParams.get("strategyId")

    const brokerUserId = searchParams.get("brokerUserId")
    const stgName = searchParams.get("strategyName")

    // Check if this Login User has access to the user account or not
    const users = lUser.users
    
    // Checking if the userId and brokerId has permissions to access in the current login user account
    useEffect(() => {
        var isUserValid = false

        users.forEach(u => {
            if (userId == u.user_id && brokerUserId == u.broker_id) {
                isUserValid = true
                return;
            }
        })

        if (!isUserValid) {
            mToast.error("Permission denied!")
            navigate("/positions")
        }
    }, [])

    const constantRef = useRef({
        isStopReStartRequestInProgress: false,
        isReEntryRequestInProgress: false
    })

    const posClientStgPingPong = async (socket) => {        
        while (socket.socket.readyState === WebSocket.OPEN) {
            console.log("Sending posClientStgPingPong request")

            socket.send(JSON.stringify({"brokerUserId": brokerUserId, "wbType": "client-strategy", "stgId": strategyId}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const posClientStgSocket = () => {
        const connData = {"userId": userId, "brokerUserId": brokerUserId, "wbType": "client-strategy", "stgId": strategyId}
        const connQuery = new URLSearchParams(connData);

        var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/position/?" + connQuery);
        
        socket.onOpen(async function () {
            console.log("Connecting to positions (client-strategy) broadcast websocket")

            socket.send(JSON.stringify({"brokerUserId": searchParams.get("brokerUserId"), "wbType": "client-strategy", "stgId": strategyId}))
            await posClientStgPingPong(socket);
        })
        
        socket.onMessage(function (data) {
            let posData = data.posData;
            let msg = data.msg;

            if (msg) {
                console.log(msg)
            }

            if (posData) {
                // setStgStatus(posData.stgStatus);

                for (let tradeKey in posData) {
                    if (typeof socketFunHandler.current[tradeKey] === "function") {
                        socketFunHandler.current[tradeKey] (posData[tradeKey])
                    }
                }
            }
        })

        var isClStgSocketClose = false;
    
        socket.onClose(function(event) {
            console.log("Positions (client-strategy) broadcast websocket connection closed", event)

            // Attempt to reconnect to the websocket after a delay
            if (!isClStgSocketClose) {
                setTimeout(() => {
                    console.log(`Reconnecting to position (client-strategy) webSocket ...`);

                    posClientStgSocket()
                }, 1000);
            }
        })

        return () => {
            isClStgSocketClose = true
            socket.close()
        }
    }

    const liveMtmStgPingPong = async (socket) => {        
        while (socket.socket.readyState === WebSocket.OPEN) {
            console.log("Sending liveMtmStgPingPong request")

            socket.send(JSON.stringify({"userId": userId, "stgId": strategyId, "wbType": "client-strategy"}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const liveMtmStgSocket = () => {
        const connData = {"userId": userId, "stgId": strategyId, "wbType": "client-strategy"}
        const connQuery = new URLSearchParams(connData);

        var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/live_mtm/?" + connQuery);

        socket.onOpen(async function () {
            console.log("Connecting to live mtm (client-strategy) broadcast websocket")    

            socket.send(JSON.stringify({"userId": userId, "stgId": strategyId, "wbType": "client-strategy"}))
            await liveMtmStgPingPong(socket);
        })
        
        socket.onMessage(function (data) {
            let liveMtm = data.liveMtm;
            let msg = data.msg;

            if (msg) {
                console.log(msg)
            }

            if (liveMtm) {
                socketFunHandler.current["liveMtm"] (liveMtm)
            }
        })

        var isMtmStgSocketClose = false;
    
        socket.onClose(function(event) {
            console.log("Live mtm (client-strategy) broadcast websocket connection closed", event)

            // Attempt to reconnect to the websocket after a delay
            if (!isMtmStgSocketClose) {
                setTimeout(() => {
                    console.log(`Reconnecting to live mtm (client-strategy) webSocket ...`);

                    liveMtmStgSocket()
                }, 1000);
            }
        })

        return () => {
            isMtmStgSocketClose = true
            socket.close()
        }
    }

    const handleReExecuteButton = (propTag) => {
        return new Promise((resolve)=>{
            if (constantRef.current.isReEntryRequestInProgress) {
                resolve(false)
                return
            }

            if (strategyId) {
                constantRef.current.isReEntryRequestInProgress = true

                root.apis.orderConfig
                .stgReEntryFix({ 
                    userId: userId, stgId: strategyId, stgName: stgName, propTag: propTag, checkStgStatus: false,
                    startStg: true
                })
                .then(d => {
                    if (d.data.msg) {
                        mToast.success("Started strategy and sent request to re-create positions.")
                    }
        
                    if (d.data.err) {
                        mToast.error(d.data.err)
                    }

                    resolve(true)
                })
                .catch(err => {
                    const err_resp = err.response
        
                    if (err_resp.status === 403) {
                        mToast.error(err_resp.data.err)
                    }
                    else {
                        mToast.error(err.message)
                    }

                    resolve(false)
                })
                .finally(() => {
                    constantRef.current.isReEntryRequestInProgress = false
                })

                setIsReEntryEnabled(false)

                setTimeout(() => {
                    setIsReEntryEnabled(true)
                }, 5000)
            }
        })
    }

    useEffect(() => {
        var posClStg = posClientStgSocket()
        var liveStgMtm = liveMtmStgSocket()

        return () => {
            posClStg()
            liveStgMtm()
        }
    }, [])

    if (isStgTrackerOpen) {
		root.apis.strategy.tracker({stgName: stgName})
		.then(d => {
			setStgTracker(d.data.stgStatus)
		})
		.catch(e => {
			console.log("Get Strategy Tracker Error: ", e)
			mToast.error(e.statusText)
		})
	}

    useEffect(() => {
        if (isCfgModalOpen) {
            root.apis.orderConfig.get(userId, {strategy_id: strategyId, filt: "stgConfigs", from_csv: true})
            .then(data => {
                const cfg = data.data.stgData

                if (cfg.length > 0) {
                    setStgConfig(cfg[0])
                }
            })
            .catch(err => {
                console.log("Get Strategy Config Error: ", err)
                mToast.error(err.statusText)
            })
        }
    }, [isCfgModalOpen])

    // Client strategy menu anchor
    const [clStgAnchorEl, setClStgAnchorEl] = useState(null);
    const clStgMenuOpen = Boolean(clStgAnchorEl);

    const handleMenuClick = (event) => {
        setClStgAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setClStgAnchorEl(null);
    };

    socketFunHandler.current.stgConfig = (data) => {
        const stgStatus = data.status
        
        stgConfig.status = stgStatus

        setStgStatus(stgStatus)
        setShowSettings(data.is_manual)
    }

    return(
        <Box>
            <SectionHeading
                heading={`Strategy - ${searchParams.get("fullName")} ( ${stgName} )`}
                hComponents={
                    <Box display="flex" alignItems="center">
                        {
                            (OrderStatusList[stgStatus] || {}).name
                            ? 
                                <Box>
                                    <Box 
                                        style={{ 
                                            width: 16, 
                                            height: 16, 
                                            backgroundColor: ((OrderStatusList[stgStatus] || {}).color || {})[800] || "transparent", 
                                            borderRadius: 50 
                                        }}
                                    ></Box>

                                    <Box ml={1.5} fontSize={15}>
                                        ({(OrderStatusList[stgStatus] || {}).name})
                                    </Box>
                                </Box>
                            : ""
                        }
                    </Box>
                }
                hCStyle={{
                    marginLeft: 1.5
                }}
            >
                <Box>
                    {/* Reload Button */}
                    <Button 
                        sx={{ minWidth: 20, p: 0, mr: 3, mt: 2 }} 
                        color="primary" 
                        onClick={() => {
                            window.location.reload();
                        }} 
                    > <CachedIcon sx={{ fontSize: 30 }} /> </Button>

                    {/* Back Button */}
                    <Button 
                        variant="outlined"
                        style={{ textTransform: "initial", marginRight: 20, marginTop: 15 }}
                        color="inherit"
                        onClick={() => updateParams({type: "view", strategyId: "", strategyName: ""})}
                    >
                        Back
                    </Button>

                    <Button
                        variant="outlined"
                        color="inherit"
                        style={{ textTransform: "initial", marginTop: 15 }}
                        onClick={() => setIsStgPosOpen(true)}
                    >
                        Strategy Position
                    </Button>

                    <Button
                        variant="outlined"
                        color="inherit"
                        style={{ textTransform: "initial", marginLeft: 20, marginTop: 15 }}
                        onClick={() => setIsStgTrackerOpen(true)}
                    >
                        Strategy Tracker
                    </Button>

                    {
                        showSettings === false ?
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "initial", marginLeft: 20, marginTop: 15 }}
                                onClick={() => {handleReExecuteButton(`oc-re-execute-${loginId}`)}}
                                disabled={!isReEntryEnabled}
                                sx={{
                                    color: "white !important",
                                    backgroundColor: "#1976D2 !important",
                                    opacity: isReEntryEnabled ? 1 : 0.5
                                }}
                            >
                                Re-Entry / Re-Check
                            </Button>
                        : ""
                    }

                    {
                        stgName.toLowerCase() == "manual" 
                        ? 
                        <Button
                            variant="contained"
                            style={{ textTransform: "initial", marginLeft: 20, marginTop: 15 }}
                            onClick={() => {

                                root.apis.orderConfig.stopReStartStrategy({
                                    stgId: strategyId,
                                    stgName: stgName,
                                    userId: userId,
                                    propTag: `cl-stg-square-off-${loginId}`,
                                    onlySquareOff: true
                                })
                                .then((data) => {
                                    if (data.status === 201) {
                                        if (data.data.msg) {
                                            mToast.success(data.data.msg)
                                        }

                                        if (data.data.err) {
                                            mToast.error(data.data.err)
                                        }
                                    }
                                    else {
                                        mToast.error("Couldn't complete request. Something went wrong!")
                                    }
                                })
                                .catch(e => {
                                    const e_resp = e.response

                                    if (e_resp.status === 403) {
                                        mToast.error(e_resp.data.err)
                                    }
                                    else {
                                        mToast.error("Couldn't complete request. Something went wrong!")
                                    }
                                })
                                
                            }}
                        >
                            Square Off
                        </Button>
                        :
                        showSettings === false
                        ?
                            <Button 
                                variant="outlined"
                                style={{ textTransform: "initial", marginLeft: 20, marginTop: 15 }}
                                color="inherit"
                                onClick={() => {setIsCfgModalOpen(true)}}
                            >
                                Setting
                            </Button>
                        : ""
                    }

                    {/* <Grid item order={{ xs: 2, sm: 3 }}> */}
                    {/* <Button
                        variant="contained"
                        color="primary" 
                        style={{ textTransform: "initial", marginLeft: 20, marginTop: 15 }}
                        onClick={handleMenuClick}
                    > Menu </Button> */}

                    {/* <Menu
                        id="basic-menu"
                        anchorEl={clStgAnchorEl}
                        open={clStgMenuOpen}
                        onClose={handleMenuClose}
                    >
                        <Box color="#000000">
                            <MenuItem
                                onClick={() => {
                                    root.apis.orderConfig.stopReStartStrategy({
                                        stgId: strategyId,
                                        stgName: stgName,
                                        userId: userId,
                                        propTag: "cl-stg-square-off",
                                        onlySquareOff: true
                                    })
                                    .then((data) => {
                                        if (data.status === 201) {
                                            if (data.data.msg) {
                                                mToast.success(data.data.msg)
                                            }
        
                                            if (data.data.err) {
                                                mToast.error(data.data.err)
                                            }
                                        }
                                        else {
                                            mToast.error("Couldn't complete request. Something went wrong!")
                                        }
                                    })
                                    .catch(e => {
                                        const e_resp = e.response
        
                                        if (e_resp.status === 403) {
                                            mToast.error(e_resp.data.err)
                                        }
                                        else {
                                            mToast.error("Couldn't complete request. Something went wrong!")
                                        }
                                    })

                                    setClStgAnchorEl(null);
                                }}
                            >
                                Squareoff
                            </MenuItem>

                            <MenuItem
                                onClick={() => {stopReStartStrategy(constantRef)}}
                            >
                                Current Day Stop
                            </MenuItem>
                                
                            <MenuItem
                                onClick={() => {stopReStartStrategy(constantRef, false)}}
                            >
                                Square Off & Restart
                            </MenuItem>

                            <MenuItem
                                onClick={() => {reEntryTroubleshootStg(constantRef)}}
                            >
                                Re-Entry
                            </MenuItem>
                        </Box>
                    </Menu> */}
                    {/* </Grid> */}

                    {/* Squareoff Button */}
                    {/* <Button
                        variant="contained"
                        style={{ textTransform: "initial", marginLeft: 20, marginBottom: 15 }}
                        onClick={() => {

                            root.apis.orderConfig.stopReStartStrategy({
                                stgId: strategyId,
                                stgName: stgName,
                                userId: userId,
                                propTag: "cl-stg-square-off",
                                onlySquareOff: true
                            })
                            .then((data) => {
                                if (data.status === 201) {
                                    if (data.data.msg) {
                                        mToast.success(data.data.msg)
                                    }

                                    if (data.data.err) {
                                        mToast.error(data.data.err)
                                    }
                                }
                                else {
                                    mToast.error("Couldn't complete request. Something went wrong!")
                                }
                            })
                            .catch(e => {
                                const e_resp = e.response

                                if (e_resp.status === 403) {
                                    mToast.error(e_resp.data.err)
                                }
                                else {
                                    mToast.error("Couldn't complete request. Something went wrong!")
                                }
                            })
                            
                        }}
                    >
                        Square Off
                    </Button> */}

                    {/* Stop Button */}
                    {/* <Button
                        variant="contained"
                        style={{ textTransform: "initial", marginLeft: 20, marginBottom: 15 }}
                        color="error"
                        onClick={() => {stopReStartStrategy(constantRef)}}
                    >
                        Current Day Stop
                    </Button> */}

                    {/* Squareoff & Restart Button */}
                    {/* <Button
                        variant="contained"
                        style={{ textTransform: "initial", marginLeft: 20, marginBottom: 15 }}
                        onClick={() => {stopReStartStrategy(constantRef, false)}}
                    >
                        Square Off & Restart
                    </Button> */}

                    {/* Re-Entry Button */}
                    {/* <Button
                        variant="contained"
                        style={{ textTransform: "initial", marginLeft: 20, marginBottom: 15 }}
                        onClick={() => {reEntryTroubleshootStg(constantRef)}}
                    >
                        Re-Entry
                    </Button> */}

                    {/* Troubleshoot Button */}
                    {/* <Button
                        variant="contained"
                        style={{ textTransform: "initial", marginLeft: 20, marginBottom: 15 }}
                        onClick={() => {reEntryTroubleshootStg(constantRef, true)}}
                    >
                        Troubleshoot/Fix
                    </Button> */}
                </Box>
            </SectionHeading>

            {
                [
                    NetPosition, 
                    OrderBook,
                    IntradayPAndLChart
                ].map((Component, i)=>(
                    <Box key={i} mt={4}>
                        <Component
                            userId={userId}
                            strategyId={strategyId}
                            searchParams={searchParams}
                            socketFunHandler={socketFunHandler}
                        />
                    </Box>
                ))
            }
            
            {
                showSettings === false
                ?
                    <ConfigModal
                        open={isCfgModalOpen}
                        setOpen={setIsCfgModalOpen}
                        userId={userId}
                        strategyId={strategyId}
                        stgConfig={stgConfig}
                    />
                : ""
            }

            <StrategyTracker
                open={isStgTrackerOpen}
                handleClose={() => setIsStgTrackerOpen(false)}
                stgTracker={stgTracker}
            />

            <StrategyPositionDialog 
                open={isStgPosOpen}
                handleClose={() => setIsStgPosOpen(false)}
                stgName={stgName}
            />
        </Box>
    )

}