import { getCookie, deleteCookie, setCookie } from "helpers/Cookies";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { CircularProgress, CssBaseline } from "@mui/material";
import { topNavBarHeight } from "components/nav/AsideMenus";
import { useSmallScreen } from "helpers/useSmallScreen";
import { AsideNav } from "components/nav/AsideNav";
import { Login } from "components/accounts/Login";
import { constant } from "Root/Common/constant";
import { TopNav } from "components/nav/TopNav";
import { useEffect, useState } from "react";
import { commonSlice } from "redux/Reducer";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { mToast } from "./Common/Toast";
import Box from "@mui/material/Box";
import { root } from "Root/root";

function ApplicationBody() {
    const isBelowScreen = useSmallScreen("lg")
    const {withSideBar, isLogin} = useSelector(s => s.common)
    const dispatch = useDispatch()
    const [isPending, startTransition] = useState(true);

    // const location = useLocation();
    // const navigate = useNavigate();

    // const verifyRoutePerm = (userObj) => {
    //     if (userObj.is_admin === false) {
    //         const dReRoutePath = constant.Authorization.DefaultReRoutePath
    //         const nonAdminRoutes = constant.Authorization.NonAdminRoutes
            
    //         const urlPath = location.pathname

    //         if (!nonAdminRoutes.includes(urlPath)) {
    //             navigate(dReRoutePath);
    //         }
    //     }
    // }

    useEffect(() => {
        const loginIdC = constant.Cookies.LoginId
        const accessTokenC = constant.Cookies.AccessToken

        // Verify Login State
        const accessTokenVal = getCookie(accessTokenC)
        
        if (accessTokenVal) {
            const loginIdVal = getCookie(loginIdC)
            const payload = {
                "loginId": loginIdVal,
                "accessToken": accessTokenVal
            }

            // Set the user AUTH token value
            root.userAuth.token = accessTokenVal 

            // Set the user login ID value
            root.user.loginId = loginIdVal

            root.apis.loginUsers.validateAccess(payload)
            .then((data) => {
                const d = data.data
                const isValid = d.isValid
                const userObj = d.user

                // Set isAdmin value in the root object
                root.isAdmin = userObj.is_admin

                if (isValid) {
                    dispatch(commonSlice.actions.setIsLogin(true))

                    dispatch(commonSlice.actions.setAuthLoginId(loginIdVal))
                    dispatch(commonSlice.actions.setAccessToken(accessTokenVal))
                    dispatch(commonSlice.actions.setUser(userObj))
                }
                else {
                    dispatch(commonSlice.actions.setIsLogin(false))

                    deleteCookie(loginIdC)
                    deleteCookie(accessTokenC)
                }

                // Verify route permissions
                // verifyRoutePerm(userObj)
            })
            .catch((err) => {
                const err_resp = err.response

                if (err_resp) {
                    if (err_resp.status === 403) {
                        mToast.error(err_resp.data.err)
                    }
                    else {
                        mToast.error("Couldn't complete request. Something went wrong!")
                    }
                }
                else {
                    mToast.error(err.message)
                }

                dispatch(commonSlice.actions.setIsLogin(false))

                deleteCookie(loginIdC)
                deleteCookie(accessTokenC)
            })
            .finally(() => {
                startTransition(false)
            })
        }
        else {
            startTransition(false)
        }

    }, [isLogin])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {
                isLogin ?
                    <>
                        <TopNav />
                        <AsideNav />

                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                p: 2,
                                ... !withSideBar && !isBelowScreen && { px: 25 }
                            }}
                            style={{
                                marginTop: topNavBarHeight + 40,
                                // width: `calc(100% - ${false ? 0 : asideMenusWidth}px)`,
                                overflow: "auto",
                                // marginLeft: isBelowSm ? 0 : asideMenusWidth
                            }}
                            className="scrollbar"
                        >
                            <Outlet />
                        </Box>
                    </>
                    :
                    isPending ? 
                    <Box sx={{ 
                        display: 'flex', width: "100%", height: "100vh", justifyContent: "center", alignItems: "center"
                    }}>
                        <CircularProgress />
                    </Box> :
                    <Login />
            }

        </Box>
    )
}

export default ApplicationBody