import { createSlice } from '@reduxjs/toolkit'
import { calculateTotal } from 'components/body/marketWatch/Sections/common/calculateTotal'


// common slice
export const commonSlice = createSlice({
    name: "common",
    initialState: { // store all common data that can be used throughout the application
        withSideBar: true,
        loginId: null,
        accessToken: null,
        isLogin: false, // default false
        user: {} // null | {}
    },
    reducers: {
        setSideBar: (state, action)=>{
            state.withSideBar = action.payload
        },
        setAuthLoginId: (state, action) => {
            state.loginId = parseInt(action.payload)
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload
        },
        setIsLogin: (state, action) => {
            state.isLogin = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        }
    }
})

export const marketWatch = createSlice({
    name: "marketWatch",
    initialState: {
        selectedExchange: null, // null | {}
        selectedSymbol: null, // null | {}
        selectedUsers: [],
        symbolList: [],
        exchangeList: [],
        updateFields: ["ltp", "delta", "gamma", "theta", "vega", "rho"],
        savedTemplate: [ // it will contain the clone data. this will help in reset. 
            // { id: 1, selected: false, qty: 6000, isBuy: undefined, exchange: "NFO1", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
            // { id: 2, selected: false, qty: 6000, isBuy: undefined, exchange: "NFO2", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
            // { id: 3, selected: false, qty: 6000, isBuy: undefined, exchange: "NFO3", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
            // { id: 4, selected: false, qty: 6000, isBuy: undefined, exchange: "NFO4", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
            // { id: 5, selected: false, qty: 6000, isBuy: undefined, exchange: "NFO5", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
            // { _type: "total", ltp: 0, delta: 0, gamma: 0, theta: 0, iv: 0, vega: 0 }
        ],
        data: [
            // isBuy: undefined | true | false
            // { id: 1, selected: false, qty: 6000, isBuy: undefined, exchange: "NFO1", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
            // { id: 2, selected: false, qty: 6000, isBuy: undefined, exchange: "NFO2", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
            // { id: 3, selected: false, qty: 6000, isBuy: undefined, exchange: "NFO3", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
            // { id: 4, selected: false, qty: 6000, isBuy: undefined, exchange: "NFO4", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
            // { id: 5, selected: false, qty: 6000, isBuy: undefined, exchange: "NFO5", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
            // { _type: "total", ltp: 0, delta: 0, gamma: 0, theta: 0, iv: 0, vega: 0 }
        ],
        templates: [],
        tradeBook: {
            "users": [],
            "tags": [],
            "trades": []
        }
    },
    reducers: {
        setSymbolList(state, action){
            state.symbolList = action.payload
        },
        setExchangeList(state, action){
            state.exchangeList = action.payload
        },
        setSelectExchange(state, action){
            state.selectedExchange = action.payload
        },
        setSelectSymbol(state, action){
            state.selectedSymbol = action.payload
        },
        setSelectUsers(state, action){
            state.selectedUsers = action.payload
        },
        resetAll(state){
            state.data = []
            state.symbolList = []
            state.exchangeList = []
            state.selectedExchange = null
            state.selectedSymbol = null
        },
        setData(state, action) {
            switch(action.payload.type){
                case "delete":
                    state.data = state.data.filter((crow) => crow.id !== action.payload.row?.id)
                    break;
                case "reverse":
                    state.data = state.data.map((trade) => {
                        if('boolean' === typeof trade.isBuy){
                            trade.isBuy = !trade.isBuy
                        }
                        return trade
                    });
                    break;
                case "modify":
                    if (action.payload.row) {
                        Object.assign(state.data.find(crow => crow.id === action.payload.row.id) || {}, action.payload.data)
                    }

                    if(action.payload.data && Array.isArray(action.payload.data)){
                        var shouldCalcTotal = false

                        if (state.data.length) {
                            action.payload.data.forEach((row) => {
                                const fe = state.data.find(crow => crow.id === row.id)

                                if (fe) {
                                    state.updateFields.forEach((field)=>{
                                        fe[field] = row[field]
                                    })
                                    shouldCalcTotal = true
                                    
                                    if (fe.selected) { // (typeof fe.isBuy === "boolean")
                                        fe.tradeAmount = (Number(fe.qty || 0) * Number(fe.ltp || 0)).toFixed(2)

                                        if (fe.isBuy === false) {
                                            fe.tradeAmount = -fe.tradeAmount
                                        }
                                    }
                                    else {
                                        fe.tradeAmount = 0
                                    }
                                }
                                else {
                                    const totalRow = state.data[state.data.length - 1]

                                    if (totalRow) {
                                        if (totalRow._type === "total") {
                                            state.data.splice(state.data.length - 1, 0, row)
                                        }
                                    }
                                }
                            });
                        }
                        else {
                            state.data = action.payload.data
                            state.savedTemplate = state.data.map(e=>({ ...e }))
                            shouldCalcTotal = true
                            // console.log("state.savedTemplate", state.savedTemplate)
                        }

                        // console.log("shouldCalcTotal = ", shouldCalcTotal)
                        if(shouldCalcTotal){
                            action.payload.calcTotal = true 
                        }

                    }
                    break;
                case "reset":
                    state.data = [...state.savedTemplate]
                    break;
                default:
                    state.data = action.payload.data
            }

            if(action.payload.calcTotal) calculateTotal(state.data);
        },
        setTemplates(state, action) {
            state.templates = action.payload
        },
        setTradeBook(state, action) {
            for (let key in action.payload) {
                state.tradeBook[key] = action.payload[key]
            }
        },
        updateTradeBookUser(state, action) {
            if (action.payload.type === "update") {
                if (action.payload.isArray) {
                    state.tradeBook.users = state.tradeBook.users.map(user => {
                        user.selected = action.payload.selected

                        return user
                    })
                }
                else {
                    const user = state.tradeBook.users.find(user => {
                        user.selected = false
                        
                        return user.id === action.payload.data.id
                    })
                    
                    if (user) {
                        user.selected = action.payload.selected
                    }
                }
            }
        },
        updateTradeBookTag(state, action) {
            if (action.payload.type === "update") {
                if (action.payload.isArray) {
                    state.tradeBook.tags = state.tradeBook.tags.map(tag => {
                        tag.selected = action.payload.selected

                        return tag
                    })
                }
                else {
                    const tag = state.tradeBook.tags.find(tag => {
                        tag.selected = false
                        
                        return tag.id === action.payload.data.id
                    })
                    
                    if (tag) {
                        tag.selected = action.payload.selected
                    }
                }
            }
        }
    }
})