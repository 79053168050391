import { useCustomSearchbar } from "./common/useCustomSearchbar";
import { SectionHeading } from "helpers/SectionHeading";
import { useRef, useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { DropDown } from "helpers/DropDown";
import { useSelector, useDispatch } from 'react-redux';
import { Table } from "helpers/Table";
import { fetchTradeBook } from "./common/marketWatchUtils";
import { ExportXLSX } from "helpers/ExportXLSX";
import green from "@mui/material/colors/green";
import grey from "@mui/material/colors/grey";
import red from "@mui/material/colors/red";
import yellow from "@mui/material/colors/yellow";
import { marketWatch } from "redux/Reducer";

function TradeBookTable({ columns = [], tradeBook = [] }) {
    // const columns = useRef([
    //     { id: "userName", label: "User", align: "left" },
    //     { id: "dateTime", label: "Date Time", align: "left" },
    //     { id: "exchange", label: "Exchange", align: "center" },
    //     { id: "symbol", label: "Symbol", align: "left" },
    //     { id: "type", label: "Type", align: "left" },
    //     { id: "qty", label: "Qty", align: "center" },
    //     { id: "price", label: "Price", align: "center" },
    //     { id: "oRefId", label: "Order Ref ID", align: "center" },
    //     { id: "tag", label: "Tag", align: "left" },
    // ])

    // const [state, setState] = useState({
    //     data: [
    //         { dateTime: "27-09-2023 11:38:45", exchange: "NFO", symbol: "BANKNIFTY 23SEP2023 44000 CE", type: "SELL", qty: 2398, price: 23947, tag: "TEST S56" },
    //         { dateTime: "27-09-2023 11:38:45", exchange: "NFO", symbol: "BANKNIFTY 23SEP2023 44000 CE", type: "SELL", qty: 2398, price: 23947, tag: "TEST S56" },
    //         { dateTime: "27-09-2023 11:38:45", exchange: "NFO", symbol: "BANKNIFTY 23SEP2023 44000 CE", type: "SELL", qty: 2398, price: 23947, tag: "TEST S56" },
    //         { dateTime: "27-09-2023 11:38:45", exchange: "NFO", symbol: "BANKNIFTY 23SEP2023 44000 CE", type: "SELL", qty: 2398, price: 23947, tag: "TEST S56" },
    //         { dateTime: "27-09-2023 11:38:45", exchange: "NFO", symbol: "BANKNIFTY 23SEP2023 44000 CE", type: "SELL", qty: 2398, price: 23947, tag: "TEST S56" },
    //         { dateTime: "27-09-2023 11:38:45", exchange: "NFO", symbol: "BANKNIFTY 23SEP2023 44000 CE", type: "SELL", qty: 2398, price: 23947, tag: "TEST S56" },
    //         { dateTime: "27-09-2023 11:38:45", exchange: "NFO", symbol: "BANKNIFTY 23SEP2023 44000 CE", type: "SELL", qty: 2398, price: 23947, tag: "TEST S56" },
    //         { dateTime: "27-09-2023 11:38:45", exchange: "NFO", symbol: "BANKNIFTY 23SEP2023 44000 CE", type: "SELL", qty: 2398, price: 23947, tag: "TEST S56" },
    //         { dateTime: "27-09-2023 11:38:45", exchange: "NFO", symbol: "BANKNIFTY 23SEP2023 44000 CE", type: "SELL", qty: 2398, price: 23947, tag: "TEST S56" },
    //         { dateTime: "27-09-2023 11:38:45", exchange: "NFO", symbol: "BANKNIFTY 23SEP2023 44000 CE", type: "SELL", qty: 2398, price: 23947, tag: "TEST S56" },
    //     ]
    // })

    return (
        <Box 
            mt={3}
            sx={{
                "& table":{
                    "tr td": {
                        py: 1,
                    }
                }
            }}
        >
            <Table
                columns={columns.current}
                rows={tradeBook}
                rowAttr={(row) => {
                    const status = row.status.toLowerCase()
                    const type = row.type.toLowerCase()
                    var value = ""

                    if (status === "completed") {
                        if (type === "buy") {
                            value = green[600]
                        }
                        else {
                            value = red[600]
                        }
                    } 

                    else if (status === "rejected") {
                        value = yellow[800]
                    }

                    else {
                        value = grey[600]
                    }

                    return {
                        sx: {
                            bgcolor: value,
                        },
                        hover: false
                    }
                }}
            />
        </Box>
    )
}

export function TradeBook() {
    document.title = "Market Watch | Tradebook";

    const columns = [
        { id: "userName", label: "User", align: "left" },
        { id: "dateTime", label: "Date Time", align: "left" },
        { id: "exchange", label: "Exchange", align: "center" },
        { id: "symbol", label: "Symbol", align: "left" },
        { id: "type", label: "Type", align: "left" },
        { id: "qty", label: "Qty", align: "center" },
        { id: "price", label: "Price", align: "center" },
        // { id: "oRefId", label: "Order Ref ID", align: "center" },
        { id: "status", label: "Status", align: "center" },
        { id: "tag", label: "Tag", align: "left" },
    ]

    const CustomSearchbar = useCustomSearchbar()
    const dispatch = useDispatch()

    const commonSel = useSelector(selector => selector.common)
    const marketWatchSel = useSelector(selector => selector.marketWatch)
    
    const [tradeFilt, setTradeFilt] = useState({
        "tag": "",
        "user": ""
    })

    const onFilter = (type, item={}) => {
        const newTradeFilt = { ...tradeFilt, [type]: item.value }

        setTradeFilt(newTradeFilt)
        fetchTradeBook(dispatch, commonSel, newTradeFilt)
    }

    useEffect(() => {
        fetchTradeBook(dispatch, commonSel)
    }, [dispatch, commonSel]);

    return (
        <Box>
            <SectionHeading
                heading={"TradeBook"}
            >
                <Box display="flex" gap={4} alignItems="center">
                    <DropDown
                        defaultValue="User Filter..."
                        searchPlaceholder="User Filter..."
                        value={marketWatchSel.tradeBook.users.filter(obj=>obj.selected)}
                        options={marketWatchSel.tradeBook.users}
                        withSelectAllControl
                        selectAllText="Show All"
                        onSelect={(user, isSelected, isArray)=> {
                            onFilter("user", user)

                            dispatch(marketWatch.actions.updateTradeBookUser({
                                type: "update",
                                data: user,
                                selected: isSelected,
                                isArray: isArray
                            }))
                        }}
                    >
                        <CustomSearchbar />
                    </DropDown>

                    <DropDown
                        defaultValue="Tag Filter..."
                        searchPlaceholder="Tag Filter..."
                        value={marketWatchSel.tradeBook.tags.filter(obj=>obj.selected)}
                        options={marketWatchSel.tradeBook.tags}
                        withSelectAllControl
                        selectAllText="Show All"
                        onSelect={(tag, isSelected, isArray)=> {
                            onFilter("tag", tag)

                            dispatch(marketWatch.actions.updateTradeBookTag({
                                type: "update",
                                data: tag,
                                selected: isSelected,
                                isArray: isArray
                            }))
                        }}
                    >
                        <CustomSearchbar />
                    </DropDown>
                    
                    <Button 
                        variant="outlined" 
                        sx={{ textTransform: "initial" }}
                        onClick={() => {
                            const exportCols = columns.map(column => column.label)
                            const exportRows = columns.map(column => column.id)

                            ExportXLSX("trade_book", {
                                columns: exportCols,
                                rows: marketWatchSel.tradeBook.trades.map(trade => exportRows.map(column => trade[column]))
                            })
                        }}
                    >Export</Button>
                </Box>
            </SectionHeading>

            <TradeBookTable
                columns={useRef(columns)}
                tradeBook={marketWatchSel.tradeBook.trades}
            />
        </Box>
    )
}