import { Box, Button, Checkbox, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Clear } from "@mui/icons-material";
import { marketWatch } from "redux/Reducer";
import { mToast } from "Root/Common/Toast";
import { useMemo, useRef } from "react"
import { root } from "Root/root";

/**
 * @useMarketWatchColumns
 * @param { Array } param0.exceptColumns - [id,id,...]
 * @param { Array } param0.columnsOrder - [id,id,...]
 * @param { Array } param0.customOptions - [ {id:"qty", label: "Custom Qty"} ]
 * @param { {} } param0.customOnChangeHandler - { selected(value, row){ } }
 * @returns 
 */
export function useMarketWatchColumns(props={}){
    const dispatch = useDispatch()
    const commonSel = useSelector(selector => selector.common)

    const handleGreekSign = (greek, row, value) => {
        const mapDict = {
            "delta": { "CE": -value, "PE": Math.abs(value) },
            "gamma": { "CE": -value, "PE": -value },
            "theta": { "CE": Math.abs(value), "PE": Math.abs(value) },
            // "iv": { "CE": Math.abs(value), "PE": Math.abs(value) },
            "vega": { "CE": -value, "PE": -value },
            "rho": { "CE": value, "PE": value }
        }

        if (!row._type) {
            if (row.symbol.includes("CE")) {
                if (row.isBuy === false) {
                    return mapDict[greek].CE
                }
            }

            if (row.symbol.includes("PE")) {
                if (row.isBuy === false) {
                    return mapDict[greek].PE
                }
            }
        }

        return value
    }

    const columns = useRef([
        { id: "qty", label: "Qty", align: "center", format(value, row) { value === 0 ? value = "" : value = value; return row._type === "total" ? value : <TextField value={value} onChange={(e)=> 'function' === typeof props?.customOnChangeHandler?.qty ? props?.customOnChangeHandler.qty(e, row, value) : dispatch(marketWatch.actions.setData({ type: "modify", calcTotal: true, row, data: { qty: e.target.value } }))} type='number' InputProps={{ inputProps: { min: 0 } }} /> }, cellAttr: { sx: { minWidth: 75, maxWidth: 50 } } },
        { id: "buy", label: "", align: "center", format(_, row) { return row._type === "total" ? "" : <Button onClick={() => {
            const isBuy = row.isBuy === true ? undefined : true

            dispatch(marketWatch.actions.setData({ type: "modify", row, data: { isBuy: isBuy, selected: Boolean(isBuy) } }))
        }} sx={{ minWidth: 20, p: 0, ml: 2 }} variant={row.isBuy === true ? "contained" : "outlined"} color="success">B</Button> } },
        { id: "sell", label: "", align: "center", format(_, row) { return row._type === "total" ? "" : <Button onClick={() => {
            const isBuy = row.isBuy === false ? undefined : false
            
            dispatch(marketWatch.actions.setData({ type: "modify", row, data: { isBuy: isBuy, selected: isBuy === false  } }))
        }} sx={{ minWidth: 20, p: 0, ml: 1 }} variant={row.isBuy === false ? "contained" : "outlined"} color="error">S</Button> } },
        { id: "exchange", label: "Exchange", align: "center", minWidth: 100, format(value, row){ return row._type === "total" ? "" : value} },
        { id: "symbol", label: "Symbol", align: "left", minWidth: 250, format(value, row){ return row._type === "total" ? "" : value} },
        { id: "user", label: "User", align: "center" },
        { id: "selected", label: "", align: "left", format(value, row) { return row._type === "total" ? "" : (<Checkbox size="small" color="info" checked={value} onChange={(e)=> 'function' === typeof props?.customOnChangeHandler?.selected ? props?.customOnChangeHandler.selected(e, row, value) : dispatch(marketWatch.actions.setData({ type: "modify", calcTotal: true, row, data: { selected: e.target.checked } }))} />) } },
        { id: "tradeAmount", label: "Trade Amount", align: "center" },
        { id: "ltp", label: "LTP", align: "center", format(value, row) { return row._type !== "total" ? <Box color={row.perc_chg !== 0 ? row.perc_chg > 0 ? "green" : "red" : ""}>{value}</Box> : value } },
        { id: "delta", label: "Delta", align: "center", format(value, row) { return handleGreekSign("delta", row, value) } },
        { id: "gamma", label: "Gamma", align: "center", format(value, row) { return handleGreekSign("gamma", row, value) } },
        { id: "theta", label: "Theta", align: "center", format(value, row) { return handleGreekSign("theta", row, value) } },
        // { id: "iv", label: "IV", align: "center", format(value, row) { return handleGreekSign("iv", row, value) } },
        { id: "vega", label: "Vega", align: "center", format(value, row) { return handleGreekSign("vega", row, value) } },
        { id: "rho", label: "Rho", align: "center", format(value, row) { return handleGreekSign("rho", row, value) } },
        { id: "delete", label: "", align: "right", format(_, row) { return row._type === "total" ? "" : <Button 
            sx={{ minWidth: 20, p: 0 }} 
            color="error" 
            className="delete-row" 
            onClick={() => {
                deleteSymbol(row.id)
                dispatch(marketWatch.actions.setData({ type: "delete", calcTotal: true, row }))}
            }    
        > <Clear /> </Button> } },
    ])
    
    const deleteSymbol = (activeId) => {
        root.apis.marketWatch.deleteSymbol(commonSel.loginId, activeId)
        .then(data => {
            const resp = data.data

            if (resp.msg) {
                mToast.success(resp.msg, {
                    autoClose: 50
                })
            }
        })
        .catch(err => {
            const err_resp = err.response

            if (err_resp) {
                if (err_resp.status === 403) {
                    mToast.error(err_resp.data.err, {
                        autoClose: 50
                    })
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!", {
                        autoClose: 50
                    })
                }
            }
            else {
                mToast.error("Couldn't complete request. Something went wrong!", {
                    autoClose: 50
                })
            }
        })
    }

    return useMemo(()=>{
        if(props?.exceptColumns && Array.isArray(props.exceptColumns)){
            columns.current = columns.current.filter((col)=>!props.exceptColumns.includes(col.id))
        }
        
        if(props?.customOptions && Array.isArray(props.customOptions)){
            columns.current = columns.current.map((col, i)=>{
                const fCol = props.customOptions.find(c => c.id === col.id)
                if(fCol){
                    Object.assign(col, fCol)
                }
                return col
            })
        }

        if(props?.columnsOrder && Array.isArray(props?.columnsOrder)){
            const ascCols = []
            props.columnsOrder.forEach(colId => {
                const findCol = columns.current.find(col => col.id === colId )
                if(findCol)ascCols.push(findCol);
            });

            columns.current = [
                ...ascCols,
                ...columns.current.filter(col => !ascCols.some(ascCol => ascCol.id === col.id ))
            ]
        }

        return columns.current
    },[])
}
