import { Box, IconButton } from "@mui/material";
import DialogContent from '@mui/material/DialogContent';
import { useMemo, forwardRef, useState, useEffect } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Close from "@mui/icons-material/Close";
import { mToast } from "Root/Common/Toast";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Table } from "helpers/Table";
import { root } from "Root/root";

export function StrategyPositionDialog({ 
    open = false, handleClose = () => undefined, stgName = ""
}) {
    const columns = [
        { id: "exchange", label: "Exchange", align: "center" },
        { id: "symbol", label: "Symbol", align: "center" },
        { id: "o_type", label: "Order Type", align: "center" },
        { id: "side", label: "Order Side", align: "center" },
        { id: "qty", label: "Quantity", align: "center" },
        { id: "o_ref_id", label: "Order Ref. ID", align: "center" }
    ]
    const [rows, setRows]= useState([]);

    const Transition = useMemo(() => forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    }), [])

    useEffect(() => {
        root.apis.strategy.getPositions({ "stgName": stgName })
        .then(d => {
            setRows(d.data.stgPos)
        })
        .catch(e => {
            console.log("Get Strategy Positions Error: ", e)
            mToast.error(e.statusText)
        })
    }, [open])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            scroll="paper"
            maxWidth="md"
			onClose={handleClose}
            PaperProps={{
                sx: {
                    minHeight: "80%",
                    minWidth: "60%",
                    color: "#000",
                    "& table": {
                        "thead tr th": {
                            p: 2
                        },
                        "tbody tr td": {
                            color: "#000",
                            py: 2
                        }
                    },
                    "& button": {
                        textTransform: "initial"
                    },
                    "& .mk-flex": {
                        mt: 3,
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 2,
                        "input": {
                            color: "black",
                        },
                    }
                }
            }}
        >
            <DialogTitle>
                <Box display="flex" alignContent="center" justifyContent="space-between">
                    <Box>Strategy Positions</Box>
                    <Box><IconButton onClick={handleClose}><Close /></IconButton></Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Table
                    columns={columns}
                    rows={rows}
                />
            </DialogContent>
        </Dialog>
    );
}