import { SectionHeading } from "helpers/SectionHeading";
import { ReportList } from "./ReportList";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { root } from "Root/root";

/** @param { null | { report: Array<{ Date: number, Day: string, Month: number, Year: number, TotalMtm: number, MTM: number, Status: string }> } } param0.reportData  */
export function Reports({ payloadData, reportData, onReportRowClick=() => undefined, selUser = "", selStg = "" }) {
    return (
        <Box>
            <SectionHeading
                heading={`Report`}
                withDivider
            >
                <Box>
                    <Button
                        variant="contained"
                        style={{ textTransform: "initial", marginBottom: 5 }}
                        size="small"
                        onClick={() => {
                            
                            // if (reportData?.report && reportData?.report.length) {
                            //     const data = {
                            //         "columns": Object.keys(reportData.report[0]),
                            //         "rows": reportData.report.map((e) => Object.values(e))
                            //     }

                            //     ExportXLSX("export", data)
                            // }

                            // Export XLSX
                            root.apis.archive.exportReport(payloadData, selUser)

                        }}
                    // excel icon
                    >
                        Export to Excel
                    </Button>
                </Box>
            </SectionHeading>

            <ReportList
                reportData={(reportData?.report) || []}
                onReportRowClick={onReportRowClick}
            />
        </Box>
    )
}