export function DigitFormatter(num, digits) {
    if (num !== 0) {
        const nag = String(num), isNag = nag.indexOf('-') != -1
        
        if (isNag) {
            num = Number(nag.replace('-',''))
        }
        
        const mp = [
            { val: 0,   smb: "" },
            { val: 1,   smb: "" },
            { val: 1e3, smb: "K" }, // Thousand
            { val: 1e6, smb: "M" }, // Million
            { val: 1e9, smb: "B" }, // Billion
            { val: 1e12,smb: "T" }, // Trillion
            { val: 1e15,smb: "P" }, // Quadrillion
            { val: 1e18,smb: "E" } // Quintillion
        ],
        
        item = mp.slice().reverse().find(v=>num>=v.val),
        nmbr = (item.val > 0 ? num / item.val : num).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + item.smb;
        
        return item ? isNag ? '-'+nmbr : nmbr : '0'
    }

    return num
}