import { StrategyAction } from "./StrategyAction" 
import { useUrlParams } from "utils/useUrlParams"
import { StrategyHome } from "./StrategyHome"
import { StrategyAssign } from "./StrategyAssign"

import Box from "@mui/material/Box";

export function StrategyManagement() {
    document.title = "Strategy Management";

    const [searchParams] = useUrlParams()

    return (
        <Box>
            {
                searchParams.get("type") === "assign" ? 
                    <StrategyAssign />
                :
                (searchParams.get("type") === "create" || searchParams.get("type") === "edit") ? 
                    <StrategyAction />
                : <StrategyHome />
            }
        </Box>
    )

}