import { asideMenus, asideMenusWidth, topNavBarHeight } from './AsideMenus';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import { useLocation, useNavigate } from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ListItemButton from '@mui/material/ListItemButton';
import { useSmallScreen } from 'helpers/useSmallScreen';
import { useDispatch, useSelector } from 'react-redux';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useRef, useState } from 'react';
import { useUrlParams } from 'utils/useUrlParams';
import { Button, Drawer } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import { brown } from '@mui/material/colors';
import { commonSlice } from 'redux/Reducer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { Logout } from './Logout';
import { root } from 'Root/root';

function WithMobileDrawer({ withSideBar=false, isBelowSm = false, open, setDrawerOpen, onClose, onOpen, children }) {
    const drawerRef = useRef()
    const commonRef = useRef()
    
    
    useEffect(()=>{
        if(commonRef.current)window.clearTimeout(commonRef.current)

        var ele, hover        
        commonRef.current = window.setTimeout(()=>{
            if(drawerRef.current && !withSideBar && !isBelowSm ){
                hover = () => {
                    if(!open){
                        setDrawerOpen(true)
                    }
                }
                ele = window.document.querySelector("div.PrivateSwipeArea-root") // "PrivateSwipeArea-root" created by mui SwipeableDrawer
                if(ele){
                    ele.addEventListener("mouseover", hover)
                }
            }
    
            if(open){
                if(ele)ele.removeEventListener("mouseover", hover);
            }
        }, 500)

        return()=>{
            if(ele)ele.removeEventListener("mouseover", hover)
        }
    },[withSideBar])


    return (
        (isBelowSm || !withSideBar) ?
            <SwipeableDrawer
                anchor="left"
                open={open}
                onClose={onClose}
                onOpen={onOpen}
                ref={drawerRef}
                disableBackdropTransition
                allowSwipeInChildren={e=>{
                    console.log("slide", e)
                }}
            >

                {children}
            </SwipeableDrawer>
            :
            children
    )
}

export function AsideNav() {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isBelowSm = useSmallScreen()
    const {withSideBar, user} = useSelector((state)=> state.common)
    const [searchParams, updateParams] = useUrlParams()
    const [activeMenu, setActiveMenu] = useState();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)


    const toggleDrawer = (event, state) => {
        if ( event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift') )return;

        if(searchParams.has('withDrawerOpen')){
            searchParams.delete("withDrawerOpen")
            updateParams()
        }else setIsDrawerOpen(state);
    };

    useEffect(() => {
        let activeMen = ""
        for (let menu of asideMenus) {
            if (location.pathname === "/" + menu.routePathName) {
                activeMen = menu.routePathName;
                break;
            }
        }

        // default route will be home
        if (!activeMen && location.pathname === "/") {
            activeMen = "home"
        }

        setActiveMenu(activeMen)

    }, [location.pathname])


    useEffect(() => {
        setIsDrawerOpen(searchParams.has('withDrawerOpen'))
    }, [searchParams.has('withDrawerOpen')])

    return (
        <aside style={{ height: "100%", position: "relative" }}>
            <WithMobileDrawer
                open={isDrawerOpen}
                setDrawerOpen={setIsDrawerOpen}
                onOpen={e => toggleDrawer(e, true)}
                onClose={e => toggleDrawer(e, false)}
                isBelowSm={isBelowSm}
                withSideBar={withSideBar}
            >
                <Drawer
                    anchor={"left"}
                    open={true}
                    onClose={_ => null}
                    sx={{ width: asideMenusWidth, flexShrink: 0 }}
                    PaperProps={{
                        sx: {
                            backgroundColor: "#000", //grey[800],
                            color: "#fff",
                            boxSizing: 'border-box',
                            width: asideMenusWidth,
                            // height: "100%",
                        }
                    }}
                    variant="permanent"
                >
                    <Box height={"100%"}>
                        <Box height={topNavBarHeight} display="flex" justifyContent="space-around" alignItems="center" bgcolor="#154A8E">
                            {/* logo */}
                            <img src={root.constant.ApplicationIcon.MAIN} height={50} width={200} />
                            <Box>
                                <Button
                                    variant='text'
                                    sx={{ p: 1, minWidth: 'fit-content', color: "white" }}
                                    onClick={()=>{
                                        dispatch(commonSlice.actions.setSideBar(!withSideBar))
                                        if(!withSideBar)setIsDrawerOpen(false)
                                    }}
                                >
                                    <MenuOpenRoundedIcon />
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                overflow: "auto",
                                height: '80vh',
                            }}
                            className="scrollbar"
                        >
                            <List
                                sx={{
                                    p: 0,
                                    "& li:hover": {
                                        color: brown[50],
                                    },
                                    "& li.MuiListItem-root":{
                                        py: 0,
                                    }
                                }}
                            >
                                <ListItem 
                                    key="userName"
                                >
                                    <ListItemText
                                        primary={"Welcome, " + user.full_name + "!"}
                                        sx={{
                                            marginLeft: 2,
                                            marginY: 3
                                        }}
                                    />
                                </ListItem>

                                {asideMenus.map(({ name, routePathName = "", Icon, userType, onClick }, index) => {

                                    if (!user.is_admin && userType === "admin") {
                                        return
                                    }

                                    return (
                                        <ListItem 
                                            key={name + routePathName}
                                            sx={[
                                                activeMenu === routePathName ? {
                                                    bgcolor: "#b8b1b1",
                                                    color: "#000"
                                                } : {
                                                    "&:hover":{
                                                        bgcolor: "gray"
                                                    }
                                                }
                                            ]}
                                        >
                                            <ListItemText>
                                                <ListItemButton
                                                    // onClick={() => navigate("/" + routePathName)}
                                                    onClick={() => onClick ? onClick({navigate, user}) : navigate("/" + routePathName)}
                                                >
                                                    {Icon ? <Icon /> : null}
                                                    <ListItemText
                                                        primary={name}
                                                        sx={{
                                                            marginLeft: 2
                                                        }}
                                                    />
                                                </ListItemButton>
                                            </ListItemText>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Box>

                        <Box
                            sx={{
                                position: "fixed",
                                bottom: 0,
                                left: 0,
                                mt: isBelowSm ? 5 : 0,
                                width: asideMenusWidth - 1,
                            }}
                        >
                            <Logout />
                        </Box>
                    </Box>

                    {
                        asideMenus.map(({ AsideComponent, routePathName = "" }) => {
                            return (
                                AsideComponent && routePathName === activeMenu ?
                                    <AsideComponent key={routePathName} />
                                    : null
                            )
                        })
                    }

                </Drawer>
            </WithMobileDrawer>
        </aside>
    )
}