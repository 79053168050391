import { useEffect, useRef } from 'react';
import { createChart, ColorType } from 'lightweight-charts';

export const ChartComponent = props => {
	const {
		data,
        height=500,
		colors: {
			backgroundColor = '#000',
			lineColor = '#2962FF',
			textColor = '#fff',
			areaTopColor = '#2962FF',
			areaBottomColor = 'rgba(41, 98, 255, 0.28)',
		} = {},
		withTime=true,
		chartRef=undefined
	} = props;

	const chartContainerRef = useRef();

	useEffect(
		() => {
			const handleResize = () => {
				chart.applyOptions({ width: chartContainerRef.current.clientWidth });
			};

			const chart = createChart(chartContainerRef.current, {
				layout: {
					background: { 
                        type: ColorType.Solid, 
                        color: backgroundColor 
                    },
					textColor,
				},
				width: chartContainerRef.current.clientWidth,
				height: height,
                grid: {
					vertLines: {
						visible: false,
					},
					horzLines: {
						visible: false
					},
				},
			});
			chart.timeScale().fitContent();

			if (withTime) {
				chart.timeScale().applyOptions({
					timeVisible: true,
					secondsVisible: true,
				});
			}

			const newSeries = chart.addAreaSeries({ 
                lineColor, 
                topColor: areaTopColor, 
                bottomColor: areaBottomColor,
                lineVisible: false,
            });
            // const negativeSeries = chart.addAreaSeries({ 
            //     lineColor: "red", 
            //     // topColor: areaTopColor, 
            //     // bottomColor: areaBottomColor,
            //     lineVisible: false,
            //     backgroundColor: "#000"
            // });
			newSeries.setData(data);
            // negativeSeries.setData(negativeArea)

			if (chartRef) {
				chartRef.current.areaChart = newSeries
			}
			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);

				chart.remove();
			};
		},
		[data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
	);

	return (
		<div
			ref={chartContainerRef}
		/>
	);
};