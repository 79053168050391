import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { IntradayPAndLChart } from "./IntradayPAndLChart";
import { Box, Button, Typography } from "@mui/material";
import { SectionHeading } from "helpers/SectionHeading";
import CachedIcon from '@mui/icons-material/Cached';
import { useEffect, useRef, useState } from "react";
import { useUrlParams } from "utils/useUrlParams";
import { red, green } from "@mui/material/colors"
import { useNavigate } from "react-router-dom";
import { NetPosition } from "./NetPosition";
import { Socket } from 'Root/Common/Socket';
import { mToast } from 'Root/Common/Toast';
import { useSelector } from 'react-redux';
import { OrderBook } from "./OrderBook";
import Alert from '@mui/material/Alert';
import { Strategy } from "./Strategy";
import { root } from "Root/root";

export function User() {
    const [searchParams, updateParams, removeParams] = useUrlParams()
    const lUser = useSelector((state)=> state.common.user)
    const navigate = useNavigate();

    const socketFunHandler = useRef({
        trade() {},
        posData() {},
        strategyMTM() {},
        totalMTM() {},
        newTrade() {},
        liveMtm() {},
        newOrder() {},
        newBOrder() {},
        clData() {}
    })
    const [isAccEnable, setIsAccEnable] = useState(null)
    // const [accMargin, setAccMargin] = useState({})

    socketFunHandler.current.clData = (data) => {
        setIsAccEnable(data.isAccEnable)
        // setAccMargin(data.margin)
    }

    const userId = searchParams.get("userId")
    const brokerUserId = searchParams.get("brokerUserId")

    // Check if this Login User has access to the user account or not
    const users = lUser.users
    
    // Checking if the userId and brokerId has permissions to access in the current login user account
    useEffect(() => {
        var isUserValid = false

        users.forEach(u => {
            if (userId == u.user_id && brokerUserId == u.broker_id) {
                isUserValid = true
                return;
            }
        })

        if (!isUserValid) {
            mToast.error("Permission denied!")
            navigate("/positions")
        }
    }, [])

    // useEffect(()=>{
    //     if(userId){
    //         root.apis.positions.get(userId)
    //         .then(d=>{
    //             // console.log('d = ',d.data)
    //         })
    //         .catch(e=>{
    //             mToast.error(e.message)
    //         })

    //     }
        
    // },[userId])

    const posClientPingPong = async (socket) => {        
        while (socket.socket.readyState === WebSocket.OPEN) {
            console.log("Sending posClientPingPong request")

            socket.send(JSON.stringify({"brokerUserId": brokerUserId, "wbType": "single-client"}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const posClientSocket = () => {
        const connData = {"userId": userId, "brokerUserId": brokerUserId, "wbType": "single-client"}
        const connQuery = new URLSearchParams(connData);

        var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/position/?" + connQuery);

        socket.onOpen(async function () {
            console.log("Connecting to positions (single-client) broadcast websocket")    

            socket.send(JSON.stringify({"brokerUserId": brokerUserId, "wbType": "single-client"}))
            await posClientPingPong(socket);
        })
        
        socket.onMessage(function (data) {
            let posData = data.posData;
            let msg = data.msg;

            if (msg) {
                console.log(msg)
            }

            if (posData) {
                for (let tradeKey in posData) {
                    if (typeof socketFunHandler.current[tradeKey] === "function") {
                        socketFunHandler.current[tradeKey] (posData[tradeKey])
                    }
                }
            }
        })

        var isClSocketClose = false;
    
        socket.onClose(function(event) {
            console.log("Positions (single-client) broadcast websocket connection closed", event)

            // Attempt to reconnect to the websocket after a delay
            if (!isClSocketClose) {
                setTimeout(() => {
                    console.log(`Reconnecting to position (single-client) webSocket ...`);

                    posClientSocket()
                }, 1000);
            }
        })

        return () => {
            isClSocketClose = true
            socket.close()
        }
    }

    const liveMtmPingPong = async (socket) => {        
        while (socket.socket.readyState === WebSocket.OPEN) {
            console.log("Sending liveMtmPingPong request")

            socket.send(JSON.stringify({"userId": userId, "wbType": "single-client"}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const liveMtmSocket = () => {
        const connData = {"userId": userId, "wbType": "single-client"}
        const connQuery = new URLSearchParams(connData);

        var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/live_mtm/?" + connQuery);

        socket.onOpen(async function () {
            console.log("Connecting to live mtm (single-client) broadcast websocket")    

            socket.send(JSON.stringify({"userId": userId, "wbType": "single-client"}))
            await liveMtmPingPong(socket);
        })
        
        socket.onMessage(function (data) {
            let liveMtm = data.liveMtm;
            let msg = data.msg;

            if (msg) {
                console.log(msg)
            }

            if (liveMtm) {
                socketFunHandler.current["liveMtm"] (liveMtm)
            }
        })

        var isMtmSocketClose = false;
    
        socket.onClose(function(event) {
            console.log("Live mtm (single-client) broadcast websocket connection closed", event)

            // Attempt to reconnect to the websocket after a delay
            if (!isMtmSocketClose) {
                setTimeout(() => {
                    console.log(`Reconnecting to live mtm (single-client) webSocket ...`);

                    liveMtmSocket()
                }, 1000);
            }
        })

        return () => {
            isMtmSocketClose = true
            socket.close()
        }
    }

    useEffect(() => {
        var posClient = posClientSocket()
        var liveMtm = liveMtmSocket()

        return () => {
            posClient()
            liveMtm()
        };
    }, [])

    return (
        <Box>
            <SectionHeading
                heading={`Hi, ${searchParams.get("fullName")}`}
                hComponents={
                    <Box display="flex" alignItems="center">
                        <Box 
                            style={{ 
                                width: 16, 
                                height: 16, 
                                backgroundColor: (isAccEnable ? green: red)[800], 
                                borderRadius: 50 
                            }}
                        ></Box>
                    </Box>
                }
                hCStyle={{
                    marginLeft: 1.5
                }}
            >
                <Box>
                    {/* Reload Button */}
                    <Button 
                        sx={{ minWidth: 20, p: 0 }} 
                        color="primary" 
                        onClick={() => {
                            window.location.reload();
                        }} 
                    > <CachedIcon sx={{ fontSize: 30 }} /> </Button>

                    {/* Back Button */}
                    <Button 
                        variant="outlined"
                        style={{ textTransform: "initial" }}
                        color="inherit"
                        onClick={() => removeParams()}
                        sx={{ ml: 3 }} 
                    >
                        Back
                    </Button>

                    {/* Squareoff Button */}
                    {/* <Button
                        variant="contained"
                        style={{ textTransform: "initial" }}
                        onClick={() => {
                            
                            // root.apis.utils.square_off({
                            root.apis.orderConfig.enableDisableClientStrategies(parseInt(userId), true, true)
                            .then((data) => {
                                if (data.status === 201) {
                                    if (data.data.msg) {
                                        mToast.success(data.data.msg)
                                    }

                                    if (data.data.err) {
                                        mToast.error(data.data.err)
                                    }
                                }
                                else {
                                    mToast.error("Couldn't complete request. Something went wrong!")
                                }
                            })
                            .catch(e => {
                                const e_resp = e.response

                                if (e_resp.status === 403) {
                                    mToast.error(e_resp.data.err)
                                }
                                else {
                                    mToast.error("Couldn't complete request. Something went wrong!")
                                }
                            })

                        }}
                    >
                        Square Off
                    </Button> */}
                </Box>
            </SectionHeading>

            {
                isAccEnable === false
                ?
                    <Box mt={2} fontSize={15}>
                        <Alert 
                            icon={
                                <ErrorOutlineIcon 
                                    fontSize="inherit" 
                                />
                            } 
                            severity="warning"
                            // onClose={() => {}}
                        >
                            <Typography fontSize={15}>
                                Your account is disabled, no trades will be executed. This includes both initiating new positions and squaring off existing positions, whether manually or within your strategies. To enable your account, you will need to restart your strategy. 
                            </Typography>
                        </Alert>
                    </Box>
                : ""
            }

            <Box
                sx={{
                    // height: "73vh",
                    overflow: "auto",
                    mt: 2
                }}
                className="scrollbar"
            >
                {
                    [
                        Strategy,
                        NetPosition,
                        OrderBook,
                        IntradayPAndLChart
                    ].map((Component, index) => (
                        <Box mt={index ? 2 : 0} key={index}>
                            <Component 
                                userId={userId}
                                searchParams={searchParams}
                                socketFunHandler={socketFunHandler}
                                isAccEnable={isAccEnable}
                                // accMargin={accMargin}
                            />
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}