import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import BottomNavigation from '@mui/material/BottomNavigation';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { TradeProfitAndLoss } from "./TradeProfitAndLoss";
import { useEffect, useRef, useState } from "react";
import { marketWatch } from 'redux/Reducer';
import { MarketWatch } from "./marketWatch/MarketWatch";
import { useSelector, useDispatch } from 'react-redux';
import { TradeBook } from "./TradeBook";
import Paper from '@mui/material/Paper';
import { Position } from "./Position";
import Box from "@mui/material/Box";
import { root } from 'Root/root';
import { refreshTemplates } from "./common/marketWatchUtils";
import { Socket } from 'Root/Common/Socket';

export function Section() {
    document.title = "Market Watch";

    const sectionList = useRef([
        { key: "marketWatch", name: "Market Watch", component: MarketWatch, icon: ViewTimelineIcon },
        { key: "position", name: "Position", component: Position, icon: ShowChartIcon },
        { key: "tradeBook", name: "Trade Book", component: TradeBook, icon: LibraryBooksIcon },
        { key: "tradePandL", name: "Trade P&L", component: TradeProfitAndLoss, icon: SettingsInputCompositeIcon },
    ])
    const dispatch = useDispatch()
    const commonSel = useSelector(selector => selector.common)
    
    const [state, setState] = useState({
        activeIndex: 0,
        activeSection: sectionList.current[0] // @sectionList<Object>
    })
    
    const [mktReconnect, setMktReconnect] = useState({
        "marketWatch": false,
        "position": false
    })
    const mktReconnectRef = useRef(mktReconnect)

    const toggleMktReconnect = (wbName) => {
        const newReconnect = Object.keys(mktReconnect).reduce((acc, key) => {
            acc[key] = key === wbName;

            return acc;
        }, {});

        // console.log("newReconnect", newReconnect)
        setMktReconnect(newReconnect);
        mktReconnectRef.current = newReconnect
    };

    const user = commonSel.user
    const socketFuncHandler = useRef({
        marketWatch() {},
        position() {},
        notif() {}
    })

    const mktWatchPingPong = async (socket, wbType) => {      
        while (socket.socket.readyState === WebSocket.OPEN && mktReconnectRef.current[wbType]) {
            console.log("Sending mktWatchPingPong request")

            socket.send(JSON.stringify({"loginId": user.id, "wbType": wbType}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const mktWatchSocket = (wbType) => {
        const connData = {"loginId": user.id, "wbType": wbType}
        const connQuery = new URLSearchParams(connData);

        var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/market_watch/?" + connQuery);

        socket.onOpen(async function () {
            console.log(`Connecting to ${wbType} broadcast websocket`)    

            socket.send(JSON.stringify({"loginId": user.id, "wbType": wbType}))
            await mktWatchPingPong(socket, wbType);
        })

        socket.onMessage(function (data) {
            let mktData = data.mktData;
            let msg = data.msg;

            if (msg) {
                console.log(msg)
            }

            if (mktData) {
                for (let sectionKey in mktData) {
                    if (typeof socketFuncHandler.current[sectionKey] === "function") {
                        socketFuncHandler.current[sectionKey] (mktData[sectionKey])
                    }
                }
            }
        })
    
        var isMktWSocketClose = false;

        socket.onClose(function (event) {
            console.log(`${wbType} websocket connection closed`, event)
            // console.log(`${wbType} reconnect =>`, mktReconnectRef.current, mktReconnect)

            // Attempt to reconnect to the websocket after a delay
            if (!isMktWSocketClose && mktReconnectRef.current[wbType]) {
                setTimeout(() => {
                    console.log(`Reconnecting to ${wbType} websocket ...`);
                    
                    mktWatchSocket(wbType);
                }, 1000);
            }
        })

        return () => {
            isMktWSocketClose = true
            socket.close()

            // delete the all market data if required
            dispatch(marketWatch.actions.resetAll())
        }
    }

    // Connect marketwatch websocket
    const activeSectionKey = state.activeSection.key
    
    useEffect(() => {
        refreshTemplates(dispatch, commonSel)
        
        if (["marketWatch", "position"].includes(activeSectionKey)) {
            toggleMktReconnect(activeSectionKey)

            return mktWatchSocket(activeSectionKey)
        }
        else {
            toggleMktReconnect(activeSectionKey)
        }

        return () => {
            // delete the all market data if required
            dispatch(marketWatch.actions.resetAll())
        }
    }, [activeSectionKey])

    return (
        <Box>

            <Box mb={5} sx={{
                "button.MuiButton-outlined.Mui-disabled": {
                    // backgroundColor: "grey"
                    color: "rgb(254 254 254 / 43%)",
                    border: "1px solid rgb(107 104 104)"
                }
            }}>
                <state.activeSection.component
                    socketFuncHandler={socketFuncHandler}
                />
            </Box>

            {/* bottom headers */}
            <Paper
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
                elevation={3}
            >
                <BottomNavigation
                    showLabels
                    value={state.activeIndex}
                    onChange={(_, newValue) => {
                        setState({
                            ...state,
                            activeIndex: newValue,
                            activeSection: sectionList.current[newValue]
                        })
                    }}
                    sx={{
                        bgcolor: "#2E7D32",
                        height: 32,
                        "& button.MuiBottomNavigationAction-root.Mui-selected": {
                            bgcolor: "#000",
                            color: "#fff",
                            
                        }
                    }}
                >
                    {
                        sectionList.current.map((section) => (
                            <BottomNavigationAction
                                key={section.key}
                                label={
                                    <Box 
                                        display="flex" 
                                        gap={1}
                                        color="#fff"
                                    >
                                        <section.icon sx={{ transform: "scale(.7)" }} />{section.name}
                                    </Box>}
                            />
                        ))
                    }
                </BottomNavigation>
            </Paper>
        </Box>
    )
}