export function getCookie(name) {
    const cookies = document.cookie.split('; ');
    
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=');
        
        if (cookieName === name) {
            return decodeURIComponent(cookieValue);
        }
    }

    return null;
}

export function setCookie(name, value, minutes=0) {
    const expirationDate = new Date();
    // expirationDate.setMinutes(expirationDate.getMinutes() + minutes)
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);

    const cookieValue = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieValue;
}

export function deleteCookie(name) {
    document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}