const OrderConfig = (self) => ({

    get(userId=undefined, params={}) {
        return self.axios.get(self.getRestUrl(["order_mgt", userId].filter(e=>e)), { params })
    },

    /** @param { number} userId  @param { boolean } isEnable @param { boolean } onlySquareOff */
    enableDisableClientStrategies(userId, isEnable, onlySquareOff, propTag){
        return self.axios.post(self.getRestUrl("order_mgt/client"), { userId, isEnable, onlySquareOff, propTag })
    },

    /** @param {{ stgId: number, stgName: string, userId: number, lots: number }} payload  */
    updateLots(payload){
        return self.axios.post(self.getRestUrl("order_mgt/client/strategy/update_lots"), payload)
    },

    /** @param {{ stgId: number, stgName: string, userId: number }} payload  @param { boolean } [isStop=true] */
    stopReStartStrategy(payload, isStop=true){
        return self.axios.post(self.getRestUrl("order_mgt/client/strategy/" + (isStop?"stop":"restart")), payload)
    },

    /** @param { number } stgId @param { string } stgName */
    cashOut(stgId, stgName, propTag){
        return self.axios.post(self.getRestUrl("order_mgt/strategy/stop"), { stgId, stgName, propTag })
    },

    /** @param {{ stgId: number, stgName: string, userId: number, config: { lots: number, max_mtm: number, min_mtm: number, en_slc_type: string, en_slc_value: number, ex_slc_type: string, ex_slc_value: number } }} payload */
    modifyConfig(payload){
        return self.axios.post(self.getRestUrl("order_mgt/client/strategy/modify_config"), payload)
    },

    /** @param {{ userId: number, stgId: number }} payload */
    stgReEntryFix(payload){
        return self.axios.post(self.getRestUrl("order_mgt/client/strategy/re_entry"), payload)
    },

    /** @param {{ userId: number, brokerUserId: string, excInstId: number, stgId: number }} payload */
    symbolExit(payload){
        return self.axios.post(self.getRestUrl("order_mgt/sq_sym"), payload)
    },

    getStgConfig(stgId, params={}) {
        return self.axios.get(self.getRestUrl(["order_mgt", "strategy", "config", stgId]), { params })
    },
    
})

export default OrderConfig