import { BrowserRouter, Routes as RRoutes, Route, useLocation, useNavigate } from "react-router-dom";
import { Positions } from "components/body/positions";
import Typography from "@mui/material/Typography";
import { constant } from "Root/Common/constant";
import { asideMenus } from "../nav/AsideMenus";
import { getCookie, deleteCookie } from "helpers/Cookies";
import { commonSlice } from "redux/Reducer";
import { mToast } from "Root/Common/Toast";
import { useDispatch } from 'react-redux';
import Box from "@mui/material/Box";
import { Home } from "../body/home";
import { root } from "Root/root";
import React from "react";

function NoPage(){
    return(
        <Box style={{ textAlign: 'center' }}>
            <Typography>404 Page Not Found</Typography>
        </Box>
    )
}

const routes = asideMenus.map(menu=>{
    return <Route key={menu.routePathName} path={menu.routePathName} element={<menu.component />} />
})

const CaptureRequest = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginId = getCookie(constant.Cookies.LoginId)
    
    // For adding restriction to the routes
    React.useEffect(() => {
        if (loginId) {
            const payload = {
                "loginId": loginId,
                "accessToken": getCookie(constant.Cookies.AccessToken)
            }
    
            root.apis.loginUsers.validateAccess(payload)
            .then((data) => {
                const d = data.data
                const isAdmin = d.user.is_admin
                const isValid = d.isValid
            
                if (!isValid) {
                    dispatch(commonSlice.actions.setIsLogin(false))
    
                    deleteCookie(constant.Cookies.LoginId)
                    deleteCookie(constant.Cookies.AccessToken)
                    
                    return;
                }
    
                if (!isAdmin) {
                    const dReRoutePath = constant.Authorization.DefaultReRoutePath
                    const nonAdminRoutes = constant.Authorization.NonAdminRoutes
                    
                    const urlPath = location.pathname
        
                    if (!nonAdminRoutes.includes(urlPath)) {
                        navigate(dReRoutePath);
                        return;
                    }
                }
            })
            .catch((err) => {
                mToast.error(err.message)
            })
        }
    }, [location]);
  
    return null;
};

export function Routes({ children }) {

    return (
        <BrowserRouter>
            <CaptureRequest />

            <RRoutes>
                <Route path="/" element={children}>
                    <Route index element={<Positions />} />
                    { routes }
                    <Route path="*" element={<NoPage />} />
                </Route>
            </RRoutes>
        </BrowserRouter>
    )
}