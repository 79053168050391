import { Button, MenuItem, Menu, TextField, Checkbox, FormControlLabel, Divider } from "@mui/material";
import { cloneElement, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";


/**
 * @param {{
 *  defaultValue: string | number
 *  value: string | Array<{ value: string | number | boolean, label?:  string | number | boolean, selected?: boolean }>
 *  options: Array<{ value: string | number | boolean, label?:  string | number | boolean, selected?: boolean }>
 *  onSelect: (
 *      obj: { value: string | number | boolean, label?: string | number | boolean, selected?: boolean } | Array<{ value: string | number | boolean, label?:  string | number | boolean, selected?: boolean }>,
 *      isSelected: boolean, 
 *      isArray: boolean
 *  ) => void
 *  maxWidth?: number - max-width of the value box. value will hide if length of the value will max then width of box
 *  withSelectAllControl?: boolean
 *  withSeachBar?: boolean
 *  multiselect?: boolean - if true, then checkbox will visible with each list items
 *  selectAllText?: string
 *  unSelectAllText?: string
 *  searchPlaceholder?: string
 *  onSearchInput?: undefined | function
 *  searchValue?: string
 *  children?: React.Component<{ withDefaultValue: boolean, defaultValue: string | number, value: string | number  }> user can change the component by passing the children
 *  }}
 * @returns React.JSX.Element
 */
export function DropDown({
    children,
    defaultValue = "",
    value = "",
    maxWidth = 300,
    withSelectAllControl = false,
    withSeachBar = true,
    options = [],
    multiselect = false,
    onSelect = () => undefined,
    selectAllText = "Select All",
    unSelectAllText = "Unselect All",
    searchPlaceholder = "Search Symbol...",
    onSearchInput = undefined,
    searchValue = "",
}) {
    const inputRef = useRef(null)
    const [isSelectOpen, setIsSelectOpen] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [optionsList, setOptionsList] = useState({
        isAllSelected: false,
        options,
    })

    const updateDefaultOptions = () => {
        setOptionsList(prev => ({
            ...prev,
            options,
            isAllSelected: options.filter(obj => obj.selected).length === options.length
        }))
    }

    const handleSelect = (obj) => {
        if (obj) {
            onSelect(obj, !obj.selected)
        };

        if (!multiselect || !obj) {
            setIsSelectOpen(false)
            setSearchText("")
            updateDefaultOptions()
        }
    }


    useEffect(() => {
        updateDefaultOptions()
    }, [options])

    return (
        <Box tabIndex="1">

            <Box className="mk-tab-event-enter" display="flex" ref={inputRef} gap={1} alignItems="center" onClick={() => {
                setIsSelectOpen(true)
                setTimeout(() => {
                    var searchInput = document.querySelector(".m-inputSearch")

                    if (searchInput) {
                        searchInput.autoFocus = true
                        searchInput.focus()
                    }
                }, 600)
            }}>
                {
                    children ?
                        cloneElement(children, {
                            withDefaultValue: !(Array.isArray(value) ? value.length : value),
                            defaultValue,
                            value: Array.isArray(value) ? value.map(obj => obj.value).join(', ') : value
                        })
                    : 
                    (Array.isArray(value) ? value.length : value) ?
                        <Box
                            sx={{
                                maxWidth,
                                border: '1px solid',
                                borderRadius: 10,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                px: 1
                            }}
                        >
                            {Array.isArray(value) ? value.map(obj => obj.value).join(', ') : value}
                        </Box>
                        :
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                p: 0,
                                px: 1,
                                textTransform: "initial"
                            }}
                        >
                            {defaultValue}
                        </Button>
                }
            </Box>

            <Menu
                anchorEl={inputRef.current}
                open={isSelectOpen}
                onClose={() => handleSelect()}
            >
                <MenuItem
                    sx={{
                        p: 0,
                        "&:hover": {
                            bgcolor: "transparent"
                        }
                    }}
                >
                    <Box>
                        {
                            withSeachBar && 
                                <TextField
                                    autoFocus
                                    placeholder={searchPlaceholder}
                                    value={onSearchInput?searchValue:searchText}
                                    sx={{ p: 1 }}
                                    inputProps={{
                                        sx: {
                                            color: "#000",
                                            p: 1
                                        },
                                        className: "m-inputSearch"
                                    }}
                                    onKeyDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                    onChange={onSearchInput || (e => {
                                        setSearchText(e.target.value)
                                        let v = e.target.value.toLowerCase()
                                        var totalSelected = 0

                                        const filtered = v ? options.filter(obj => {
                                            if (obj.value.toLowerCase().indexOf(v) !== -1) {
                                                if (obj.selected) totalSelected++
                                                return true
                                            }
                                            return false
                                        }) : options

                                        setOptionsList({
                                            isAllSelected: filtered.length === (v ? totalSelected : options.filter(obj=>obj.selected).length),
                                            options: filtered
                                        })
                                    })}
                                    autoComplete="off"
                                />
                        }

                        {
                            withSelectAllControl ?
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={optionsList.isAllSelected}
                                                onChange={(e) => {
                                                    onSelect(optionsList.options, e.target.checked, true)
                                                    setOptionsList({
                                                        ...optionsList,
                                                        isAllSelected: optionsList.options.filter(obj => obj.selected).length === optionsList.options.length
                                                    })
                                                }}
                                            />
                                        }
                                        label={optionsList.isAllSelected ? unSelectAllText : selectAllText}
                                        sx={{
                                            color: "#000",
                                            px: 1.3
                                        }}
                                    />

                                    <Divider />

                                </Box>
                                : null
                        }
                    </Box>
                </MenuItem>
                {
                    optionsList.options.map(obj => {
                        return (
                            <MenuItem
                                key={obj.value}
                                sx={{ color: '#000', ...multiselect && { px: 0, py: 0 } }}
                                onClick={() => handleSelect(obj)}
                            >
                                {
                                    multiselect ?
                                        <Checkbox
                                            size="small"
                                            checked={Boolean(obj.selected)}
                                            onChange={() => undefined}
                                        />
                                        : null
                                }
                                {obj.label ?? obj.value}
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </Box>
    )
}

