import { DateComponent, DropDownComponent, MultiSelectDropDownComponent } from "./UserInputs";
import { SectionHeading } from "helpers/SectionHeading";
import { UserTradeDetails } from "./UserTradeDetails";
import { PAndLReport } from "./PAndLReport";
import { mToast } from "Root/Common/Toast";
import { UserInputs } from "./UserInputs";
import { Reports } from "../reports";
import Box from "@mui/material/Box";
import { useState } from "react";
import { root } from "Root/root";
import moment from 'moment';

export function Archive(){
    document.title = "Archive";

    const [reportData, setReportData] = useState(null)
    const [payloadData, setPayloadData] = useState({})
    const [pageData, setPageData] = useState({
        openPage: "",
        data: null
    })
    const [selUser, setSelUser] = useState("")
    const [selStg, setSelStg] = useState([])

    const cDate = new Date();

    const [inputs, setInputs] = useState([
        // key: should be same as api flags
        { label: "Username", Component: DropDownComponent, optionsList: [], key: "userId" },
        { label: "Strategy", Component: MultiSelectDropDownComponent, optionsList: [], selStg: selStg, setSelStg: setSelStg, key: "strategyIds" },
        { label: "Date From", Component: DateComponent, key: "fromDate", type: 'date', default: new Date(
            cDate.getFullYear(),
            cDate.getMonth() - 3, 
            cDate.getDate()
        ) },
        { label: "Date to", Component: DateComponent, key: "toDate", type: 'date', default: cDate }
    ])

    const onReportRowClick = (row) => {
        setPageData({
            ...pageData, openPage: "tradeBook", data: row
        })
    }

    const onGenerateReport = () => {
        inputs.forEach((input) => {
            if (["userId", "fromDate", "toDate"].includes(input.key)) {
                if (!input.value) {
                    mToast.info("Please enter all required fields")
                    return; 
                }
            }
            else {
                if (selStg.length === 0) {
                    mToast.info("Please enter all required fields")
                    return; 
                }
            }
        })

        const payload = {
            // fromDate: "2023-06-03T14:54:30.381Z",
            // strategyIds: 6,
            // toDate: "2023-08-03T14:54:30.381Z",
            // userId: 1
        }

        inputs.forEach(el => {
            if(el.key === "userId"){
                setSelUser(el.value)
                payload[el.key] = el.optionsList.find(e => e.value === el.value)?.data.id
            }
            else if(el.key === "strategyIds") {
                payload[el.key] = selStg.map(stg => stg?.data.stgId)
            }
            else if(el.type === 'date'){
                payload[el.key] = moment(el.value).format("yyyy-MM-DDTHH:mm:ss.SSS")
            }
        });

        setPayloadData(payload)

        root.apis.archive.genReport(payload)
        .then(d=>{
            if(d.data.err){
                mToast.error(d.data.err);
                setReportData(false)
            }else{
                setReportData(d.data)
            }
        })
        .catch(e=>{
            mToast.error(e.message)
            setReportData(false)
        })
    }

    if (pageData.openPage === "tradeBook") {
        return <UserTradeDetails payloadData={payloadData} pageData={pageData} setPageData={setPageData} />
    }

    return(
        <Box mb={15}>
            <SectionHeading
                heading="Archive"
            />

            <Box mt={2}>
                <UserInputs 
                    onGenerateReport={onGenerateReport}
                    inputs={inputs}
                    setInputs={setInputs}
                    selStg={selStg}
                />
            </Box>

            {
                reportData ?
                    <>
                        <Box mt={2}>
                            <PAndLReport reportData={reportData} />
                        </Box>
                        <Box mt={2}>
                            <Reports 
                                payloadData={payloadData}
                                reportData={reportData} 
                                onReportRowClick={onReportRowClick}
                                selUser={selUser}
                                selStg={selStg}
                            />
                        </Box>
                    </>

                : null
            }
        </Box>
    )
}