import { mToast } from "Root/Common/Toast";
import moment from 'moment';

const Archive = (self) => ({
    
    getArchiveStg(userId) {
        return self.axios.get(self.getRestUrl(["archive", "stg", userId]))
    },

    /** @param {{ userId: number, strategyId: number, fromDate: string, toDate: string }} payload dateFormat-2023-08-02T17:43:51.686Z */
    genReport(payload){
        return self.axios.post(self.getRestUrl(["gen_report"]), payload)
    },

    exportReport(payload, selUser) {
        const cDate = moment(Date.now()).format("YYYY-MM-DD_HH:mm:ss");
        selUser = selUser.replace(/ /g,"_")

        self.axios({
            url: self.getRestUrl(["export_report"]),
            method: "POST",
            responseType: "blob",
            headers: {
                "Accept": "application/json"
            },
            data: payload

        }).then((response) => {
            const href = URL.createObjectURL(response.data);
        
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", `${selUser}_${cDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
        
            document.body.removeChild(link);
            URL.revokeObjectURL(href);

        }).catch((error) => {
            console.log("exportReport Error:", error)

            const e_resp = error.response

            if (e_resp) {
                if (e_resp.status === 403) {
                    e_resp.data.text()
                    .then(data => {
                        data = JSON.parse(data)
    
                        mToast.error(data.err)
                    })
                    .catch("Couldn't export report. Something went wrong!")
                }
                else {
                    mToast.error("Couldn't export report. Something went wrong!")
                }
            }
            else {
                mToast.error(error.message)
            }
        });
    },

    getReport(date, params) {
        return self.axios.get(self.getRestUrl(["get_report", date]), { params })
    }

})


export default Archive