import jstat from "jstat";

export default function calcGreek(symbols, vix) {
    const interestRate = 10.00
    const dividend = 0.00
    const tradingDays = 282 
    const thetaDivVal = 282

    vix = vix / 100

    const cDate = new Date();

    const calcDaysToExp = (expDate) => {
        expDate = new Date(expDate);
        return ((expDate - cDate) / (1000 * 60 * 60 * 24))
    }

    const calcValues = (sym, timeToExp) => {
        var delta = 0
        var gamma = 0
        var theta = 0
        var vega = 0
        var rho = 0

        const symName = sym.symbol

        if (symName.includes("CE") | symName.includes("PE")) {
            const ulPrice = sym.ul_price
            const skPrice = sym.strike_price    

            const logVal = Math.log(ulPrice / skPrice)
            const tSigVal = (((interestRate - dividend) / 100) + (Math.pow(vix, 2) / 2)) * timeToExp
            const sigVal = vix * Math.sqrt(timeToExp)
    
            const d1 = (logVal + tSigVal) / sigVal
            const d2 = sigVal - d1
    
            const nD1 = jstat.normal.cdf(d1, 0, 1);
            const negND1 = jstat.normal.cdf(-d1, 0, 1);
    
            const nD2 = jstat.normal.cdf(-d2, 0, 1);
            const negND2 = jstat.normal.cdf(d2, 0, 1);
    
            const intExptVal = Math.exp((-interestRate / 100) * timeToExp)
            const intSkval = skPrice * intExptVal
    
            const divExptVal = Math.exp((-dividend / 100) * timeToExp)
            const divUlVal = ulPrice * divExptVal
    
            gamma = Math.exp((-1 * Math.pow(d1, 2)) / 2) / (Math.sqrt(2 * Math.PI) * divExptVal) / (ulPrice * sigVal)
            vega = Math.exp(-1 * Math.pow(d1, 2) / 2) / Math.sqrt(2 * Math.PI) * divExptVal * ulPrice * Math.sqrt(timeToExp) / 100
            
            if (symName.includes("CE")) {
                delta = nD1 * divExptVal
                theta = (-(ulPrice * Math.exp(-1 * Math.pow(d1, 2) / 2) / Math.sqrt(2 * Math.PI) * vix * divExptVal / (2 * Math.sqrt(timeToExp))) - ((interestRate / 100) * intSkval * nD2) + ((dividend / 100) * ulPrice * nD1 * divExptVal)) / thetaDivVal
                rho = skPrice * timeToExp * intExptVal * nD2 / 100
            }
    
            if (symName.includes("PE")) {
                delta = intExptVal * (nD1 - 1)
                theta = (-(ulPrice * Math.exp(-1 * Math.pow(d1, 2) / 2) / Math.sqrt(2 * Math.PI) * vix * divExptVal / (2 * Math.sqrt(timeToExp))) - ((interestRate / 100) * intSkval * negND2) + ((dividend / 100) * ulPrice * negND1 * divExptVal)) / thetaDivVal
                rho = -skPrice * timeToExp * intExptVal * negND2 / 100
            }
        }

        sym.delta = delta ? delta.toFixed(4) : 0
        sym.gamma = gamma ? gamma.toFixed(4) : 0
        sym.theta = theta ? theta.toFixed(4) : 0
        sym.vega = vega ? vega.toFixed(4) : 0
        sym.rho = rho ? rho.toFixed(4) : 0
    }

    symbols.forEach(sym => {
        const daysToExp = calcDaysToExp(sym.exp_date)
        const timeToExp = (daysToExp / tradingDays) // * 100

        calcValues(sym, timeToExp)
    })

    return symbols
}