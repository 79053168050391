import Box from "@mui/material/Box";

import {
    Chart,
    ArgumentAxis,
    SplineSeries,
    ValueAxis,
    AreaSeries,
    ZoomAndPan,
    Tooltip,
    
} from '@devexpress/dx-react-chart-material-ui';
import { scalePoint, scaleTime } from 'd3-scale';
import { ArgumentScale, EventTracker } from '@devexpress/dx-react-chart';
import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import moment from "moment";

// import Chart, {
//     Series,
//     CommonSeriesSettings
// } from 'devextreme-react/chart';
 
// https://devexpress.github.io/devextreme-reactive/react/chart/docs/guides/zoom-and-pan/
// https://devexpress.github.io/devextreme-reactive/react/chart/demos/area/simple-area/

const generateData = (n) => {
    const ret = [];
    let y = 0;
    const dt = new Date(2023, 2, 10);
    for (let i = 0; i < n; i += 1) {
        dt.setDate(dt.getDate() + 1);
        y += Math.round(Math.random() * 100 - 5);
        ret.push({ x: new Date(dt), y });
    }
    return ret;
};
const _data = generateData(100);

const getMode = (zoom, pan) => {
    if (zoom && pan) {
        return 'both';
    }
    if (zoom && !pan) {
        return 'zoom';
    }
    if (!zoom && pan) {
        return 'pan';
    }
    return 'none';
};


const StyledChartRoot = styled(Chart.Root)(() => ({
    "& #center-axis-container, & #bottom-axis-container": {
        "& svg g text": {
            fill: "#fff"
        },
        "& svg path": {
            stroke: "#e0e0e030"
        }
    }
}));

const RenderToolTip = (...arg) => {
    return (
        <Box sx={{
            color: "black"
        }}>
            {(arg[0] || {}).text}
        </Box>
    )
}


const ChartRoot = props => (
    <StyledChartRoot {...props} />
);



export function AreaChart({ data=_data }) {
    const [state, setState] = useState({
        data,
        zoomArgument: true,
        panArgument: true,
        zoomValue: false,
        panValue: false,
    })

    const [viewport, setViewport] = useState(undefined)


    useEffect(() => {
        if (data !== state.data) {
            setState({
                ...state,
                data
            })
        }
    }, [data])

    return (
        <Chart
            data={state.data}
            rootComponent={ChartRoot}
        >



            {/* <ArgumentScale factory={moment} /> */}
            <ArgumentScale
                name="date"
                factory={scaleTime}
            />

            <ArgumentAxis
                showLabels

            // tickFormat={(scale)=>(tick)=>{
            //     console.log('scal= ', tick)
            //     return moment(tick).format("MM-DD")
            // }}
            // tickComponent={}
            />

            <ValueAxis
                position="right"
                // scaleName="price"
                showGrid={false}
                showLine
                showTicks
            >
                {/* <Strip startValue={40} endValue={50} color="blue" /> */}
                {/* <SplineSeries /> */}
                
            </ValueAxis>



            
            <AreaSeries
                valueField="y"
                argumentField="x"
                // color="red"

            />
            


            <ZoomAndPan
                interactionWithArguments={getMode(state.zoomArgument, state.panArgument)}
                interactionWithValues={getMode(state.zoomValue, state.panValue)}
                viewport={viewport}
                onViewportChange={setViewport}
            />

            <EventTracker />
            <Tooltip
                contentComponent={RenderToolTip}
            />

        </Chart>
    )
}
