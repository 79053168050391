import { Box, Button, Card, CardContent, TextField, Typography } from "@mui/material";
import { constant } from "Root/Common/constant";
import { setCookie } from "helpers/Cookies";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { commonSlice } from "redux/Reducer";
import { mToast } from "Root/Common/Toast";
import { useDispatch } from 'react-redux';
import { root } from "Root/root";

export function Login() {
    const [inputs, setInputs] = useState([
        { label: "Username", key: "username" },
        { label: "Password", key: "password" }
    ]);
    const dispatch = useDispatch()

    const onChangeInputs = (e, index) => {
        inputs[index].value = e.target.value
        setInputs([...inputs])
    }

    const onLogin = () => {
        const payload = {}

        inputs.forEach(elem => {
            payload[elem.key] = elem.value
        });

        root.apis.loginUsers.login(payload, {
            "Content-Type": "application/x-www-form-urlencoded"
        })
        .then((data) => {
            const respObj = data.data
            const accessToken = respObj.accessToken
            const loginId = respObj.loginId

            setCookie(constant.Cookies.LoginId, loginId)
            setCookie(constant.Cookies.AccessToken, accessToken)
            
            dispatch(commonSlice.actions.setIsLogin(true))
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp.status === 401) {
                mToast.error(err_resp.data.err)
            }
            else {
                mToast.error("Couldn't complete request. Something went wrong!")
            }
        })
    } 

    // Login on "Enter" key press
    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.which === 13) {
                onLogin()
            }
        }

        document.addEventListener("keydown", onKeyDown)
        return () => {
            document.removeEventListener("keydown", onKeyDown)
        }

    }, [onLogin])

    return (
        <Box
            style={{
                minHeight: "100vh",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "calc(10px + 2vmin)",
            }}
        >
            <Card
                className="custom-inputs"
                sx={{
                    bgcolor: grey[800],
                    px: 5,
                    pt: 2,
                    pb: 0
                }}
            >
                <CardContent>
                    <Box sx={{ textAlign: "center", mb: 2 }}>
                        <img src={root.constant.ApplicationIcon.MAIN} height={70} width={300} />
                    </Box>
                    <Box mb={6}>
                        <Typography sx={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            textAlign: "center"
                        }}>
                            Welcome to the World of Algo Trading
                        </Typography>
                    </Box>

                    {
                        inputs.map(({ Component = TextField, ...input }, i) => {
                            return (
                                <Box mt={2} key={input.key}>
                                    <Component 
                                        label={input.label} 
                                        sx={{ width: '100%' }} 
                                        onChange={(e) => { onChangeInputs(e, i) }}
                                        type={input.key === "password" ? "password" : ""}
                                    />
                                </Box>
                            )
                        })
                    }
                    
                    <Box mt={4}>

                        <Button
                            style={{ textTransform: "initial", width: '100%' }}
                            variant="contained"
                            onClick={onLogin}
                        >
                            Login
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            mt: 8,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {/* <Typography sx={{ color: "#fff", fontSize: 10 }}>
                            ABC, Copyright © 2023
                        </Typography> */}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}