import { SectionHeading } from "helpers/SectionHeading";
import { useUrlParams } from "utils/useUrlParams";
import { formatePrice } from "utils/formatPrice";
import Edit from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { mToast } from "Root/Common/Toast";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Table } from "helpers/Table";
import Box from "@mui/material/Box"
import { root } from "Root/root";

const renderStatusCircle = (value) => {
    return <Box
        display="flex"
        justifyContent="center"
    > 
        <Box
            sx={{
                width: 10,
                height: 10,
                aspectRatio: "1/1",
                borderRadius: "100%",
                bgcolor: value ? "green" : "red"
            }}
        />
    </Box>
}

const columns = [
    { id: 'loginId', label: 'Login ID', minWidth: 170, align: 'left' },
    { id: 'fullName', label: 'Full Name', minWidth: 170, align: 'left' },
    { id: 'userName', label: 'User Name', minWidth: 170, align: 'left' },
    { id: 'isLoginEnable', label: 'Login Permission', minWidth: 170, align: 'center', format: (value) => renderStatusCircle(value) },
    { id: 'isAdmin', label: 'Is Admin User?', minWidth: 170, align: 'center', format: (value) => renderStatusCircle(value) },
    { id: 'action', label: 'Action', minWidth: 100, align: 'right' },
];

export function LoginHome() {
    const [, updateParams] = useUrlParams();
    const [lUsers, setLUsers] = useState({
        list: []
    })

    useEffect(() => {
        const createData = (user = {}) => {
            return {
                loginId: user.loginId,
                fullName: user.fullName,
                userName: user.userName,
                isLoginEnable: user.isLoginEnable,
                isAdmin: user.isAdmin,
                action: (
                    <Link to={"/loginAccounts?type=edit&loginid=" + user.loginId}>
                        <Button
                            startIcon={<Edit style={{ transform: 'scale(.8)' }} />}
                            style={{ textTransform: "initial" }}
                            variant="text"
                        >
                            Edit
                        </Button>
                    </Link>
                )
            };
        }

        root.apis.loginUsers.get()
            .then(d => {
                const list = (d.data.lUsers || []).map(createData)

                setLUsers(prev => ({
                    ...prev,
                    list,
                }))
            })
            .catch(err => {
                const e_resp = err.response

                if (e_resp.status === 401) {
                    mToast.error(e_resp.data.detail)
                }
                else {
                    mToast.error("getLoginUser error = ")
                }
            })
    }, [])

    return (
        <Box>
            <SectionHeading
                heading={"Login Accounts Information"}
            >
                <Box>
                    <Button
                        variant="contained"
                        style={{ textTransform: 'initial' }}
                        onClick={() => updateParams({ "type": "create" })}
                    >
                        Create Login User
                    </Button>
                </Box>
            </SectionHeading>
            <Box mt={2}>
                <Table
                    columns={columns}
                    rows={lUsers.list}
                />
            </Box>
        </Box>
    )
}