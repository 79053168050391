import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import MuiTable from '@mui/material/Table';
import Paper from '@mui/material/Paper';


export function Table({ columns=[], rows=[], rowAttr=()=>undefined }) {    
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', bgcolor: 'transparent' }}>
            <TableContainer className='scrollbar'>
                <MuiTable stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    sx={{ minWidth: column.minWidth, p: 0, fontWeight: 'bold' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .map((row,rowIndex) => {
                                return (
                                    <TableRow 
                                        hover 
                                        tabIndex={-1} 
                                        key={rowIndex} 
                                        {...rowAttr(row, rowIndex)||{} }
                                    >
                                        {columns.map((column, columnIndex) => {
                                            if(!column.cellAttr)column.cellAttr={}
                                            column.cellAttr.sx={ p: 0, ...column.cellAttr.sx }

                                            return (
                                                <TableCell 
                                                    key={column.id} 
                                                    align={column.align}
                                                    {...column.cellAttr}
                                                >
                                                    { column.format
                                                        ? column.format(row[column.id], row, column, rowIndex, columnIndex)
                                                        : row[column.id]                                                        
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </Paper>
    );
}
