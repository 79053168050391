import { ThemeProvider } from "@mui/material/styles";
import { Routes } from "./components/routes";
import { themeDark } from './utils/MuiTheme';
import "./styles/globar.scss"
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import { store } from "./redux/store";
import ApplicationBody from "Root/ApplicationBody"

import { AreaChartApex } from "helpers/AreaChartApex";
import { useMkKeyboardEvent } from "helpers/useMkKeyboardEvent";

function App() {
  useMkKeyboardEvent();

  // return <AreaChartApex />
  return (
    <Provider store={store}>
      <Routes>
        <ThemeProvider theme={themeDark}>
          <ApplicationBody />
          <ToastContainer />
        </ThemeProvider>
      </Routes>
    </Provider>
  );
}

export default App;
