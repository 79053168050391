import { configureStore } from '@reduxjs/toolkit'
import { commonSlice, marketWatch } from "./Reducer";



export const store = configureStore({
  reducer: {
    common: commonSlice.reducer,
    marketWatch: marketWatch.reducer
  },
})