import { marketWatch } from "redux/Reducer";
import { mToast } from "Root/Common/Toast";
import { root } from 'Root/root';

export function refreshTemplates (dispatch = () => undefined, commonSel = {}) {
    // TODO: Find alternative way to do this

    setTimeout(() => {
        root.apis.marketWatch.getTemplates(commonSel.loginId)
        .then(data => {
            dispatch(marketWatch.actions.setTemplates(data.data))
        })
        .catch(e => {
            // console.log("getTemplates API error:", e)
            mToast.error(e.message)
        })
    }, 1000)
}

export function fetchTradeBook (dispatch = () => undefined, commonSel = {}, tradeFilt = {}) {
    const params = {
        loginId: commonSel.loginId
    }

    for (let key in tradeFilt) {
        if (tradeFilt[key]) {
            params[key] = tradeFilt[key]
        }
    }

    root.apis.marketWatch.getTradeBook(params)
    .then(data => {
        const tradeBook = data.data

        if (!Object.keys(tradeFilt).length) {
            tradeBook.users = tradeBook.users.map((val, i) => ({label: val, value: val, id: i}))    
            tradeBook.tags = tradeBook.tags.map((val, i) => ({label: val, value: val, id: i}))
        }
        else {
            delete tradeBook.users
            delete tradeBook.tags
        }

        dispatch(marketWatch.actions.setTradeBook(tradeBook))
    })
    .catch(e => {
        // console.log("getTradeBook API error:", e)
        mToast.error(e.message)
    })
}