import { decode } from "msgpack-lite";

export class Socket {
    constructor(url) {
        this.socket = new WebSocket(url);
        this.reconnectTimeout = 5000;
    }

    onOpen(cb) {
        this.socket.onopen = () => {
            cb()
        }
    }

    onMessage(cb) {
        this.socket.onmessage = (event) => {
            const blobData = new Blob([event.data])
            const reader = new FileReader();

            reader.onload = function() {
                let data = decode(new Uint8Array(reader.result));
                
                cb(data)
            };
            
            reader.readAsArrayBuffer(blobData);
        }
    }

    onClose(cb) {
        this.socket.onclose = (event) => {
            cb(event)
        }
    }

    close() {
        this.socket.close();
    }

    send(data) {
        this.socket.send(data);
    }
}
