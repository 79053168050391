import { styled } from '@mui/material/styles';
import Switch from "@mui/material/Switch";

const EnableDisableSwitchElem = styled(Switch)(() => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22,
        backgroundColor: "#8a9096",
        
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
    },
    '& .MuiSwitch-thumb': {
        width: 16,
        height: 16,
        margin: 2,
        backgroundColor: "#E0E0E0"
    },
}));

export default EnableDisableSwitchElem;