const MarketWatch = (self) => ({

    get(){
        return self.axios.get(self.getRestUrl("marketwatch"))
    },
    
    /////////////////////////////////////////// Marketwatch /////////////////////////////////////////// 

    getExchange(searchQuery) {
        return self.axios.get(self.getRestUrl(["marketwatch", "exchange"]), { params: {"search_query": searchQuery}})
    },

    getSymbols(excSeg, searchQuery) {
        return self.axios.get(self.getRestUrl(["marketwatch", "symbols"]), { params: {"exc_seg": excSeg, "search_query": searchQuery}})
    },

    /** @param {{ loginId: number, excSeg: String, excSegId: number, excInstId: number }} payload */
    addSymbol(payload) {
        return self.axios.post(self.getRestUrl(["marketwatch", "client", "add"]), payload)
    },

    deleteSymbol(loginId, activeId) {
        return self.axios.delete(self.getRestUrl(["marketwatch", "client", "delete", loginId, activeId]))
    },

    /////////////////////////////////////////// Templates /////////////////////////////////////////// 

    getTemplates(loginId) {
        return self.axios.get(self.getRestUrl(["marketwatch", "template", loginId]))
    },

    /** @param {{ loginId: number, name: String, symbols: { excSegId: number, excInstId: number, qty: number, side: string }[] }} payload */
    addTemplate(payload) {
        return self.axios.post(self.getRestUrl(["marketwatch", "template"]), payload)
    },

    /** @param {{ tmptId: number }} payload */
    loadTemplate(payload) {
        return self.axios.post(self.getRestUrl(["marketwatch", "template", "load"]), payload)
    },

    deleteTemplate(tmptId) {
        return self.axios.delete(self.getRestUrl(["marketwatch", "template", tmptId]))
    },

    /////////////////////////////////////////// Order /////////////////////////////////////////// 

    /** @param {{ userId: number, tag: string, symbols: { excSeg: string, name: string, excSegId: number, excInstId: number, qty: number, side: string }[] }} payload */  
    executeOrder(payload) {
        return self.axios.post(self.getRestUrl(["marketwatch", "order"]), payload)
    },

    /////////////////////////////////////////// Tradebook /////////////////////////////////////////// 

    getTradeBook(params) {
        return self.axios.get(self.getRestUrl(["marketwatch", "tradebook"]), { params })
    },

    /////////////////////////////////////////// Postions /////////////////////////////////////////// 

    /** @param {{ userId: number, symbols: { excSeg: string, name: string, excSegId: number, excInstId: number, sqQty: number }[] }} payload */  
    squareOff(payload) {
        return self.axios.post(self.getRestUrl(["marketwatch", "squareoff"]), payload)
    },

    /** @param {{ loginId: number }} */
    resetUsers(loginId) {
        return self.axios.post(self.getRestUrl(["marketwatch", "reset_users", loginId]))
    }

})

export default MarketWatch