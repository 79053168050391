const Logs = (self) => ({

    get(filterItems={}){
        const params = {}
        for(let key in filterItems){
            if(filterItems[key]){
                params[key] = filterItems[key]
            }
        }
        return self.axios.get(self.getRestUrl("logs"), { params })
    }


})


export default Logs