import StrategyInstance from "./StrategyInstance/StrategyInstance";
import OrderConfig from "./OrderConfig/OrderConfig";
import MarketWatch from "./MarketWatch/MarketWatch";
import UserActions from "./UserActions/UserActions";
import LoginUsers from "./LoginUsers/LoginUsers";
import Positions from "./Positions/Positions";
import Strategy from "./Strategy/Strategy";
import Archive from "./Archive/Archive";
import Users from "./Users/Users";
import Utils from "./Utils/Utils";
import Logs from "./Logs/Logs";

export function ApiManager(){

    this.axios.interceptors.request.use(
        (config) => {
            // Set the AUTH header                
            config.headers.Authorization = "Bearer " + this.userAuth.token
            config.headers.loginId = this.user.loginId

            return config;
        },
        error => Promise.reject(error)
    );

    // default header
    // this.axios.defaults.headers = {
    //     this will help to server to know that it's a xhr request
    //     "X-Requested-With": "XMLHttpRequest",
    // }

    this.apis = {
        users: Users(this),
        userActions: UserActions(this),
        loginUsers: LoginUsers(this),
        logs: Logs(this),
        strategy: Strategy(this),
        strategyInstance: StrategyInstance(this),
        positions: Positions(this),
        orderConfig: OrderConfig(this),
        marketWatch: MarketWatch(this),
        archive: Archive(this),
        utils: Utils(this),
    }

}