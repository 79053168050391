import { useCustomSearchbar } from "../common/useCustomSearchbar";
import { SectionHeading } from "helpers/SectionHeading";
import { useSelector, useDispatch } from "react-redux";
import { ExchangeDropdown } from "./ExchangeDropdown";
import { SymbolsDropdown } from "./SymbolsDropdown";
import CachedIcon from '@mui/icons-material/Cached';
import { Box, Button } from "@mui/material";
import { DropDown } from "helpers/DropDown";
import { useState, useEffect } from "react";
import { marketWatch } from "redux/Reducer";
import { Clear } from "@mui/icons-material";
import { mToast } from "Root/Common/Toast";
import { Template } from './Template';
import { MWTable } from './MWTable';
import { root } from "Root/root";
import calcGreek from "../../Utils";

export function MarketWatch({ socketFuncHandler }) {
    const marketWatchSel = useSelector(selector => selector.marketWatch)
    const commonSel = useSelector(selector => selector.common)
    const lUser = commonSel.user

    const dispatch = useDispatch()
    const CustomSearchbar = useCustomSearchbar()

    const [mktUsers, setMktUsers] = useState([])
    const [excSeg, setExcSeg] = useState([])
    const [excInst, setExcInst] = useState([])
    const [isUsersSel, setIsUsersSel] = useState(false)

    // const [segSearchVal, setSegSearchVal] = useState("")
    // const [instSearchVal, setInstSearchVal] = useState("")

    const resetExcSegSym = () => {
        excSeg.forEach(obj=>{
            obj.selected = false
        })

        excInst.forEach(obj=>{
            obj.selected = false
        })

        setExcSeg([...excSeg])
        setExcInst([...excInst])

        dispatch(marketWatch.actions.setSelectExchange(null))
        dispatch(marketWatch.actions.setSelectSymbol(null))

        // setSegSearchVal("")
        // setInstSearchVal("")
    }

    const addSymbol = () => {
        if (marketWatchSel.selectedSymbol === null || marketWatchSel.selectedExchange === null) {
            mToast.error("Please select exchange segment and symbol first!")

            // Reset exchange and symbol input fields
            resetExcSegSym()

            return
        }

        const payload = {
            "loginId": commonSel.loginId,
            "excSeg": marketWatchSel.selectedExchange,
            "excSegId": marketWatchSel.selectedSymbol.excSegId,
            "excInstId": marketWatchSel.selectedSymbol.excInstId
        }

        root.apis.marketWatch.addSymbol(payload)
        .then((data) => {
            const resp = data.data

            if (resp.msg) {
                mToast.success(resp.msg, {
                    autoClose: 50
                })
            }

            // Reset exchange and symbol input fields
            resetExcSegSym()
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp) {
                if (err_resp.status === 403) {
                    mToast.error(err_resp.data.err, {
                        autoClose: 50
                    })
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!", {
                        autoClose: 50
                    })
                }
            }
            else {
                mToast.error("Couldn't complete request. Something went wrong!", {
                    autoClose: 50
                })
            }
        })
    }

    const resetSelUsers = () => {
        // Clear selectedUsers
        dispatch(marketWatch.actions.setSelectUsers([]))

        // Unselect all the selected users
        mktUsers.forEach(obj=>{
            obj.selected = false
        })
        setMktUsers([...mktUsers])

        root.apis.marketWatch.resetUsers(commonSel.loginId)
        .then((data) => {
            const resp = data.data

            if (resp.msg) {
                mToast.success(resp.msg, {
                    autoClose: 50
                })
            }
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp) {
                if (err_resp.status === 403) {
                    mToast.error(err_resp.data.err, {
                        autoClose: 50
                    })
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!", {
                        autoClose: 50
                    })
                }
            }
            else {
                mToast.error("Couldn't complete request. Something went wrong!", {
                    autoClose: 50
                })
            }
        })
    }

    // Set data in marketwatch table
    socketFuncHandler.current.marketWatch = (data) => {
        var symbols = data.symbols[0]
        const vix = data.symbols[1]

        if (symbols) {
            const users = data.users

            symbols.forEach((elem) => {
                elem.selected = false
                elem.tradeAmount = 0
            })
    
            if (!isUsersSel && mktUsers.length !== 0) {
                mktUsers.forEach(user => {
                    if (users.includes(user.id)) {
                        user.selected = true
                    }
                })
    
                setIsUsersSel(true)
                dispatch(marketWatch.actions.setSelectUsers(users))
            }
    
            // Calculate greek values
            symbols = calcGreek(symbols, vix)
    
            dispatch(marketWatch.actions.setData({
                type: "modify",
                data: symbols
            }))
        }
    }

    // Get the notification message
    socketFuncHandler.current.notif = (msg) => {
        mToast.success(msg, {
            autoClose: 50
        })
    }

    useEffect(() => {
        if (lUser.is_admin) {
            root.apis.users.get()
            .then(data => {
                setMktUsers(data.data.users.map((user) => {
                    return { label: user.userInfo.fullName, value: user.userInfo.fullName, id: user.id }
                }))
            })
            .catch(e=>{
                console.log("getUser API error:", e)
                mToast.error(e.message)
            })
        }
        else {
            setMktUsers(lUser.users.map((user) => {
                return { label: user.f_name, value: user.f_name, id: user.user_id }
            }))
        }
    }, [dispatch])

    // console.log("marketWatchSel= ", marketWatchSel)
    return (
        <Box>
            <SectionHeading
                heading={"Market Watch"}
                boxStyle={{
                    justifyContent: "start",
                    alignItems: "center",
                    gap: 3
                }}
            >
                <Box display="flex" gap={3} alignItems="center" width="90%" mt={0.8}>
                    <Box minWidth={30} display="flex">
                        <DropDown
                            multiselect
                            defaultValue="Select User..."
                            searchPlaceholder="Search User..."
                            value={mktUsers.filter(obj => obj.selected)}
                            options={mktUsers}
                            onSelect={(mktUserElem, isSelected, isArray)=> {
                                const selUserIDs = []
                                mktUserElem.selected = isSelected

                                // Get selected user IDs
                                mktUsers.forEach(user => {
                                    if (user.selected) {
                                        selUserIDs.push(user.id)
                                    }
                                })

                                // Set the user IDs in selectedUsers in the reducer
                                dispatch(marketWatch.actions.setSelectUsers(selUserIDs))

                                if (selUserIDs.length !== 0) {
                                    setIsUsersSel(true)
                                }

                                setMktUsers([...mktUsers])
                            }}
                        >
                            <CustomSearchbar />
                        </DropDown>

                        <Button 
                            sx={{ minWidth: 20, p: 0, ml: 2 }} 
                            color="error" 
                            onClick={resetSelUsers}
                        > <Clear /> </Button>
                    </Box>

                    <Box marginLeft="auto">
                        <Box 
                            display="flex" gap={3} justifyContent="end" alignItems="center" justifySelf="end"
                        >
                            {/* Reload Button */}
                            <Button 
                                sx={{ minWidth: 20, p: 0 }} 
                                color="primary" 
                                onClick={() => {
                                    window.location.reload();
                                }} 
                            > <CachedIcon sx={{ fontSize: 30 }} /> </Button>

                            <ExchangeDropdown
                                excSeg={excSeg}
                                setExcSeg={setExcSeg}
                            />
                            <SymbolsDropdown
                                excInst={excInst}
                                setExcInst={setExcInst}
                            />
                            
                            <Box>
                                <Button
                                    variant={(marketWatchSel.selectedExchange && marketWatchSel.selectedSymbol) ? "contained" : "outlined"}
                                    style={{
                                        textTransform: "initial"
                                    }}
                                    onClick={addSymbol}
                                >
                                    Add Symbol
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SectionHeading>

            <MWTable
                rows={marketWatchSel.data}
                dispatch={dispatch}
                commonSel={commonSel}
                marketWatchSel={marketWatchSel}
                mktUsers={mktUsers}
            />

            <Template
                dispatch={dispatch}
            />
        </Box>
    )
}
