
export function calculateTotal(data=[], totalCalFields = ["tradeAmount", "ltp", "delta", "gamma", "theta", "iv", "vega"]){
    var totalRow = { _type: "total" }
    for (let field of totalCalFields) totalRow[field] = 0;
    
    data.forEach((row)=>{
        if (row.selected) {
            totalCalFields.forEach(field => {
                totalRow[field] += Number(row[field] || 0)
            })
        }
    })
    
    // fixed the total with 2 digit
    for(let field of totalCalFields){
        if("number" === typeof totalRow[field]){
            totalRow[field] = totalRow[field].toFixed(2)
        }
    }

    // if there are any other fields then that will not remove
    var isTotal = data.find(row => row._type === "total")
    if(isTotal){
        Object.assign(isTotal, totalRow)
    }else{
        data.push(totalRow)
    }
}