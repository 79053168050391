import { AccountHome } from "./AccountHome"
import Box from "@mui/material/Box"
import { CreateNewUser } from "./CreateNewUser"
import { useUrlParams } from "../../../utils/useUrlParams"

export function UserAndAccounts(){
    document.title = "User & Accounts";

    const [searchParams] = useUrlParams()

    return (
        <Box> 
            {
                (searchParams.get("type") === "create" || searchParams.get("type") === "edit") ? 
                    <CreateNewUser />
                : <AccountHome />
            }
        </Box>
    )
}