import { Table } from "helpers/Table";
import Box from "@mui/material/Box";



// logObj={
//     "id": 11,
//     "userID": 16,
//     "createdOn": "2023-06-26T14:23:18",
//     "stgCode": "S-8",
//     "logInfo": "Log created at 2023-06-26 19:53:18.483545"
// }


export function LogsList({ columns=[], logs=[] }){
    
    return(
        <Box>
            <Table
                columns={columns}
                rows={logs}
            />
        </Box>
    )
}