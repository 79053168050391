import { LoginHome } from "./LoginHome"
import Box from "@mui/material/Box"
import { CreateLoginUser } from "./CreateLoginUser"
import { useUrlParams } from "../../../utils/useUrlParams"

export function LoginAccounts() {
    document.title = "Login Accounts";

    const [searchParams] = useUrlParams()

    return (
        <Box>
            {
                (searchParams.get("type") === "create" || searchParams.get("type") === "edit") ? 
                    <CreateLoginUser />
                : <LoginHome />
            }
        </Box>
    )
}