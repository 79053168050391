import { InputSearchWithDropdown } from "helpers/InputSearchWithDropdown";
import { useState, forwardRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { DropDown } from "helpers/DropDown";
import { mToast } from "Root/Common/Toast";
import DatePicker from "react-datepicker";
import Button from "@mui/material/Button";
import { useSelector } from 'react-redux';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { root } from "Root/root";

// https://mui.com/material-ui/guides/pickers-migration/
export function DateComponent({ value, ...inputProps }) {
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <Box ref={ref} onClick={onClick} sx={{ width: "100%" }}>
            <TextField
                fullWidth
                label={inputProps.label}
                value={value}
                aria-autocomplete="none"
                autoComplete="off"
                autoCorrect="off"
                onChange={() => null}
                variant="filled"
            />
        </Box>
    ));

    return (
        <DatePicker
            showPopperArrow={false}
            selected={value}
            onChange={(date) => {
                inputProps.onChange({
                    target: {
                        value: date
                    }
                })
            }}
            customInput={<CustomInput />}
        />
    )
}

export const CustomComp = ({ withDefaultValue, defaultValue, value}) => {
    return(
        <TextField
            fullWidth
            value={withDefaultValue?defaultValue:value}
            onChange={()=>undefined}
        />
    )
}

export function DropDownComponent(props){
    return (
        <DropDown
            defaultValue={props.label}
            value={props.value}
            options={props.optionsList}
            onSelect={(obj)=>{
                props.onChange({
                    target: {
                        value: obj.value
                    }
                })
                // console.log("selected = ", obj)
            }}
        >
            <CustomComp />
        </DropDown>
    )
}

export function MultiSelectDropDownComponent(props){
    var cSelValue = props.optionsList ? props.optionsList.filter(obj => obj.selected) : []
    
    if (props.selStg.length !== 0) {
        cSelValue = props.selStg ? props.selStg.filter(obj => obj.selected) : []
    }

    return (
        <DropDown
            multiselect
            withSelectAllControl
            searchPlaceholder="Search Strategy..."
            defaultValue={props.label}
            value={cSelValue}
            options={props.optionsList}
            onSelect={(cmpObj, isSelected, isArray)=>{
                if (isArray) {
                    cmpObj.forEach(obj => {
                        obj.selected = isSelected
                    })
                } else {
                    cmpObj.selected = isSelected
                }

                props.setSelStg([...props.optionsList.filter(obj => obj.selected)])
            }}
        >
            <CustomComp
                value={cSelValue}
            />
        </DropDown>
    )
}

export function UserInputs({ onGenerateReport=()=>undefined, inputs=[], setInputs=()=>undefined, selStg=[] }) {
    // const [inputs, setInputs] = useState([
    //     // key: should be same as api flags
    //     { label: "Username", Component: DropDownComponent, optionsList: [], key: "userId" },
    //     { label: "Strategy", Component: DropDownComponent, optionsList: [], key: "strategyIds" },
    //     { label: "Date From", Component: DateComponent, key: "fromDate", type: 'date' },
    //     { label: "Date to", Component: DateComponent, key: "toDate", type: 'date' }
    // ])

    const commonSel = useSelector(selector => selector.common)
    const lUser = commonSel.user

    console.log("lUser", lUser)

    const [selUserId, setSelUserId] = useState()
    const [assignedIds, setAssignedIds] = useState([])

    const onChangeInputs = (e, index) => {
        const tVal = e.target.value
        inputs[index].value = tVal

        if (inputs[index].key === "userId") {
            var userObjs = inputs[index].optionsList.filter((user) => user.value === tVal)
            
            if (userObjs.length !== 0) {
                setSelUserId(userObjs[0]?.data.id)
            }
        }

        setInputs([...inputs])
    }

    useEffect(() => {
        if (selUserId) {
            root.apis.archive.getArchiveStg(selUserId)
            .then((data) => {
                setAssignedIds(data.data.strategies)
            })
            .catch(err => { mToast.error(err.message) })
        }
    }, [selUserId])

    useEffect(() => {
        if (lUser.is_admin) {
            root.apis.users.get()
            .then((d)=>{
                setInputs(prev=>{
                    const usr = prev.find(e=> e.key === "userId" )
                    if(usr){
                        usr.optionsList = d.data.users.map(d=>({ data: d, value: d.userInfo.fullName, label: d.userInfo.fullName }))
                    }
                    return [...prev]
                })
            })
            .catch(e=>{ mToast.error(e.message) })
        }
        else {
            setInputs(prev => {
                const usr = prev.find(e => e.key === "userId")

                if (usr) {
                    usr.optionsList = lUser.users.map(u => ({ data: { id: u.user_id }, value: u.f_name, label: u.f_name }))
                }
                return [...prev]
            })
        }

        inputs.forEach(input => {
            if (input.type === "date") {
                input.value = input.default
            }
        })
    },[])

    useEffect(() => {
        root.apis.strategy.get()
        .then((d)=>{
            setInputs(prev=>{
                const str = prev.find(e=> e.key === "strategyIds")
                var stgData = d.data.strategy

                // Filter strategies which are only assigned to the user
                if (!lUser.is_admin) {
                    stgData = stgData.filter((stg) => assignedIds.includes(stg.stgId))
                }

                if (str) {
                    str.optionsList = stgData.map(d=>({ data: d, value: d.name, label: d.name }))
                    str.selStg = selStg
                }

                return [...prev]
            })
        })
        .catch(e=>{ mToast.error(e.message) })
    }, [selUserId, assignedIds])

    return (
        <Box
            className="custom-inputs"
            noValidate
        >
            <Grid container spacing={{ xs: 2, md: 4 }}>
                {
                    inputs.map(({ Component = TextField, ...input }, i) => {
                        return (
                            <Grid
                                key={input.key}
                                item
                                xs={12}
                                sm={6}
                                md={6}
                            >
                                <Component
                                    fullWidth
                                    onChange={(e) => { onChangeInputs(e, i) }}
                                    value={input.value ?? ""}
                                    variant="standard"
                                    {...input}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>

            <Box style={{ marginTop: 15, display: "flex", justifyContent: "flex-end" }}>
                <Button 
                    variant="contained"
                    color="success"
                    size="large"
                    style={{ textTransform: "initial" }}
                    onClick={onGenerateReport}
                >
                    Generate Report
                </Button>
            </Box>
        </Box>
    )
}