const Positions = (self) => ({

    get(userId=undefined){ // if user id not provided, then it will fetch all
        return self.axios.get(self.getRestUrl(["positions", userId].filter(e=>e)))
    },

    tradeBook(tradeType, userId=undefined, stgId=undefined){
        return self.axios.get(self.getRestUrl(["trade_book", tradeType, userId, stgId ? stgId : ""].filter(e=>e)))
    },

    intradayUser(userId=undefined, stgId=undefined){
        return self.axios.get(self.getRestUrl(["anly_intraday", "user", userId, stgId ? stgId : ""].filter(e=>e)))
    },

    netSummary(userId=undefined){
        return self.axios.get(self.getRestUrl(["positions", userId, "net_summary"]))
    },

})

export default Positions