import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import { Table } from "helpers/Table";
import Box from "@mui/material/Box";

const columns = [
    { id: "name", label: "Strategy", align: "center" },
    { id: "c_date", label: "Date", align: "center" },
    { id: "day", label: "Day", align: "center" },
    { id: "min_mtm", label: "Min. MTM", align: "center" },
    { id: "max_mtm", label: "Max. MTM", align: "center" },
    { id: "avg_mtm", label: "Avg. MTM", align: "center" },
    { id: "mtm", label: "MTM", align: "center" },
    { id: "total", label: "Total", align: "center" },
    {
        id: "more",
        label: "",
        align: "right",
        format() {
            return (
                <ArrowForwardIosIcon style={{ transform: "scale(.7)" }} />
            )
        }
    },
]

// const statusColors = {
//     completed: green[600],
//     cancelled: red[600]
// }


/** @param { [] | { report: Array<{ date: number, day: string, month: number, year: number, total: number, mtm: number }> } } [param0.reportData=[]]  */
export function ReportList({ reportData=[], onReportRowClick=() => undefined }) {
    // const [rowData, setRowData] = useState([])

    // const createRows = (year, month, date, day, mtm, total, status) => {
    //     // staus = completed | cancelled
    //     return {
    //         year, month, date, day, mtm, total, status
    //     }
    // }
    // dummy data
    // useEffect(() => {
    //     const rowData = [
    //         createRows(2023, "May", 23, "Wednesday", -238.23, -238987, "Completed"),
    //         createRows(2023, "May", 23, "Wednesday", -238.23, -238987, "Completed"),
    //         createRows(2023, "May", 23, "Wednesday", -238.23, -238987, "Cancelled"),
    //         createRows(2023, "May", 23, "Wednesday", -238.23, -238987, "Completed"),
    //     ]
    //     setRowData(rowData)
    // }, [])
    return (
        <Box>
            <Table
                columns={columns}
                rows={reportData}
                rowAttr={(row) => {
                    return {
                        sx: {
                            // bgcolor: statusColors[
                            //     (row.Status || "").toLowerCase() || 
                            //     Object.keys(statusColors)[Math.random() > 0.5 ? 1 : 0]
                            // ]
                            bgcolor: row.mtm > 0 ? green[600] : red[600],
                            cursor: "pointer"
                        },
                        hover: false,
                        onClick () {
                            onReportRowClick(row)
                        }
                    }
                }}
            />
        </Box>
    )
}

