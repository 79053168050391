import ExcelJS from "exceljs"

const data = {
    columns: ["Year", "Month", "Date", "Day", "MTM", "Total"],
    rows: [
        [2023, "May", 23, "Wednesday", -234.789, -27987234],
        [2024, "June", 24, "Wednesday", -234.789, -27987234],
        [2025, "July", 25, "Wednesday", -234.789, -27987234],
        [2026, "Aug", 26, "Wednesday", -234.789, -27987234],
        [2027, "Sep", 27, "Wednesday", -234.789, -27987234],
    ],
    rowColors: [
        "43a047", // green
        "e53935", // red,
        "43a047", // green
        "e53935", // red,
        "43a047", // green
    ]
}

export async function ExportXLSX(fileName="", data={}) {
    // const fileName = "Reports"

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
   

    worksheet.columns = data.columns.map(col=>({ header: col, key: col }))
    

    data.rows.forEach((row, i) => {
        worksheet.addRow(row)
        // const r = worksheet.addRow(row)
        // const mc = data.rowColors[i]
        // r.eachCell((cell)=>{
        //     cell.style.fill = 
        //     {
        //         type: "pattern",
        //         pattern: "none", // gray0625, gray0625
        //         bgColor: { 
        //             argb: mc.toUpperCase(),
        //             theme: 30
        //         },
        //     };
        // })
        // console.log(r)
    })

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const blob = new Blob([buffer], { type: fileType });
    fileName = `${fileName}_${Date.now()}`
        .replace(/\W+(?!$)/g, '_')
        .replace(/\W$/, '')
        .replace(/_$/, '')
        .toLowerCase();

    const a = document.createElement('a');
    a.style.display = "none"
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName + ".xlsx";
    a.click();
    a.remove()

}

export async function ExportMultiSheetsXLSX(fileName="", data=[]) {
    const workbook = new ExcelJS.Workbook();

    data.forEach(d => {
        const worksheet = workbook.addWorksheet(d.sheetName);
        worksheet.columns = d.columns.map(col=>({ header: col, key: col }))
    
        d.rows.forEach((row, i) => {
            row = row.map(r => typeof r === "boolean" ? JSON.stringify(r) : r)
            worksheet.addRow(row)
        })
    })

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const blob = new Blob([buffer], { type: fileType });
    fileName = `${fileName}_${Date.now()}`
        .replace(/\W+(?!$)/g, '_')
        .replace(/\W$/, '')
        .replace(/_$/, '')
        .toLowerCase();

    const a = document.createElement('a');
    a.style.display = "none"
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName + ".xlsx";
    a.click();
    a.remove()
}