const LoginUsers = (self) => ({

    get(loginId) {
        // if user id not provided, then it will fetch all users data
        return self.axios.get(self.getRestUrl(["login_user" + ( loginId ? "" : "s" ), loginId].filter(e=>e)))
    },
    register(payload={}) {
        return self.axios.post(self.getRestUrl(["login_user", "register"]), payload)
    },
    edit(loginId, payload={}) {
        return self.axios.post(self.getRestUrl(["login_user", "edit", loginId]), payload)
    },
    delete(loginId) {
        return self.axios.delete(self.getRestUrl(["login_user", "delete", loginId]))
    },
    login(payload={}, headers={}) {
        return self.axios.post(self.getRestUrl(["login_user", "login"]), payload, { headers: headers })
    },
    logout(loginId) {
        return self.axios.post(self.getRestUrl(["login_user", "logout", loginId]))
    },
    validateAccess(payload={}) {
        return self.axios.post(self.getRestUrl(["login_user", "validate_access"]), payload)
    },
    // getNonAdminRoutes() {
    //     return self.axios.get(self.getRestUrl(["login_user", "non_admin_routes"]))
    // }

})

export default LoginUsers