import { Grid, List, ListItem, TextField, Typography } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import { useState, forwardRef, useRef, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { OrderStatusList } from "utils/OrderStatusList";
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import { DropDown } from 'helpers/DropDown';
import { mToast } from 'Root/Common/Toast';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import Slide from '@mui/material/Slide';
import Radio from '@mui/material/Radio';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import { root } from 'Root/root';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DropdownInput = ({ withDefaultValue, defaultValue, value }) => (
    <TextField
        fullWidth
        value={withDefaultValue ? defaultValue : value}
        onChange={() => undefined}
    />
)

// const InformationText = () => {
//     const Section = ({ title, body }) => {
//         return (
//             <Box>
//                 <Box className="mk-title">{title}</Box>
//                 <Box mb={2} className="mk-body">{body}</Box>
//             </Box>
//         )
//     }

//     return (
//         <Box sx={{
//             "& *": {
//                 fontSize: 18
//             },
//             "& .mk-title": {
//                 fontSize: "80%",
//                 fontWeight: "bold"
//             },
//             "& .mk-body, & li": {
//                 fontSize: "75%",
//             },
//         }}>
//             <Section
//                 title="Number Of Slices (Lots)"
//                 body="Enter Number of lots on NF/BNF, Application witll multiply with quantity."
//             />

//             <Section
//                 title="Max MTM and Min MTM"
//                 body="If you want to limit your daily MTM. Enter the maximum amount of profit you want to take and exit from strategy through the rest of the day. And it will start with zero MTM on the next day
//                 Ex: Max MTM: 2000 and Min MTM: -1000"
//             />


//             <Section
//                 title="Max MTM and Min MTM"
//                 body={
//                     <Box>
//                         <Box className="mk-body">
//                             If your number of lots is maximum from the exchange limit in single then we have 3 types of lots slicing when entering or exiting from the position.
//                         </Box>
//                         <List>
//                             <ListItem>Instant: It will execute order instantly </ListItem>
//                             <ListItem>Interval: It will wait for a number of seconds to send the next order request. (Input in seconds)</ListItem>
//                             <ListItem>Range Bound: It will send an order request when LTP is in between ranges for the next 10 seconds after that it cancels the remaining lots pending to excute.</ListItem>
//                         </List>
//                     </Box>
//                 }
//             />

//         </Box>
//     )
// }

function stopReStartStrategy(loginId, constantRef, data={}, isStop=true, isFullyStop=false) {
    return new Promise((resolve)=>{
        if(constantRef.current.isStopReStartRequestInProgress){
            resolve(false)
            return
        }
        constantRef.current.isStopReStartRequestInProgress = true
        var propTag = null

        if (isStop === true) {
            if (isFullyStop === true) {
                propTag = `oc-permanently-stop-${loginId}`
            }
            else {
                propTag = `oc-current-day-stop-${loginId}`
            }
        }
        else {
            propTag = `oc-squareoff-restart-${loginId}`
        }

        root.apis.orderConfig
        .stopReStartStrategy({ 
            stgId: data.strategyId, stgName: data.strategyData.stgName, userId: data.rowData.userid, onlySquareOff: false, isFullyStop: isFullyStop,
            propTag: propTag
        }, isStop)
        .then(d=>{
            mToast.success(d.data.msg)
            resolve(true)
        })
        .catch(e=>{
            mToast.error(e.message)
            resolve(false)
        }).finally(()=>{
            constantRef.current.isStopReStartRequestInProgress = false
        })
    })
}

const validateConfigValues = (state, resolve) => {
    if (state.limit_mtm.val === true) {
    //     if (state.trade_max_mtm.val <= 0) {
    //         mToast.error("Trade max MTM value should be greater than zero")
    //         resolve(false)
    //         return false;
    //     }

    //     if (state.trade_min_mtm.val >= 0) {
    //         mToast.error("Trade min MTM value should be less than zero")
    //         resolve(false)
    //         return false;
    //     }

    //     if (state.day_max_mtm.val <= 0) {
    //         mToast.error("Day max MTM value should be greater than zero")
    //         resolve(false)
    //         return false;
    //     }

    //     if (state.day_min_mtm.val >= 0) {
    //         mToast.error("Day min MTM value should be less than zero")
    //         resolve(false)
    //         return false;
    //     }

    //     if (parseFloat(state.day_min_mtm.val) > parseFloat(state.trade_min_mtm.val)) {
    //         mToast.error("Day min MTM value should be greater than trade min MTM value")
    //         resolve(false)
    //         return false;
    //     }

    //     if (parseFloat(state.day_max_mtm.val) < parseFloat(state.trade_max_mtm.val)) {
    //         mToast.error("Day max MTM value should be greater than trade max MTM value")
    //         resolve(false)
    //         return false;
    //     }

        if (state.trade_min_mtm.val > 0) {
            state.trade_min_mtm.val *= -1
        }

        if (state.day_min_mtm.val > 0) {
            state.day_min_mtm.val *= -1
        }
    }

    if (state.en_lots.val < 0) {
        mToast.error(`Entry lots should be greater than 0`)
        resolve(false)
        return false;
    }

    if (state.ex_lots.val < 0) {
        mToast.error(`Exit lots should be greater than 0`)
        resolve(false)
        return false;
    }

    if (parseInt(state.en_lots.val) > parseInt(state.max_lots.val)) {
        mToast.error(`Entry lots should be less than ${state.max_lots.val}`)
        resolve(false)
        return false;
    }

    if (parseInt(state.ex_lots.val) > parseInt(state.max_lots.val)) {
        mToast.error(`Exit lots should be less than ${state.max_lots.val}`)
        resolve(false)
        return false;
    }

    return true;
}

function saveConfig(constantRef, state, data, saveAndStart){
    return new Promise((resolve)=>{
        if(constantRef.current.isSaveConfigRequestInProgress){
            resolve(false)
            return
        }

        const isValid = validateConfigValues(state, resolve)

        if (!isValid) {
            return;
        }

        const payload = {
            stgId: data.strategyId,
            stgName: data.strategyData.stgName,
            userId: data.rowData.userid,
            config: {},
            startStg: saveAndStart
        }

        for(let key in state){
            if(state[key].sendToServer){
                payload.config[key] = state[key].type(state[key].val)

                if(!payload.config[key] && state[key].type !== Number && state[key].type !== Boolean){
                    mToast.info("Please enter all the fields")
                    resolve(false)
                    return;
                }
            }
        }

        constantRef.current.isSaveConfigRequestInProgress = true

        root.apis.orderConfig
        .modifyConfig(payload)
        .then((data)=>{
            if (data.data.msg) {
                mToast.success(data.data.msg)
            }

            if (data.data.err) {
                mToast.error(data.data.err)
            }

            resolve(true)
        }).catch((e)=>{
            mToast.error(e.message)
            resolve(false)
        }).finally(()=>{
            constantRef.current.isSaveConfigRequestInProgress = false
        })

    })
}


export function StrategyModal({ orderState=[], data={}, setOpen=()=>undefined }) {
    const isEditable = data.rowData.editable
    const constantRef = useRef({
        entryNewPositionOptions: [
            { value: "Instant"},
            { value: "Time Based" },
            // { value: "Percentage Range" },
            // { value: "Points Based" },
        ],
        exitNewPositionOptions: [
            { value: "Instant"},
            { value: "Time Based" },
            // { value: "Percentage Range" },
            // { value: "Points Based" },
        ],
        isSaveConfigRequestInProgress: false,
        isStopReStartRequestInProgress: false,
        isPlayRequestInProgress: false,
        isStartStopProcessRequestInProgress: false
    })

    const commonSel = useSelector(selector => selector.common)
    const loginId = commonSel.loginId

    const userId = data.rowData?.id
    if (userId) {
        const oRowData = orderState.rows.filter(row => row.id === userId)
        
        if (oRowData.length !== 0) {
            data.strategyData.status = oRowData[0]["status_" + data.strategyData.stgId]
        }
    }

    const stgStatus = data.strategyData.status
    const [state, setState] = useState({
        limit_mtm: {
            type: Boolean,
            val: false,
            sendToServer: true,
            default: false
        },
        lots: {
            type: Number,
            val: "",
            sendToServer: true,
            default: ""
        },
        max_lots: {
            type: Number,
            val: "",
            sendToServer: false,
            default: ""
        },
        trade_max_mtm: {
            type: Number,
            val: "",
            sendToServer: true,
            default: ""
        },
        trade_min_mtm: {
            type: Number,
            val: "",
            sendToServer: true,
            default: ""
        },
        day_max_mtm: {
            type: Number,
            val: "",
            sendToServer: true,
            default: ""
        },
        day_min_mtm: {
            type: Number,
            val: "",
            sendToServer: true,
            default: ""
        },
        en_slc_type: {
            type: String,
            val: "",
            sendToServer: true,
            default: ""
        },
        en_slc_value: {
            type: Number,
            val: "",
            sendToServer: true,
            default: ""
        },
        en_lots: {
            type: Number,
            val: "",
            sendToServer: true,
            default: 0
        },
        ex_slc_type: {
            type: String,
            val: "",
            sendToServer: true,
            default: ""
        },
        ex_slc_value: {
            type: Number,
            val: "",
            sendToServer: true,
            default: ""
        },
        ex_lots: {
            type: Number,
            val: "",
            sendToServer: true,
            default: 0
        },
    })

    // const [hasStartStopPerms, setHasStartStopPerms] = useState(false)
    // const commonSel = useSelector(selector => selector.common)

    // If user has start/stop permissions for the strategy process
    // useEffect(() => {
    //     const isAdmin = commonSel.user.is_admin

    //     if (!isAdmin) {
    //         setHasStartStopPerms(data.strategyData.startStopUser === commonSel.user.id)
    //     }
    //     else {
    //         setHasStartStopPerms(true)
    //     }
    // }, [data.strategyData])

    // Strategy modal menu anchor
    const [stgModalAnchorEl, setStgModalAnchorEl] = useState(null);
    const modalMenuOpen = Boolean(stgModalAnchorEl);

    const handleMenuClick = (event) => {
        setStgModalAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setStgModalAnchorEl(null);
    };

    const handleUpdateState = (flag, value) => {
        state[flag].val = value
        setState({...state})
    }

    const handleClose = () => {
        // remove the value from state
        for(let key in state){
            if(state[key].sendToServer){
                state[key].val = state[key].default
            }
        }

        setState({ ...state })
        setOpen(false);
    };

    const updateModalData = () => {
        const stgData = data.strategyData

        stgData.lots = Number(state.lots.val)

        stgData.limitMtm = Boolean(state.limit_mtm.val)
        stgData.tradeMaxMtm = Number(state.trade_max_mtm.val)
        stgData.tradeMinMtm = Number(state.trade_min_mtm.val)
        stgData.dayMaxMtm = Number(state.day_max_mtm.val)
        stgData.dayMinMtm = Number(state.day_min_mtm.val)

        stgData.enSlcType = String(state.en_slc_type.val)
        stgData.enSlcValue = Number(state.en_slc_value.val)
        stgData.enLots = Number(state.en_lots.val)

        stgData.exSlcType = String(state.ex_slc_type.val)
        stgData.exSlcValue = Number(state.ex_slc_value.val)
        stgData.exLots = Number(state.ex_lots.val)

        if (stgData.tradeMinMtm > 0) {
            stgData.tradeMinMtm *= -1
        }

        if (stgData.dayMinMtm > 0) {
            stgData.dayMinMtm *= -1
        }
    }

    const handleOnSave = (saveAndStart) => {
        if(data.strategyId){
            saveConfig(constantRef, state, data, saveAndStart)
           .then((success)=>{
               if (success) {
                    updateModalData()
                    // handleClose()
               } 
           })
        }
    }

    const handleStartStopStr = (isStop=true, isFullyStop=false) => {
        stopReStartStrategy(loginId, constantRef, data, isStop, isFullyStop)
        .then((success)=>{
            // logic  
        })

        setStgModalAnchorEl(null);
    }

    const handlePlayButton = (propTag) => {
        return new Promise((resolve)=>{
            if(constantRef.current.isPlayRequestInProgress){
                resolve(false)
                return
            }

            if (data.strategyId) {
                var cfgPayload = {}

                const isValid = validateConfigValues(state, resolve)

                if (!isValid) {
                    return;
                }

                for(let key in state){
                    if(state[key].sendToServer){
                        cfgPayload[key] = state[key].type(state[key].val)
            
                        if(!cfgPayload[key] && state[key].type !== Number && state[key].type !== Boolean){
                            mToast.info("Please enter all the fields")
                            resolve(false)
                            return;
                        }
                    }
                }

                constantRef.current.isPlayRequestInProgress = true

                root.apis.orderConfig
                .stgReEntryFix({ 
                    userId: data.rowData.id, stgId: data.strategyId, stgName: data.strategyData.stgName, propTag: propTag, checkStgStatus: false,
                    modifyConfig: cfgPayload
                })
                .then(d => {
                    if (d.data.msg) {
                        mToast.success("Started strategy and sent request to re-create positions.")
                    }
        
                    if (d.data.err) {
                        mToast.error(d.data.err)
                    }

                    updateModalData()
                    resolve(true)
                })
                .catch(err => {
                    const err_resp = err.response
        
                    if (err_resp.status === 403) {
                        mToast.error(err_resp.data.err)
                    }
                    else {
                        mToast.error(err.message)
                    }

                    resolve(false)
                })
                .finally(() => {
                    constantRef.current.isPlayRequestInProgress = false
                })
            }
        })
    }

    // const handleStartStopProcessButton = (isStart, propTag=null) => {
    //     return new Promise((resolve)=>{
    //         if(constantRef.current.isStartStopProcessRequestInProgress){
    //             resolve(false)
    //             return
    //         }

    //         if (data.strategyId) {
    //             var payload = {
    //                 userId: userId, 
    //                 stgId: data.strategyId
    //             }

    //             if (propTag) {
    //                 payload.propTag = propTag
    //             }

    //             constantRef.current.isStartStopProcessRequestInProgress = true

    //             root.apis.strategy
    //             .startStopProcess(payload, isStart)
    //             .then(d => {
    //                 if (d.data.msg) {
    //                     mToast.success(d.data.msg)
    //                 }
        
    //                 if (d.data.err) {
    //                     mToast.error(d.data.err)
    //                 }

    //                 resolve(true)
    //             })
    //             .catch(err => {
    //                 const err_resp = err.response
        
    //                 if (err_resp.status === 403) {
    //                     mToast.error(err_resp.data.err)
    //                 }
    //                 else {
    //                     mToast.error(err.message)
    //                 }

    //                 resolve(false)
    //             })
    //             .finally(() => {
    //                 constantRef.current.isStartStopProcessRequestInProgress = false
    //             })
    //         }
    //     })
    // }

    useEffect(()=>{
        if(Object.keys(data.strategyData).length){
            setState(prev=>{
                prev.lots.val = String(data.strategyData.lots)
                prev.max_lots.val = String(data.strategyData.maxLots)
                prev.limit_mtm.val = data.strategyData.limitMtm
                prev.trade_max_mtm.val = String(data.strategyData.tradeMaxMtm)
                prev.trade_min_mtm.val = String(data.strategyData.tradeMinMtm)
                prev.day_max_mtm.val = String(data.strategyData.dayMaxMtm)
                prev.day_min_mtm.val = String(data.strategyData.dayMinMtm)
                prev.en_slc_type.val = String(data.strategyData.enSlcType)
                prev.en_slc_value.val = String(data.strategyData.enSlcValue)
                prev.en_lots.val = String(data.strategyData.enLots)
                prev.ex_slc_type.val = String(data.strategyData.exSlcType)
                prev.ex_slc_value.val = String(data.strategyData.exSlcValue)
                prev.ex_lots.val = String(data.strategyData.exLots)

                return { ...prev } 
            })
        }
    },[data.strategyData, data.open])

    return (
        <Box>
            <Dialog
                open={data.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                scroll="paper"
                PaperProps={{
                    sx: {
                        minWidth: "36%"
                    }
                }}
                sx={{
                    "& *:not(button)": {
                        color: 'black'
                    }
                }}
            >
                <DialogTitle>
                    <Grid
                        container
                        columnSpacing={{ xs: 4, sm: 4, md: 19 }}
                        rowSpacing={2}
                    >
                        <Grid item>
                            <Typography>
                                UserName - { data?.rowData?.userid }
                            </Typography>
                            <Typography>
                                Strategy Name - { data?.strategyData?.stgName }
                            </Typography>
                        </Grid>

                        <Grid item order={{ xs: 3, sm: 2 }}>
                            <Box display="flex" alignItems="center">
                                <Box style={{ width: 20, height: 20, backgroundColor: ((OrderStatusList[data.strategyData?.status] || {}).color || {})[400] || "transparent", borderRadius: 50 }}></Box>
                                <Box sx={{ ml: 1.5 }}>
                                    <Typography fontSize={13}>Status:</Typography>
                                    <Typography fontSize={16} lineHeight={.8}>
                                        { (OrderStatusList[data.strategyData?.status] || {})?.name }
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item order={{ xs: 2, sm: 3 }}>
                            <Button
                                variant="contained"
                                color="primary" 
                                onClick={handleMenuClick}
                            > Menu </Button>

                            <Menu
                                id="basic-menu"
                                anchorEl={stgModalAnchorEl}
                                open={modalMenuOpen}
                                onClose={handleMenuClose}
                            >
                                <Box color="#000000">
                                    <MenuItem 
                                        onClick={() => {handlePlayButton(`oc-re-execute-${loginId}`)}} 
                                        disabled={stgStatus === "WK" ? false : true}
                                    >
                                        Re-execute / Re-check
                                    </MenuItem>
                                    
                                    <MenuItem 
                                        onClick={() => handleStartStopStr(true)} 
                                        // onMouseLeave={(e) => e.target.style.backgroundColor= "initial"}
                                        // onMouseEnter={(e) => e.target.style.backgroundColor= "#d32f2f"}
                                        disabled={stgStatus === "WK" ? false : true}
                                    >
                                        Stop For Current Day
                                    </MenuItem>
                                     
                                    <MenuItem 
                                        onClick={() => handleStartStopStr(true, true)}
                                        disabled={stgStatus === "WK" ? false : true}
                                    >
                                        Permanently Stop
                                    </MenuItem>

                                    <MenuItem 
                                        onClick={() => handleStartStopStr(false)} 
                                        disabled={stgStatus === "WK" ? false : true}
                                    >
                                        Squareoff & Restart
                                    </MenuItem>
                                </Box>
                            </Menu>
                        </Grid>

                        {/* <Grid item order={{ xs: 2, sm: 3 }}>
                            <Box display="flex" gap={3}>
                                <Button 
                                    onClick={()=>handleStartStopStr(true)} 
                                    variant="contained" 
                                    color="error" 
                                    style={{ textTransform: "initial" }}
                                    // disabled={!isEditable}
                                    disabled={stgStatus === "WK" ? false : true}
                                >Current Day Stop</Button>
                                <Button 
                                    onClick={()=>handleStartStopStr(true, true)} 
                                    variant="contained" 
                                    color="error" 
                                    style={{ textTransform: "initial" }}
                                    // disabled={!isEditable}
                                    disabled={stgStatus === "WK" ? false : true}
                                >Fully Stop</Button>
                                <Button 
                                    onClick={()=>handleStartStopStr(false)} 
                                    variant="contained" 
                                    color="primary" 
                                    style={{ textTransform: "initial" }}
                                    // disabled={!isEditable}
                                    disabled={stgStatus === "WK" ? false : true}
                                >Squareoff & Restart</Button>
                            </Box>
                        </Grid> */}
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>

                    <DialogContentText id="alert-dialog-slide-description">
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6}>

                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item sm={6} md={12}>
                                            <TextField
                                                fullWidth
                                                type='number'
                                                label="Number Of Sets (Lots)"
                                                InputProps={{ inputProps: { min: 0 } }}
                                                value={state.lots.val}
                                                onChange={(e)=>handleUpdateState("lots", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item sm={6} md={12}>
                                            <FormControl>
                                                <FormLabel id="limitMTM">Do you want to limit MTM?</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="limitMTM"
                                                    name="limitMTM"
                                                    value={state.limit_mtm.val}
                                                    onChange={({ target: { value } }) => {
                                                        handleUpdateState("limit_mtm", Boolean(value === "true"))
                                                    }}
                                                    color="primary"
                                                >
                                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {
                                        state.limit_mtm.val === true &&
                                        <Grid container spacing={2}>
                                            <Grid item sm={6} md={12}>
                                                <TextField
                                                    fullWidth
                                                    type='number'
                                                    label="Trade Max MTM (Profit Per Set/Lot)"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={state.trade_max_mtm.val}
                                                    onChange={(e)=>handleUpdateState("trade_max_mtm", e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item sm={6} md={12}>
                                                <TextField
                                                    fullWidth
                                                    type='number'
                                                    label="Trade Min MTM (Loss Per Set/Lot)"
                                                    InputProps={{ inputProps: { max: 0, min: -999999 } }}
                                                    value={state.trade_min_mtm.val}
                                                    onChange={(e)=>handleUpdateState("trade_min_mtm", e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item sm={6} md={12}>
                                                <TextField
                                                    fullWidth
                                                    type='number'
                                                    label="Day Max MTM / Profit"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={state.day_max_mtm.val}
                                                    onChange={(e)=>handleUpdateState("day_max_mtm", e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item sm={6} md={12}>
                                                <TextField
                                                    fullWidth
                                                    type='number'
                                                    label="Day Min MTM / Loss"
                                                    InputProps={{ inputProps: { max: 0, min: -999999 } }}
                                                    value={state.day_min_mtm.val}
                                                    onChange={(e)=>handleUpdateState("day_min_mtm", e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                            </Grid>

                            <Grid item sm={12} md={6}>
                                {/* <InformationText /> */}

                                <Box>
                                    <Box fontWeight="bold">Lots Slicing</Box>
                                    <Box style={{ textDecorationLine: "underline" }}>Taking New Entry Position inside Strategy</Box>
                                    <Box mt={2}>
                                        <Grid container spacing={2}>
                                            <Grid item sm={6} md={12}>
                                                <DropDown
                                                    defaultValue="Entry New Position"
                                                    withSeachBar={false}
                                                    options={constantRef.current.entryNewPositionOptions}
                                                    value={state.en_slc_type.val}
                                                    onSelect={(e)=>handleUpdateState("en_slc_type", e.value)}
                                                >
                                                    <DropdownInput />
                                                </DropDown>
                                            </Grid>
                                            {
                                                (state.en_slc_type.val && state.en_slc_type.val !== "Instant") &&
                                                <Grid item sm={6} md={12}>
                                                    <TextField
                                                        fullWidth
                                                        type='number'
                                                        label='Delay In Seconds (In Each Set Of Orders)'
                                                        InputProps={{ inputProps: { min: 0 } }}
                                                        value={state.en_slc_value.val}
                                                        onChange={(e)=>handleUpdateState("en_slc_value", e.target.value)}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item sm={6} md={12}>
                                                <TextField
                                                    fullWidth
                                                    type='number'
                                                    label='Number of sets (In Each Set Of Orders)'
                                                    InputProps={{ inputProps: { min: 0, max: state.max_lots.val } }}
                                                    value={state.en_lots.val}
                                                    onChange={(e)=>handleUpdateState("en_lots", e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                                <Box mt={3}>
                                    <Box style={{ textDecorationLine: "underline" }}>Exit from Position when Stop Loss or Target hits</Box>
                                    <Box mt={2}>
                                        <Grid container spacing={2}>
                                            <Grid item sm={6} md={12}>
                                                <DropDown
                                                    defaultValue="Exit from Position"
                                                    withSeachBar={false}
                                                    options={constantRef.current.exitNewPositionOptions}
                                                    value={state.ex_slc_type.val}
                                                    onSelect={(e)=>handleUpdateState("ex_slc_type", e.value)}
                                                >
                                                    <DropdownInput />
                                                </DropDown>
                                            </Grid>
                                            {
                                            (state.ex_slc_type.val && state.ex_slc_type.val !== "Instant") &&
                                                <Grid item sm={6} md={12}>
                                                    <Grid item>
                                                        <TextField
                                                            fullWidth
                                                            type='number'
                                                            label='Delay In Seconds (In Each Set Of Orders)'
                                                            InputProps={{ inputProps: { min: 0 } }}
                                                            value={state.ex_slc_value.val}
                                                            onChange={(e)=>handleUpdateState("ex_slc_value", e.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid item sm={6} md={12}>
                                                <TextField
                                                    fullWidth
                                                    type='number'
                                                    label='Number of sets (In Each Set Of Orders)'
                                                    InputProps={{ inputProps: { min: 0, max: state.max_lots.val } }}
                                                    value={state.ex_lots.val}
                                                    onChange={(e)=>handleUpdateState("ex_lots", e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Box sx={{ textAlign: "center", width: "100%" }}>
                        <Box display="flex" justifyContent="center" gap={3} >
                            {/* <Button 
                                sx={{ px: 5 }} 
                                onClick={() => {handleOnSave(false)}} 
                                variant="contained" 
                                color="primary"
                            >Save</Button> */}
                            <Button 
                                // sx={{ px: 2 }} 
                                onClick={() => {handlePlayButton(`oc-re-entry-${loginId}`)}} 
                                variant="contained" 
                                color="primary"
                                disabled={!isEditable}
                            >Start & Trade Now</Button>
                            <Button 
                                sx={{ px: 5 }} 
                                onClick={() => {handleOnSave(true)}} 
                                variant="contained" 
                                color="primary"
                                disabled={!isEditable}
                            >{stgStatus === "WK" ? "Next trade with updated config" : "Start from next trade"}</Button>
                            <Button 
                                onClick={handleClose} 
                                variant="outlined" 
                                color="info" 
                                style={{ textTransform: "initial" }}
                            >Close</Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}