import { SectionHeading } from "helpers/SectionHeading";
import { useState, useEffect } from "react";
import { mToast } from "Root/Common/Toast";
import { Table } from "helpers/Table";
import Box from "@mui/material/Box";
import { root } from "Root/root";
import moment from "moment";

const columns = [
    { id: "datetime", label: "Datetime", format(value){ return <Box width={"100%"} marginRight={25}>{moment(value).format("YYYY-MM-DD HH:mm:ss.SSS")}</Box> } },
    { id: "lName", label: "Login User", align: "center", format(value, row) { return <Box width={"100%"} marginRight={15}>{row.loginId} {value ? `( ${value} )` : ""}</Box> } },
    { id: "uName", label: "User", align: "center", format(value, row) { return <Box width={"100%"} marginRight={15}>{row.userId} {value ? `( ${value} )` : ""}</Box> } },
    { id: "action", label: "Action", align: "center", format(value) { return <Box width={"100%"} marginRight={15}>{value}</Box> } },
]

export function ActionsHome() {
    const [actions, setActions] = useState([])

    useEffect(() => {
        root.apis.userActions.get()
        .then((data) => {
            setActions(data.data.actions)
        })
        .catch(err => { mToast.error(err.message) })
    }, [])

    return (
        <Box>
            <SectionHeading
                heading={"User Actions"}
            >
            </SectionHeading>

            <Box mt={2}>
                <Table
                    columns={columns}
                    rows={actions}
                />
            </Box>
        </Box>
    )

}