import Box from "@mui/material/Box";
import { SectionHeading } from "../../../helpers/SectionHeading";
import { MarketWatchList } from "./MarketWatchList";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useRef } from "react";
import { Section } from "./Sections";


export function MarketWatch() {
    const commonRef = useRef({
        onAdd(){}
    })

    return (
        <Section />
    )

    return (
        <Box>
            <SectionHeading
                heading="Market Watch"
            >
                <Box>
                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        onClick={()=>commonRef.current.onAdd()}
                    >
                        Add
                    </Button>
                </Box>
            </SectionHeading>

            <Box mt={2}>
                <MarketWatchList 
                    commonRef={commonRef}
                />
            </Box>
        </Box>
    )
}