import { useUrlParams } from 'utils/useUrlParams';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UsersList } from "./UsersList";
import { Strategy } from "./Strategy";
import Box from "@mui/material/Box";
import { useEffect } from 'react';
import { User } from "./User";

export function Positions() {
    document.title = "Positions";

    const [searchParams] = useUrlParams()
    const user = useSelector((state)=> state.common.user)
    const navigate = useNavigate()

    useEffect(() => {
        if (!searchParams.get("type")) {
            navigate("/positions")
        }

        // if (user.id) {
        //     if (!user.is_admin) {
        //         if (!searchParams.get("type")) {
        //             navigate(`/positions?type=view&userId=${user.id}&fullName=${user.full_name}&mtm=0&brokerUserId=${user.broker_user_id}`)
        //         }
        //     } else {
        //         if (!searchParams.get("type")) {
        //             navigate("/positions")
        //         }
        //     }
        // }
    }, [user, navigate]);

    return (
        <Box>
            {
                searchParams.get("type") === "view" ?
                    <User />
                    :
                    searchParams.get("type") === "strategy" ?
                        <Strategy />
                        :
                        <UsersList />
            }
        </Box>
    )
}