import { Search } from '@mui/icons-material';
import { useCallback } from "react";
import Box from "@mui/material/Box";


export function useCustomSearchbar(){
    return useCallback(({ withDefaultValue, defaultValue, value }) => {
        return (
            <Box
                sx={{
                    borderBottom: "1px solid",
                    display: "flex",
                    gap: 1,
                    pr: 1,
                    opacity: withDefaultValue ? .9 : 1
                }}
            >

                <Search sx={{ transform: "scale(.8)" }} />
                <Box>
                    {withDefaultValue ? defaultValue : value}
                </Box>
            </Box>
        )
    }, [])
}