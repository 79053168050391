import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SectionHeading } from "helpers/SectionHeading"
import { ChartComponent } from "Root/Common/charts";
import Typography from "@mui/material/Typography";
import yellow from "@mui/material/colors/yellow";
import { formatePrice } from "utils/formatPrice";
import green from "@mui/material/colors/green";
import grey from "@mui/material/colors/grey";
import Tooltip from "@mui/material/Tooltip";
import red from "@mui/material/colors/red";
import { useEffect, useState, useRef } from "react"
import Button from "@mui/material/Button"
import { Table } from "helpers/Table"
import { Box } from "@mui/material"
import moment from "moment/moment";
import { root } from "Root/root"

const tradeColumns = [
    { id: "date", label: "Date" },
    { id: "time", label: "Time", format(value){ return moment(value).format("hh:mm:ss a")} },
    {id: "exchange", label: "Exchange"},
    {id: "symbol", label: "Symbol"},
    {id: "side", label: "Side"},
    {id: "mType", label: "Type", align: "center"},
    {id: "stg", label: "Strategy", align: "center"},
    {id: "qty", label: "Quantity", align: "center"},
    {id: "price", label: "Price", align: "center"},
    {id: "oRefId", label: "Order Ref ID", align: "center"},
    { 
        id: "status", 
        label: "Status",
        align: "center",
        format(value, rowData={}) {
            value = value.toUpperCase()
            return(
                rowData.statusInfo ?
                    <Tooltip
                        title={rowData.statusInfo}
                    >
                        <Box style={{ display: "flex", justifyContent: "center" }}>
                            { value }
                            <ErrorOutlineIcon style={{ transform: "scale(.8) rotate(180deg)" }} />
                        </Box>
                    </Tooltip>
                : value
            )
        }
    },
]

/**
 * 
 * @param {{openPage: string, data: null|{}}} param0.pageData
 * @param {} [param0.setPageData=() => undefined]  
 * @returns 
 */
export function UserTradeDetails({payloadData={}, pageData={}, setPageData=() => undefined}) {
    const [tradeRowData, setTradeRowData] = useState([])

    const [chartData, setChartData] = useState({
        data: [],
        maxMTM: 0, // max profit & loass
        minMTM: 0 // min profit & loss
    })
    const chartRef = useRef({
        areaChart: undefined
    })

    useEffect(() => {
        root.apis.archive.getReport(pageData.data?.c_date, { "userId": payloadData.userId, "stgId": pageData.data?.strategy_id })
        .then((data) => {
            var maxMTM = 0, minMTM = undefined

            setTradeRowData(data.data.trades)

            setChartData(prev => ({
                ...prev,
                data: data.data.mtm.map((data) => {
                    data.mtm = Number(data.mtm)

                    if (isNaN(data.mtm)) {
                        data.mtm = 0
                        console.warn("intraday data NaN ", data)
                    }

                    if (data.mtm > maxMTM) maxMTM = data.mtm;
                    if (minMTM === undefined) minMTM = data.mtm;
                    else if (data.mtm < minMTM ) minMTM = data.mtm;
                    return {
                        time: new Date(data.date) / 1000,
                        value: data.mtm
                    }
                }),
                maxMTM,
                minMTM
            }))
        })
        .catch((error) => {
            console.log("getReport Error:", error)
        })
    }, [pageData.data?.c_date, payloadData.userId, pageData.data?.strategy_id])

    return (
        <Box>
            <Box>
				<SectionHeading
					heading={
						<Box
							sx={{
								display: "flex",
								gap: 1,
								"& .MuiTypography-root": { fontSize: 18 },
							}}
						>
                            <Typography>Indraday P&L</Typography>
                            <Typography>Max:</Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                                ₹{formatePrice(chartData.maxMTM)}
                            </Typography>
                            <Typography>|</Typography>
                            <Typography>Min: </Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                                ₹{formatePrice(chartData.minMTM)}
                            </Typography>
						</Box>
					}
				>
                    <Box>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setPageData({...pageData, openPage: "", data: null})}
                        >
                            Back
                        </Button>
                    </Box>
                </SectionHeading>

				<Box
					sx={{
						width: "100%",
						mt: 2,
					}}
				>
                    {
                        chartData.data.length ? <ChartComponent data={chartData.data} chartRef={chartRef} /> : null
                    }
				</Box>
			</Box>

            <Box mt={5}>
                <SectionHeading 
                    heading="Trade Book"
                />
                
                <Box mt={3}>
                    <Table 
                        columns={tradeColumns}
                        rows={tradeRowData}
                        rowAttr={(row)=>{
                            const status = row.status.toLowerCase()
                            const side = row.side.toLowerCase()
                            var value = ""

                            if (status === "completed") {
                                if (side === "buy") {
                                    value = green[600]
                                }
                                else {
                                    value = red[600]
                                }
                            } 

                            else if (status === "rejected") {
                                value = yellow[800]
                            }

                            else {
                                value = grey[600]
                            }

                            return {
                                sx: {
                                    bgcolor: value,
                                },
                                hover: false
                            }
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}