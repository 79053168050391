import { useCustomSearchbar } from "../common/useCustomSearchbar";
import { useState, useEffect, useRef } from "react";
import { DropDown } from "helpers/DropDown";
import { marketWatch } from "redux/Reducer";
import { mToast } from "Root/Common/Toast";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { root } from "Root/root";

export function ExchangeDropdown({ excSeg = [], setExcSeg = () => undefined }) {
    const ref = useRef({
        searchDelay: null,
        defaultExec: []
    })
    const dispatch = useDispatch()
    const CustomSearchbar = useCustomSearchbar()

    const [searchVal, setSearchVal] = useState("")

    const getExchange = ({ isDefault=false, value }) => {
        root.apis.marketWatch.getExchange(value)
        .then(data => {
            const _data = (data.data || []).map((elem) => {
                return { label: elem, value: elem, id: elem }
            })

            setExcSeg(_data)

            // Clear the current selected segment name and symbol values in the dispatcher
            dispatch(marketWatch.actions.setSelectExchange(null))
            dispatch(marketWatch.actions.setSelectSymbol(null))

            if (isDefault) {
                ref.current.defaultExec = [..._data]
            }
        
        })
        .catch(e => {
            // console.log("getExchange API error:", e)
            mToast.error(e.message)
        })
    }

    useEffect(() => {
        getExchange({ isDefault: true })
    }, [])

    return (
        <Box>
            <DropDown
                defaultValue="Search Exchange..."
                searchPlaceholder="Search Exchange..."
                value={excSeg.filter(obj => obj.selected)}
                options={excSeg}
                searchValue={searchVal}
                onSearchInput={e=>{
                    setSearchVal(e.target.value)

                    if (ref.current.searchDelay) window.clearTimeout(ref.current.searchDelay);
                    if (e.target.value) {
                        ref.current.searchDelay = window.setTimeout(() => {
                            getExchange({value: e.target.value})
                        }, 900) // Waiting for 900ms
                    }
                    else {
                        setExcSeg([...ref.current.defaultExec])
                    }
                }}
                onSelect={(excSegElem, isSelected, isArray)=> {
                    // Set the exchange segment name in the reducer
                    dispatch(marketWatch.actions.setSelectExchange(excSegElem.value))

                    excSeg.forEach(obj => {
                        obj.selected = false
                    })

                    excSegElem.selected = isSelected
                    setExcSeg([...excSeg])
                }}
            >
                <CustomSearchbar />
            </DropDown>
        </Box>
    )
}