import { green, grey, orange, red } from "@mui/material/colors";

export const OrderStatusList = {
    WK: {
        name: "Working",
        color: green
    },
    ST: {
        name: "Stopped",
        color: grey
    },
    ER: {
        name: "Error",
        color: red
    },
    AL: {
        name: "Alert",
        color: orange
    }
}