import { SectionHeading } from "helpers/SectionHeading";
import { useUrlParams } from "utils/useUrlParams";
import { formatePrice } from "utils/formatPrice";
import Edit from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { mToast } from "Root/Common/Toast";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Table } from "helpers/Table";
import Box from "@mui/material/Box"
import { root } from "Root/root";

// userObj = {
//     "id": 2,
//     "userInfo": {
//         "fullName": "Michael Garcia",
//         "email": "8rkihvw0@example.com",
//         "phone": "08177901582",
//         "userName": "FaUKbGQL"
//     },
//     "kycInfo": {
//         "gstName": "Michael Garcia",
//         "gstNumber": "625998102081186",
//         "address": "Elm Street, Houston, CA 60601"
//     },
//     "bankInfo": {
//         "accName": "Michael Garcia",
//         "accNumber": "5091411165",
//         "bankName": "Central Savings",
//         "ifscCode": "QAZH0512846"
//     },
//     "brokerInfo": {
//         "apiType": "MOTILAL",
//         "apiKey": "2Vhqs2QKBAFIr4D89Z57",
//         "apiSecret": "2JWFT8iz2YQcaVO8UIJzG4eVq85U1j",
//         "brokeName": "Fyers",
//         "marginVal": 73177300,
//         "mfaType": "Text Number",
//         "totp": "381114",
//         "url": "https://trade.minstein.com/"
//     }
// }

const renderStatusCircle = (value) => {
    return <Box
        display="flex"
        justifyContent="center"
    > 
        <Box
            sx={{
                width: 10,
                height: 10,
                aspectRatio: "1/1",
                borderRadius: "100%",
                bgcolor: value ? "green" : "red"
            }}
        />
    </Box>
}

const columns = [
    { id: 'userId', label: 'User ID', minWidth: 170, align: 'left' },
    { id: 'brokerUserId', label: 'Broker User ID', minWidth: 170, align: 'left' },
    { id: 'fullName', label: 'Full Name', minWidth: 170, align: 'left' },
    { id: 'brokerName', label: 'Broker Name', minWidth: 170, align: 'center' },
    { id: 'marginVal', label: 'Margin Val.', minWidth: 170, align: 'center', format: (value) => formatePrice(value) },
    { id: 'limitMtm', label: 'Limit MTM (Min | Max)', minWidth: 170, align: 'center', format: (value, row) => {
        return <Box 
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
        >
            {renderStatusCircle(row.limitMtm)} (₹ {formatePrice(row.minMtm)} | ₹ {formatePrice(row.maxMtm)})
        </Box>
    } },
    { id: 'action', label: 'Action', minWidth: 100, align: 'right' },
];



export function AccountHome() {
    const [, updateParams] = useUrlParams();
    const [users, setUsers] = useState({
        list: []
    })


    useEffect(() => {
        const createData = (user = {}) => {
            return {
                userId: user.id,
                brokerUserId: user.brokerInfo.brokerUserId,
                fullName: user.userInfo.fullName,
                brokerName: user.brokerInfo.brokerName,
                marginVal: user.brokerInfo.marginVal,
                limitMtm: user.mtm.limitMtm,
                minMtm: user.mtm.minMtm,
                maxMtm: user.mtm.maxMtm,
                action: (
                    <Link to={"/userAndAccounts?type=edit&userid=" + user.id}>
                        <Button
                            startIcon={<Edit style={{ transform: 'scale(.8)' }} />}
                            style={{ textTransform: "initial" }}
                            variant="text"
                        >
                            Edit
                        </Button>
                    </Link>
                )
            };
        }
        root.apis.users.get()
            .then(d => {
                console.log("getUser response = ", d);
                const list = (d.data.users || []).map(createData)
                setUsers(prev => ({
                    ...prev,
                    list,
                }))
            })
            .catch(e => {
                const e_resp = e.response

                if (e_resp.status === 401) {
                    mToast.error(e_resp.data.detail)
                }
                else {
                    mToast.error("getUser error = ")
                }
            })
    }, [])

    return (
        <Box>
            <SectionHeading
                heading={"User & Accounts Information"}
            >
                <Box>
                    <Button
                        variant="contained"
                        style={{ textTransform: 'initial' }}
                        onClick={() => updateParams({ "type": "create" })}
                    >
                        Create New User
                    </Button>
                </Box>
            </SectionHeading>
            <Box mt={2}>
                <Table
                    columns={columns}
                    rows={users.list}
                />
            </Box>
        </Box>
    )
}