import DeleteForever from "@mui/icons-material/DeleteForever";
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Container, Divider } from "@mui/material";
import EnableDisableSwitchElem from "helpers/Switches";
import { useUrlParams } from "utils/useUrlParams";
import Typography from "@mui/material/Typography";
import Refresh from "@mui/icons-material/Refresh";
import { formatePrice } from "utils/formatPrice";
import FormGroup from '@mui/material/FormGroup';
import TextField from "@mui/material/TextField";
import { constant } from "Root/Common/constant";
import { Download } from "@mui/icons-material";
import Save from "@mui/icons-material/Save";
import { useEffect, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import { DropDown } from "helpers/DropDown";
import { mToast } from "Root/Common/Toast";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import moment from "moment/moment"
import { root } from "Root/root";

function useInputsSchema(searchParams, setSearchParams) {
    const [schema, setSchema] = useState([
        {
            heading: "Login User",
            key: "userInfo",
            bullets: [],
            inputs: [
                { label: "Full Name *", key: "fullName", optional: false },
                { label: "User Name *", key: "userName", optional: false },
                { label: "Password *", key: "password", optional: false },
            ]
        },
        {
            heading: "Select User Accounts",
            key: "userAccs",
            bullets: [],
            inputs: [
                { label: "Users", key: "users", optional: true}
            ]
        },
        {
            heading: "Permissions",
            key: "perms",
            bullets: [],
            inputs: [
                { label: "Enable User Login *", key: "isLoginEnable", type: "checkbox", value: false, optional: false }
            ]
        }
    ]);
    const [apiData, setApiData] = useState()

    const onChangeInputs = (e, schemaIndex, inputIndex) => {
        const schemaObj = schema[schemaIndex].inputs[inputIndex]

        if (schemaObj.type === "checkbox") {
            schemaObj.value = e.target.checked
        }
        else {
            schemaObj.value = e.target.value
        }

        setSchema([...schema])
    }

    const onSumbit = (usersList) => {
        const payload = {}
        const isEdit = searchParams.get("type") === "edit"
        
        for (let section of schema) {
            for (let input of section.inputs) {
                if ("key" in input) {
                    payload[input.key] = input.value
                }

                if (input.key == "users") {
                    if (typeof input.value === "string") {
                        payload["users"] = input.value.split(',').map(u => parseInt(u))
                    }                    
                    else {
                        payload["users"] = input.value
                    }

                    // payload["users"] = usersList.filter(u => u.selected).map(u => u.userId)
                }
            }
        }

        root.apis.loginUsers[isEdit ? "edit" : "register"](...[isEdit && apiData.loginId, payload].filter(e => e))
        .then((data) => {
            mToast.success(data.data.msg)
            setSearchParams()
        })
        .catch(err => {
            if (err.response) {
                err = err.response.data.err
            }
            else {
                err = err.statusText
            }

            mToast.error(err)
        })
    }

    useEffect(() => {
        if (searchParams.get("type") === "edit") {
            const loginId = searchParams.get("loginid")

            if (loginId) {
                root.apis.loginUsers.get(loginId)
                .then(d => {
                    // console.log("loginId, getLoginUser response = ", loginId, d);
                    const lUserData = d.data

                    const assignDataToSchema = (prevSchema) => {
                        prevSchema.forEach(section => {

                            section.inputs.forEach(input => {
                                const value = lUserData[input.key]

                                if (value) {
                                    input.value = value
                                }
                            })
                        })

                        return [...prevSchema]
                    }

                    if ("err" in d.data) {
                        mToast.error(d.data.err)

                    } else {
                        setSchema(assignDataToSchema)
                        setApiData(lUserData)
                    }
                })
                .catch(err => {
                    console.log('loginid, getLoginUser error = ', loginId, err)
                    mToast.error(err.statusText)
                })
            } else {
                mToast.info("loginId is required for edit.")
            }
        }
    }, [searchParams.get("type")])

    return [schema, onChangeInputs, onSumbit, apiData]
}

function SaveButton({ setSearchParams, onSumbit, usersList }) {
    return (
        <Box display={"flex"} justifyContent={"end"} my={2}>
            <Box>
                <Box display={'flex'} justifyContent={'end'} gap={5} mt={2}>
                    <Button
                        variant="outlined"
                        style={{ textTransform: 'initial', color: '#707070' }}
                        onClick={() => {
                            setSearchParams()
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<Save />}
                        onClick={() => {onSumbit(usersList)}}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Box>

    )
}

function DeleteAccountSection({ apiData, searchParams, setSearchParams }) {
    const [isDeleteCall, setIsDeleteCall] = useState(false)

    if (!apiData || searchParams.get("type") !== "edit" || !apiData.loginId) return null;

    const onDelete = () => {
        if (apiData && apiData.loginId) {
            if (!window.confirm("Are you sure do you want to delete your account?")) return;
            setIsDeleteCall(true)

            root.apis.loginUsers.delete(apiData.loginId)
                .then(d => {
                    mToast.success(d.data.msg)
                    setSearchParams()
                })
                .catch(e => {
                    mToast.error(e.statusText)
                })
                .finally(() => {
                    setIsDeleteCall(false)
                })
        }
    }

    return (
        <Box mt={2}>
            <Box mb={1}>
                <Typography>
                    Delete Account
                </Typography>
            </Box>
            <Button
                startIcon={<DeleteForever />}
                variant="outlined"
                color="error"
                style={{ textTransform: 'initial' }}
                onClick={onDelete}
            >
                {isDeleteCall ? "Delete your account..." : "Remove Account"}
            </Button>
        </Box>
    )
}

export function CreateLoginUser() {
    const [searchParams, , setSearchParams] = useUrlParams()
    const [usersList, setUsersList] = useState([])

    const [schema, onChangeInputs, onSumbit, apiData] = useInputsSchema(searchParams, setSearchParams)
    const [, setIsPermsChecked] = useState(true);

    const EnableDisableSwitch = ({ initialValue, onChangeInputs, schemaIndex, inputIndex, isChecked }) => {
        
        useEffect(() => {
            setIsPermsChecked(initialValue);
        }, [initialValue])  

        const handleChange = (e) => {
            setIsPermsChecked(e.target.checked);
            onChangeInputs(e, schemaIndex, inputIndex);
        };

        return (
            <EnableDisableSwitchElem
                checked={isChecked}
                onChange={handleChange}
            />
        );
    };

    const createSelUser = (d) => {
        var isSelected = false
        
        if (apiData) {
            isSelected = apiData.users.includes(d.id);
        }

        return { userId: d.id, value: d.userInfo.fullName, selected: isSelected, label: d.userInfo.fullName }
    }

    // Fetch user details
    useEffect(() => {
        root.apis.users.get()
        .then((d) => {
            setUsersList(d.data.users.map(d => createSelUser(d)))
        })
        .catch(err => { mToast.error(err.message) })
    }, [apiData])

    return (
        <Container disableGutters style={{ position: 'relative' }}  >
            <Box
                sx={{
                    width: '100%',
                }}
                className="custom-inputs"
                noValidate
                autoComplete="off"
            >
                {
                    schema.map((schema = {}, schemaIndex) => {

                        return (
                            <Box
                                key={schema.heading}
                                sx={{
                                    mt: schemaIndex ? 3 : 0
                                }}
                            >
                                <Box mb={1}>
                                    <Typography style={{
                                        fontSize: 18,
                                        textDecoration: 'underline',
                                        textUnderlineOffset: 5,
                                        color: "#5497db"
                                    }}>
                                        {schema.heading}
                                    </Typography>
                                </Box>

                                <Box mb={2}>
                                    {
                                        schema.bullets.map(bullet => {
                                            return (
                                                <Typography display="flex">
                                                    <Typography color="red">*</Typography> 
                                                    <Typography ml={1}>{bullet}</Typography>
                                                </Typography>
                                            )
                                        })
                                    }
                                </Box>
                                
                                <Box>
                                    <Grid container spacing={{ xs: 2, md: 4 }}>
                                        {
                                            schema.inputs.map((input, inputIndex) => {

                                                return (
                                                    <Grid
                                                        key={input.label}
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                    >

                                                        {
                                                            input.type === "dropdown" ? 
                                                                <DropDown
                                                                    multiselect
                                                                    withSelectAllControl
                                                                    defaultValue="Select Users"
                                                                    searchPlaceholder="Search Users..."
                                                                    value={usersList.filter(obj => obj.selected)}
                                                                    options={usersList}
                                                                    onSelect={(user, isSelected, isArray)=> {
                                                                        if (isArray) {
                                                                            user.forEach(obj => {
                                                                                obj.selected = isSelected
                                                                            })
                                                                        } else {
                                                                            user.selected = isSelected
                                                                        }
                                                                        setUsersList([...usersList])
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                    />
                                                                </DropDown>
                                                            :
                                                                input.type === "checkbox" ?
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <EnableDisableSwitch 
                                                                                    initialValue={input.value} onChangeInputs={onChangeInputs} 
                                                                                    schemaIndex={schemaIndex} inputIndex={inputIndex} 
                                                                                    isChecked={input.value}
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Box display="flex">
                                                                                    {input.label}
                                                                                    <Box ml={1} mt={0.5}>
                                                                                        {
                                                                                            input.toolTipText ?
                                                                                            <Tooltip title={input.toolTipText}> 
                                                                                                <HelpOutlineIcon fontSize="small" />
                                                                                            </Tooltip>    
                                                                                            : ""
                                                                                        }
                                                                                    </Box>
                                                                                </Box>
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                :
                                                                    <TextField
                                                                        fullWidth
                                                                        onChange={(e) => { onChangeInputs(e, schemaIndex, inputIndex) }}
                                                                        value={input.value ?? ""}
                                                                        variant="standard"
                                                                        {...input}
                                                                    />
                                                        }

                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box>
                            </Box>
                        )
                    })
                }

                <SaveButton
                    setSearchParams={setSearchParams}
                    onSumbit={onSumbit}
                    usersList={usersList}
                />

                <Divider color="gray" />

                <DeleteAccountSection
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    apiData={apiData}
                />
            </Box>
        </Container>
    )
}