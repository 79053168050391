import { getCookie, deleteCookie } from "helpers/Cookies";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { constant } from 'Root/Common/constant';
import ListItem from '@mui/material/ListItem';
import { commonSlice } from 'redux/Reducer';
import red from '@mui/material/colors/red';
import { mToast } from "Root/Common/Toast";
import { useDispatch } from 'react-redux';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { root } from 'Root/root';

export function Logout() {
    const dispatch = useDispatch()

    const onLogout = () => {
        const loginIdVal = getCookie(constant.Cookies.LoginId)

        root.apis.loginUsers.logout(loginIdVal)
        .then((data) => {
            deleteCookie(constant.Cookies.LoginId)
            deleteCookie(constant.Cookies.AccessToken)

            dispatch(commonSlice.actions.setIsLogin(false))
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp.status === 403) {
                mToast.error(err_resp.data.err)
            }
            else {
                mToast.error("Couldn't complete request. Something went wrong!")
            }
        })
    }

    return (
        <Box>
            <List>
                <ListItem>
                    <ListItemButton
                        sx={{
                            color: red[300],
                            "&:hover": {
                                color: red[600]
                            }
                        }}
                        onClick={onLogout}
                    >
                        <LogoutIcon />
                        <ListItemText 
                            primary={"Logout"} 
                            sx={{
                                marginLeft: 2
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
           
        </Box>
    )
}