import { toast } from "react-toastify";

class Toast {
    constructor() {
        this.config = {
            autoClose: 1000,
            position: "bottom-right"
        };
    }

    success(msg, config={}) {
        if (Object.keys(config).length !== 0) {
            this.config = {...this.config, ...config}
        }

        toast.success(msg, this.config)
    }

    error(msg, config={}) {
        if (Object.keys(config).length !== 0) {
            this.config = {...this.config, ...config}
        }

        toast.error(msg, this.config)
    }

    info(msg, config={}) {
        if (Object.keys(config).length !== 0) {
            this.config = {...this.config, ...config}
        }

        toast.info(msg, this.config)
    }

    warning(msg, config={}) {
        if (Object.keys(config).length !== 0) {
            this.config = {...this.config, ...config}
        }

        toast.warning(msg, this.config)
    }
}

export const mToast = new Toast()