import axios from "axios";
import { ApiManager } from "./ApiManager/ApiManager";
import { constant } from "./Common/constant";


function Root(axios) {
    // implement the constant in root
    this.constant = constant;
    this.axios = axios;
    this.userAuth = {
        token: ""
    }
    this.user = {
        loginId: null,
        isAdmin: false
    }

    // create the full url based on endpoint, endpoint: string | string[]
    this.getRestUrl = (endpoint = "") => this.constant.SERVER_URL + this.constant.REST_API_VERSION + "/" + (Array.isArray(endpoint) ? endpoint.join("/") : endpoint) + "/"


    // call the api manager for initializing the all api clients
    ApiManager.call(this)


    return this
}


export const root = new Root(axios)