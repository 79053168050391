import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import { grey } from "@mui/material/colors"

export function SectionHeading({ heading, children, withDivider = false, boxStyle = {}, hComponents = "", hCStyle = {} }) {

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', ...boxStyle }}>
                <Box
                    sx={{
                        borderBottom: withDivider ? 'unset' : '1px solid',
                        fontSize: 20,
                        display: "flex", 
                        alignItems: "center"
                    }}
                >
                    {heading}
                    <Box sx={{ ...hCStyle }}>
                         {hComponents}
                    </Box>
                </Box>
                {children}
            </Box>
            {
                withDivider ? <Divider color={grey[400]} /> : null
            }
        </Box>
    )
}