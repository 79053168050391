import { SectionHeading } from "helpers/SectionHeading";
import { useUrlParams } from "utils/useUrlParams";
import Typography from "@mui/material/Typography";
import AccountInfoModal from "./AccountInfoModal";
import { formatePrice } from "utils/formatPrice";
import green from "@mui/material/colors/green";
import MenuItem from '@mui/material/MenuItem';
import UpdateMTMModal from "./UpdateMTMModal";
import EDConfirmModal from "./EDConfirmModal";
import red from "@mui/material/colors/red";
import { mToast } from "Root/Common/Toast";
import Button from "@mui/material/Button";
import { useSelector } from 'react-redux';
import Paper from "@mui/material/Paper";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import Menu from '@mui/material/Menu';
import Box from "@mui/material/Box";
import { useState } from "react";
import { root } from "Root/root";

// strategyObj={
//     "id": 38,
//     "stgId": 7,
//     "stgCode": "S-6",
//     "pnl": 64371.35,
//     "isPnLUp": true,
//     "status": "WK"
//   }

function StrategyCard({ strategy = {}, updateParams=()=>undefined }) {
    return (
        <Paper 
            key={strategy.stgName}
            isup={`${strategy.mtm == 0 ? 0 : strategy.mtm > 0 ? true : false}`}
            onClick={()=>{
                updateParams({ type: "strategy", strategyId: strategy.strategy_id, strategyName: strategy.stgName })
            }}
        >
            <Tooltip title={strategy.stgName} placement="top">
                <Typography strategy-name="">{strategy.stgName}</Typography>
            </Tooltip>
          
            <Typography
                { ...strategy.stgName && {
                    style: {
                        transform: 'translate(0, 3px)'
                    }
                }}
            >{formatePrice(strategy.mtm)} ({strategy.netQty ? strategy.netQty : 0})</Typography>
        </Paper>
    )
}

export const handleAccElDl = (userId, isAccEnable) => {
    root.apis.orderConfig.enableDisableClientStrategies(parseInt(userId), !isAccEnable, false)
    .then((data) => {
        if (data.status === 201) {
            if (data.data.msg) {
                mToast.success(data.data.msg)
            }

            if (data.data.err) {
                mToast.error(data.data.err)
            }
        }
        else {
            mToast.error("Couldn't complete request. Something went wrong!")
        }
    })
    .catch(e => {
        const e_resp = e.response

        if (e_resp.status === 403) {
            mToast.error(e_resp.data.err)
        }
        else {
            mToast.error("Couldn't complete request. Something went wrong!")
        }
    })
}

export function Strategy({ userId, socketFunHandler, isAccEnable, accMargin }) {
    const [strategyList, setStrategyList] = useState([])
    const [clientMtm, setClientMtm] = useState(0)
    const [, updateParams] = useUrlParams()

    const commonSel = useSelector((state)=> state.common)
    const loginId = commonSel.loginId
    
    const [isMTMModalOpen, setIsMTMModalOpen] = useState(false)
    // const [isAccInfoModalOpen, setIsAccInfoModalOpen] = useState(false)
    const [isEdConfirmModalOpen, setIsEdConfirmModalOpen] = useState(false)

    // useEffect(()=>{
    //     if(userId){
    //         root.apis.orderConfig.get(userId)
    //         .then(d=>{
    //             setStrategyList(d.data.stgConfigs || [])
    //         })
    //         .catch(e=>{
    //             mToast.error(e.message)
    //         })
    //     }
    // },[userId])

    socketFunHandler.current.strategyMTM = (data) => {
        setStrategyList(data || [])
    }

    socketFunHandler.current.totalMTM = (data) => {
        setClientMtm(data)
    }

    // Client strategy menu anchor
    const [clAnchorEl, setClAnchorEl] = useState(null);
    const clMenuOpen = Boolean(clAnchorEl);

    const handleClMenuClick = (event) => {
        setClAnchorEl(event.currentTarget);
    };

    const handleClMenuClose = () => {
        setClAnchorEl(null);
    };

    return (
        <Box>
            <SectionHeading
                heading={"Strategy"}
                withDivider
            >
                <Box sx={{ display: 'flex', gap: 1, alignItems: "center" }}>
                    <Typography sx={{ color: clientMtm > 0 ? green[600] : red[600] }}>
                        MTM:
                    </Typography>
                    <Typography sx={{ color: clientMtm > 0 ? green[600] : red[600], fontWeight: 'bold', mr: 2 }}>
                        {formatePrice(clientMtm || 0)}
                    </Typography>

                    {/* Squareoff Button */}
                    <Button
                        variant="contained"
                        style={{ textTransform: "initial", marginBottom: 5 }}
                        onClick={() => {
                            
                            // root.apis.utils.square_off({
                            root.apis.orderConfig.enableDisableClientStrategies(parseInt(userId), true, true, `cl-square-off-${loginId}`)
                            .then((data) => {
                                if (data.status === 201) {
                                    if (data.data.msg) {
                                        mToast.success(data.data.msg)
                                    }

                                    if (data.data.err) {
                                        mToast.error(data.data.err)
                                    }
                                }
                                else {
                                    mToast.error("Couldn't complete request. Something went wrong!")
                                }
                            })
                            .catch(e => {
                                const e_resp = e.response

                                if (e_resp.status === 403) {
                                    mToast.error(e_resp.data.err)
                                }
                                else {
                                    mToast.error("Couldn't complete request. Something went wrong!")
                                }
                            })

                        }}
                    >
                        Square Off
                    </Button>

                    <Button
                        variant="contained"
                        color="primary" 
                        style={{ textTransform: "initial", marginLeft: 15, marginBottom: 5 }}
                        onClick={handleClMenuClick}
                    > Menu </Button>

                    <Menu
                        id="basic-menu"
                        anchorEl={clAnchorEl}
                        open={clMenuOpen}
                        onClose={handleClMenuClose}
                    >
                        <Box color="#000000">
                            <MenuItem
                                onClick={() => {
                                    if (isAccEnable) {
                                        setIsEdConfirmModalOpen(true);
                                    }
                                    else {
                                        handleAccElDl(userId, isAccEnable);
                                    }

                                    setClAnchorEl(null);
                                }}
                            >
                                {isAccEnable ? "Disable" : "Enable"} Account
                            </MenuItem>

                            <MenuItem
                                onClick={() => {setIsMTMModalOpen(true); setClAnchorEl(null);}}
                            >
                                Update MTM Limit For Account
                            </MenuItem>

                            {/* <MenuItem
                                onClick={() => {setIsAccInfoModalOpen(true); setClAnchorEl(null);}}
                            >
                                Account Info
                            </MenuItem> */}
                        </Box>
                    </Menu>
                </Box>
            </SectionHeading>
            
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: 140,
                        height: 70,
                    },
                    "& .MuiPaper-root": {
                        cursor: 'pointer',
                        textAlign: 'center',
                        "&[isup='0']": {
                            bgcolor: "rgba(0,0,0,0.02)"
                        },
                        "&[isup='true']": {
                            bgcolor: green[900]
                        },
                        "&[isup='false']": {
                            bgcolor: red[900]
                        },

                        "& [strategy-name]":{
                            overflow: "hidden",
                            display: "inline-block",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "inherit",
                            px: 1
                        }
                    },
                }}
            >
                {strategyList.map(str=><StrategyCard key={str.stgName} strategy={str} updateParams={updateParams} />)}
            </Box>

            <UpdateMTMModal
                open={isMTMModalOpen}
                handleClose={() => setIsMTMModalOpen(false)}
                userId={userId}
            />

            {
                isAccEnable
                ?
                    <EDConfirmModal 
                        open={isEdConfirmModalOpen}
                        handleClose={() => setIsEdConfirmModalOpen(false)}
                        userId={userId}
                        isAccEnable={isAccEnable}
                    />
                : ""
            }

            {/* <AccountInfoModal 
                open={isAccInfoModalOpen}
                handleClose={() => setIsAccInfoModalOpen(false)}
                accMargin={accMargin}
            /> */}
        </Box>
    )
}