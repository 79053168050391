import { netPositionColumns } from "components/body/positions/common/NetPosition";
import { NetPosition } from "components/body/positions/common/NetPosition";
import { useCustomSearchbar } from "./common/useCustomSearchbar";
import { SectionHeading } from "helpers/SectionHeading";
import { DropDown } from "helpers/DropDown";
import { useState, useEffect } from "react";
import { Socket } from "Root/Common/Socket";
import { mToast } from "Root/Common/Toast";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { root } from "Root/root";

export function TradeProfitAndLoss() {
    document.title = "Market Watch | Trade PnL";

    const [rowData, setRowData] = useState({
        beforeNetPLRowData: [],
        total: { mtm: 0, netQty: 0, buyQty: 0, avgBuyPrice: 0, sellQty: 0, avgSellPrice: 0, buyVal: 0, sellVal: 0 },
        afterNetPLRowData: [],
        isAfterNetPLRowDataVisible: false
    })
    const CustomSearchbar = useCustomSearchbar()
    const [mktPnLUsers, setMktPnLUsers] = useState([])
    const [pnlSelUser, setPnLSelUser] = useState({})

    const commonSel = useSelector(selector => selector.common)
    const lUser = commonSel.user

    const createPosData = (posData) => {
        const total = posData.total

        const beforeNetPLRowData = (posData.pos || []).map((pos)=>{
            const col = {}

            for (let column of netPositionColumns) {
                col[column.id] = pos[column.id]
            }
            return col
        })

        setRowData(prev=>({
            ...prev,
            beforeNetPLRowData,
            total
        }))
    }

    useEffect(() => {
        // Get users list

        if (lUser.is_admin) {
            root.apis.users.get()
            .then(data => {
                setMktPnLUsers(data.data.users.map((user) => {
                    return { label: user.userInfo.fullName, value: user.userInfo.fullName, userId: user.id, brokerUserId: user.brokerInfo.brokerUserId }
                }))
            })
            .catch(e=>{
                console.log("getUser API error:", e)
                mToast.error(e.message)
            })
        }
        else {
            setMktPnLUsers(lUser.users.map((user) => {
                return { label: user.f_name, value: user.f_name, userId: user.id, brokerUserId: user.broker_id }
            }))
        }
    }, [])

    const mktClientStgPingPong = async (socket, userId, brokerUserId) => {        
        while (socket.socket.readyState === WebSocket.OPEN) {
            console.log("Sending mktClientStgPingPong request")

            socket.send(JSON.stringify({"userId": userId, "brokerUserId": brokerUserId, "wbType": "client-strategy"}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const mktClientStgSocket = (pnlSelUser) => {
        const userId = pnlSelUser.userId
        const brokerUserId = pnlSelUser.brokerUserId

        if (brokerUserId) {
            const connData = {"userId": userId, "brokerUserId": brokerUserId, "wbType": "client-strategy"}
            const connQuery = new URLSearchParams(connData);
    
            // Create socket connection
            var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/position/?" + connQuery);
    
            socket.onOpen(async function () {
                console.log("Connecting to positions (client-strategy) broadcast websocket")
    
                socket.send(JSON.stringify(connData))
                await mktClientStgPingPong(socket, userId, brokerUserId);
            })
            
            socket.onMessage(function (data) {
                let posData = data.posData;
                let msg = data.msg;
    
                if (msg) {
                    console.log(msg)
                }

                if (posData) {
                    createPosData(posData.posData)
                }
            })

            var isMktPosSocketClose = false;
        
            socket.onClose(function(event) {
                console.log("Position (client-strategy) webSocket connection closed", event)

                // Attempt to reconnect to the websocket after a delay
                if (!isMktPosSocketClose) {
                    setTimeout(() => {
                        console.log(`Reconnecting to position (client-strategy) webSocket ...`);

                        mktClientStgSocket(pnlSelUser)
                    }, 1000);
                }
            })
    
            return () => {
                isMktPosSocketClose = true
                socket.close()
            }
        }
    }

    useEffect(() => {
        return mktClientStgSocket(pnlSelUser)
    }, [pnlSelUser])

    return (
        <Box>
            <SectionHeading
                heading={"Trade P&L"}
            >
                <Box display="flex" gap={3} alignItems="center">
                    <Box>
                        <DropDown
                            defaultValue="Select User..."
                            searchPlaceholder="Search User..."
                            value={mktPnLUsers.filter(obj => obj.selected)}
                            options={mktPnLUsers}
                            onSelect={(mktPnLUserElem, isSelected, isArray)=> {
                                mktPnLUsers.forEach(obj => {
                                    obj.selected = false
                                })
                                mktPnLUserElem.selected = isSelected

                                setMktPnLUsers([...mktPnLUsers])
                                setPnLSelUser(mktPnLUserElem)
                            }}
                        >
                            <CustomSearchbar />
                        </DropDown>
                    </Box>
                </Box>
            </SectionHeading>
            <Box mt={2}>
                <NetPosition
                    withHeading={false}
                    rowData={rowData}
                />
            </Box>
        </Box>
    )
}