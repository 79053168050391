import { Grid, Typography } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import { useState, forwardRef } from 'react';
import { green, orange, red } from "@mui/material/colors";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Box from "@mui/material/Box";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function Preview(data={}) {
    const [modelOpen, setModelOpen] = useState(false);
    const strategyList = data.data.strategyList
    const row = data.data

    const handleClose = () => {
        setModelOpen(false)
    }

    const OrderStatusList = {
        WK: {
            name: "Working",
            color: green
        },
        ST: {
            name: "Stopped",
            color: red
        },
        ER: {
            name: "Error",
            color: red
        },
        AL: {
            name: "Alert",
            color: orange
        }
    }

    return (
        <Box>
            <Tooltip title="Preview">                      
                <VisibilityIcon
                    onClick={() => setModelOpen(true)}
                />
            </Tooltip>

            <Dialog
                open={modelOpen}
                TransitionComponent={Transition}
                onClose={handleClose}
                keepMounted
                scroll="paper"
                maxWidth="md"
            >
                <DialogTitle>
                    <Grid
                        container
                        columnSpacing={{ xs: 4, sm: 4, md: 8 }}
                        rowSpacing={2}
                    >
                        <Grid item>
                            <Typography color="black" fontSize={18}>
                                User - { row?.id } ({ row?.fullName })
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {
                                strategyList.map(stg => {
                                    return (
                                        <Grid item xs={2} sm={4} md={4} key={stg.id}>
                                            <Grid item 
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        height: 15,
                                                        width: 15,
                                                        aspectRatio: "1/1",
                                                        borderRadius: "100%",
                                                        bgcolor:
                                                            stg.status
                                                                ? OrderStatusList[
                                                                        row[
                                                                            "status_" +
                                                                                stg.stgId
                                                                        ]
                                                                    ]
                                                                        ?.color[800]
                                                                : "transparent"
                                                    }}
                                                ></Box>

                                                <Box ml={1} color="black">
                                                    ( {stg.lots} )
                                                </Box>

                                                <Box ml={2} color="black">
                                                    {stg.stgName}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Box sx={{ textAlign: "center", width: "100%" }}>
                        <Box display="flex" justifyContent="center" >
                            <Button 
                                onClick={handleClose} 
                                variant="outlined" 
                                color="info" 
                                style={{ textTransform: "initial" }}
                            >Close</Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    )
}