import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { useMarketWatchColumns } from '../common/useMarketWatchColumns';
import { Box, Button, Divider, TextField } from "@mui/material";
import { PreviewOfOrderDialog } from './PreviewOfOrderDialog';
import { refreshTemplates } from '../common/marketWatchUtils';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PreviewIcon from '@mui/icons-material/Preview';
import CommitIcon from '@mui/icons-material/Commit';
import SaveIcon from '@mui/icons-material/Save';
import { marketWatch } from "redux/Reducer";
import { mToast } from 'Root/Common/Toast';
import { Table } from "helpers/Table";
import { useState } from "react";
import { root } from 'Root/root';

export function MWTable({ rows = [], dispatch = () => undefined, commonSel = {}, marketWatchSel = {}, mktUsers = [] }) {
    const columns = useMarketWatchColumns({
        exceptColumns: ["user"]
    })

    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
    const [tmptName, setTmptName] = useState("")
    const [orderTag, setOrderTag] = useState(null)

    const handleOnSaveTemplate = () => {
        const symbols = []

        if (tmptName === null || tmptName === "") {
            mToast.error("Please enter name for your template!")

            return
        }

        // Create payload to save the template
        rows.forEach(row => {
            if (!row._type) {
                symbols.push({
                    "excSegId": row.exc_seg_id,
                    "excInstId": row.exc_inst_id,
                    "qty": row.qty === "" ? 0 : row.qty,
                    "side": row.isBuy ? "Buy" : row.isBuy === false ? "Sell" : null
                })
            }
        })

        const payload = {
            "loginId": commonSel.loginId,
            "name": tmptName,
            "users": marketWatchSel.selectedUsers,
            "symbols": symbols
        }

        // Send request to save the template
        root.apis.marketWatch.addTemplate(payload)
        .then((data) => {
            const resp = data.data

            if (resp.msg) {
                mToast.success(resp.msg)

                setTmptName("")
            }
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp) {
                if (err_resp.status === 403) {
                    mToast.error(err_resp.data.err)
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!")
                }
            }
            else {
                mToast.error("Couldn't complete request. Something went wrong!")
            }
        })

        refreshTemplates(dispatch, commonSel)
    }

    const handleExecuteOrder = () => {
        const symData = marketWatchSel.data
        const userIds = marketWatchSel.selectedUsers
        const symbols = []

        if (!userIds.length) {
            mToast.error("Please select atleast 1 user before executing order!")
    
            return
        }

        symData.forEach(sym => {
            if (!sym._type) {
                var qty = sym.qty
                var isBuy = sym.isBuy

                if (qty > 0 && typeof isBuy === "boolean") {
                    symbols.push({
                        "excSeg": sym.exchange,
                        "name": sym.symbol,
                        "excSegId": sym.exc_seg_id,
                        "excInstId": sym.exc_inst_id,
                        "qty": sym.qty,
                        "side": sym.isBuy ? "Buy" : "Sell"
                    })
                }
            }
        })

        if (!symbols.length) {
            mToast.error("Please select atleast 1 symbol with quantity more than 0 and order side before executing order!")
    
            return
        }

        const payload = {
            "loginId": commonSel.loginId,
            "userIds": userIds,
            "tag": orderTag,
            "symbols": symbols
        }

        // Sending request to execute order
        root.apis.marketWatch.executeOrder(payload)
        .then((data) => {
            const resp = data.data

            if (resp.resp) {
                resp.resp.forEach(r => {
                    mToast.success(r.msg)
                    mToast.error(r.err)
                })
            }

            // Close the preview order popup after the order is executed
            setIsPreviewModalOpen(false)
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp) {
                if (err_resp.status === 403) {
                    mToast.error(err_resp.data.err)
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!")
                }
            }
            else {
                mToast.error("Couldn't complete request. Something went wrong!")
            }
        })
    }

    return (
        <Box
            className="custom-inputs"
            sx={{
                mt: 4,
                "& table": {
                    minWidth: 800,
                    "thead tr": {
                        // nth-child(n+4)
                        "th:nth-child(n+6)":{
                            width: 100
                        }
                    },
                    "tbody tr": {
                        "button.delete-row": {
                            visibility: "hidden"
                        },
                        ".MuiInputBase-root": {
                            borderRadius: 0,
                            "input": {
                                padding: 0
                            }
                        },
                        ".MuiCheckbox-root": {
                            color: "#c6bebe"
                        },
                        "&:hover": {
                            "button.delete-row": {
                                visibility: "visible"
                            },
                        },

                        "&:last-child": {
                            "td": {
                                py: 2,
                                fontWeight: "bold"
                            }
                        },
                        "td:nth-child(6)":{
                            borderLeft: "1px solid #fff"
                        },
                        "& td:nth-child(2) button:not(.MuiButton-contained)": {
                            borderColor: "#0cff0c",
                            color: "#26c026"
                        },
                        "& td:nth-child(3) button:not(.MuiButton-contained)": {
                            borderColor: "red" // "#d32f2f"
                        },
                    }
                }
            }}
        >

            <Table
                columns={columns}
                rows={rows}
                rowAttr={(row) => {
                    if (row.isBuy === true) {
                        return {
                            style: {
                                backgroundColor: "#0f5412"
                            }
                        }
                    }

                    if (row.isBuy === false) {
                        return {
                            style: {
                                backgroundColor: "#540f0f"
                            }
                        }
                    }

                    if (row.selected) {
                        return {
                            style: {
                                backgroundColor: "#313131"
                            }
                        }
                    }
                }}
            />

            {
                rows.length ?
                    <Box
                        sx={{
                            "& .mk-flex": {
                                mt: 3,
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                                "& button": {
                                    textTransform: "initial",
                                }
                            }
                        }}
                    >
                        <Box className="mk-flex">
                            <Box display="flex" justifyContent="end" alignItems="center" gap={3}>
                                <Button onClick={() => dispatch(marketWatch.actions.setData({ type: "reset" }))} variant="outlined" color="warning" startIcon={<RestartAltIcon />}>Reset</Button>
                                <Button disabled={!rows.some(r => 'boolean' === typeof r.isBuy)} onClick={() => dispatch(marketWatch.actions.setData({ type: "reverse" }))} variant="outlined" color="warning" startIcon={<FlipCameraAndroidIcon />}>Reverse Trade</Button>
                            </Box>
                            <Box display="flex" justifyContent="end" alignItems="center" gap={3}>
                                <TextField
                                    size='small'
                                    label="Order Tag"
                                    value={orderTag}
                                    onChange={(e) => { setOrderTag(e.target.value) }}
                                />
                                <Button disabled={!rows.some(r => "boolean" === typeof r.isBuy)} onClick={() => setIsPreviewModalOpen(true)} variant="outlined" color="info" startIcon={<PreviewIcon />}>Preview Order</Button>
                                <Button onClick={handleExecuteOrder} variant="contained" color="success" startIcon={<CommitIcon />}>Execute Order</Button>
                            </Box>
                        </Box>

                        <Divider color="#fff" sx={{ mt: 2 }} />

                        <Box className="mk-flex">
                            <Box display="flex" gap={3} alignContent="center">
                                <Box>
                                    <TextField
                                        size='small'
                                        label="Template Name"
                                        value={tmptName}
                                        onChange={(e) => { setTmptName(e.target.value) }}
                                    />
                                </Box>
                                <Button onClick={handleOnSaveTemplate} variant="outlined" startIcon={<SaveIcon />}>Save Template</Button>
                            </Box>
                            <Box display="flex" gap={3} alignContent="center">
                                {/* <Button onClick={() => dispatch(marketWatch.actions.setData({ type: "reset" }))} variant="outlined" color="warning" startIcon={<RestartAltIcon />}>Reset</Button>
                                <Button disabled={!rows.some(r => 'boolean' === typeof r.isBuy)} onClick={() => dispatch(marketWatch.actions.setData({ type: "reverse" }))} variant="outlined" color="warning" startIcon={<FlipCameraAndroidIcon />}>Reverse Trade</Button> */}
                            </Box>
                        </Box>

                    </Box>
                    : <Box width="100%" textAlign="center">No Data Available</Box>
            }

            <PreviewOfOrderDialog
                rows={rows}
                open={isPreviewModalOpen}
                dispatch={dispatch}
                onClose={() => setIsPreviewModalOpen(false)}
                handleOnSaveTemplate={handleOnSaveTemplate}
                handleExecuteOrder={handleExecuteOrder}
                tmptName={tmptName}
                orderTag={orderTag}
                setTmptName={setTmptName}
                setOrderTag={setOrderTag}
                mktUsers={mktUsers}
            />
        </Box>
    )
}
