import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Close from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function StrategyTracker({ open = false, handleClose = () => undefined, stgTracker = "" }) {

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			PaperProps={{
				sx: {
					minWidth: "60%",
					minHeight: "30%",
					color: "#000"
				}
			}}
		>
			<DialogTitle>
				<Box display="flex" alignContent="center" justifyContent="space-between">
					<Box>Strategy Status</Box>
					<Box><IconButton onClick={handleClose}><Close /></IconButton></Box>
				</Box>
			</DialogTitle>
			<DialogContent>
			<DialogContentText
				color="black"
			>
				<Box
					style={{ whiteSpace: "pre" }}
				>
					{stgTracker}
				</Box>
			</DialogContentText>
			</DialogContent>
		</Dialog>
	);
}
