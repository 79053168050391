import { SectionHeading } from "helpers/SectionHeading";
import { formatePrice } from "utils/formatPrice";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { ChartComponent } from "Root/Common/charts";

export function PAndLReport({ reportData }){
    const [chartChart, setChartData] = useState([])

    
    useEffect(()=>{
        if(reportData?.report){
            // reportData.pnlReport<Array<{ date: '2023-07-22T09:18:35', mtm: 24178.3}>>

            setChartData((reportData.pnlReport || []).map(e=>({
                value: Number(e.mtm),
                time: e.date // x format: "2023-03-10T18:30:00.000Z"
            })))
        }
    },[reportData?.report])
    

    return(
        <Box>
        <SectionHeading
            heading={`P&L Report - MTM: ${formatePrice(reportData?.totalMTM??0)}`}
            withDivider
        />

        <Box
            sx={{
                width: '100%',
                mt: 2
            }}
        >
            {
                chartChart.length ? <ChartComponent data={chartChart} withTime={false} /> : null
            }
        </Box>
    </Box>
    )
}