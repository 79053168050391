import { useMarketWatchColumns } from "./common/useMarketWatchColumns";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { useMemo, useState, forwardRef, useRef } from "react";
import { calculateTotal } from "./common/calculateTotal";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { SectionHeading } from "helpers/SectionHeading";
import PreviewIcon from "@mui/icons-material/Preview";
import DialogTitle from '@mui/material/DialogTitle';
import Close from "@mui/icons-material/Close";
import { mToast } from "Root/Common/Toast";
import Dialog from '@mui/material/Dialog';
import { useSelector } from "react-redux";
import Slide from '@mui/material/Slide';
import { Table } from "helpers/Table";
import { root } from "Root/root";
import calcGreek from "../Utils";

function PreviewOfPosition({ open = false, rows = [], onClose = () => undefined, handleSquareOff = () => undefined }) {
    const columns = useMarketWatchColumns({
        exceptColumns: ["selected", "ltp", "delta", "gamma", "theta", "iv", "vega", "delete", "sell"],
        customOptions: [
            { id: "exchange", align: "left", format(value) { return <Box ml={2}>{value}</Box>}},
            { id: "qty", align: "center", format(_, row) { return <Box>{row.newQty}</Box> } },
            { id: "buy", align: "left", format(_, row) { return <Box color={row.isBuy ? "red" : "green"}>{row.isBuy ? "Sell" : "Buy"}</Box> } },
        ],
        columnsOrder: ["exchange", "symbol", "qty", "sell"]
    })
    const Transition = useMemo(() => forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    }), [])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { }}
            scroll="paper"
            maxWidth="md"
            PaperProps={{
                sx: {
                    minHeight: "80%",
                    minWidth: "60%",
                    color: "#000",
                    "& table": {
                        "thead tr th": {
                            p: 2
                        },
                        "tbody tr td": {
                            color: "#000",
                            py: 2
                        }
                    }
                }
            }}
        >
            <DialogTitle>
                <Box display="flex" alignContent="center" justifyContent="space-between">
                    <Box>Preview Of Position</Box>
                    <Box><IconButton onClick={onClose}><Close /></IconButton></Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Table
                        columns={columns}
                        rows={rows.filter(col => col.newQty > 0)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{
                        mt: 3,
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "end",
                        "& button": {
                            textTransform: "initial"
                        }
                    }}
                >
                    <Box>
                        <Button variant="contained" color="success" onClick={handleSquareOff}>Square Off</Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

function PTable({ socketFuncHandler }) {
    const columns = useMarketWatchColumns({
        exceptColumns: ["selected","buy", "sell", "delete", "tradeAmount"],
        customOptions: [
            {
                id: "qty", label: "Net Qty", align: "left", format(value, row) {
                    return (
                        row._type === "total" ? ""
                            : (
                                <Box display="flex" gap={1} width={"100%"}>
                                    <Box color={row.qty !== 0 ? row.qty > 0 ? "green" : "red" : ""}>{value}</Box>
                                    <Box ml={2}>
                                        <TextField value={row.newQty} onChange={(e) => onChangeData(row, { newQty: parseInt(e.target.value) })} />
                                    </Box>
                                </Box>
                            )
                        )
                }
            },
        ],
        customOnChangeHandler: { selected(e, row) { onChangeData(row, { selected: e.target.checked }, { withCalcTotal: true }) } },
    })

    const [isPreviewModelOpen, setIsPreviewModelOpen] = useState(false)
    const commonSel = useSelector(selector => selector.common)
    const lUser = commonSel.user

    const updateFields = useSelector(selector => selector.marketWatch.updateFields)
    const ref = useRef({
        shouldUpdateNetQty: false
    })

    const [positionData, setPositionData] = useState([
        // { isBuy: false, user: "ABC", selected: false, qty: 6000, newQty: 6000, exchange: "NFO1", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
        // { isBuy: true, user: "ABC", selected: false, qty: 6000, newQty: 6000, exchange: "NFO2", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
        // { isBuy: false, user: "ABC", selected: false, qty: 6000, newQty: 6000, exchange: "NFO3", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
        // { isBuy: true, user: "ABC", selected: false, qty: 6000, newQty: 6000, exchange: "NFO4", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
        // { isBuy: false, user: "ABC", selected: false, qty: 6000, newQty: 6000, exchange: "NFO5", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
        // { isBuy: false, user: "ABC", selected: false, qty: 6000, newQty: 6000, exchange: "NFO5", symbol: "BANKNIFTY 23SEP2023 44000 CE", ltp: 432.00, delta: 0.5, gamma: 0.11, theta: 10.55, iv: 10.3, vega: 8.9 },
        // { _type: "total", ltp: 0, delta: 0, gamma: 0, theta: 0, iv: 0, vega: 0 }
    ])

    const onChangeData = (row = {}, newData = {}, config={}) => {
        if (isNaN(newData.newQty)) {
            newData.newQty = 0
        }
        
        Object.assign(row, newData)
        console.log("config=", config)

        if(config.withCalcTotal) calculateTotal(positionData);
        setPositionData(prev => [...prev])
    }

    // Get data for position table
    socketFuncHandler.current.position = (data) => {
        var pos = data[0]
        const vix = data[1]

        if (pos) {
            // Filter position data on the basis of users assigned to the login user
            const aUserIds = lUser.users.map((user) => user.user_id)

            pos = pos.filter((p) => aUserIds.includes(p.user_id))

            // Calculate greek values
            pos = calcGreek(pos, vix)
    
            // updateFields
            setPositionData(prev=>{
                if (!prev.length) {
                    pos.forEach((elem) => {
                        elem.newQty = elem.qty
                    })
    
                    return pos
                }
    
                pos.forEach((row) => {
                    const fe = prev.find(crow => crow.id === row.id)
                    
                    if(fe){
                        updateFields.forEach((field)=>{
                            fe[field] = row[field]
                        })
    
                        if (fe.qty !== row.qty) {
                            fe.qty = row.qty
                            fe.newQty = row.qty
                        }
                    }
                });
    
                // if (ref.current.shouldUpdateNetQty) {
                //     ref.current.shouldUpdateNetQty = false
                // }
    
                return [...prev]
            })
        }
    }

    // Get the notification message
    socketFuncHandler.current.notif = (msg) => {
        mToast.success(msg, {
            autoClose: 50
        })
    }

    const handleSquareOff = () => {
        const symbols = []

        positionData.forEach(pos => {
            if (pos.newQty <= 0) {
                mToast.error("Squareoff quantity should be greater than 0!")
            }

            if (pos.newQty > 0) {
                symbols.push({
                    "excSeg": pos.exchange,
                    "name": pos.symbol,
                    "excSegId": pos.exc_seg_id,
                    "excInstId": pos.exc_inst_id,
                    "sqQty": pos.newQty,
                    "userId": pos.user_id,
                    "side": pos.qty > 0 ? "Sell" : "Buy"
                })
            }
        })

        if (symbols.length) {
            const payload = {
                "loginId": commonSel.loginId,
                "symbols": symbols
            }
    
            // Send request to squareoff positions
            root.apis.marketWatch.squareOff(payload)
            .then((data) => {
                const resp = data.data
    
                if (resp.msg) {
                    mToast.success(resp.msg)
                
                    // ref.current.shouldUpdateNetQty = true
                }
            })
            .catch((err) => {
                const err_resp = err.response
    
                if (err_resp) {
                    if (err_resp.status === 403) {
                        mToast.error(err_resp.data.err)
                    }
                    else {
                        mToast.error("Couldn't complete request. Something went wrong!")
                    }
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!")
                }
            })
        }
    }

    return (
        <Box
            className="custom-inputs"
            sx={{
                mt: 4,
                "& table": {
                    minWidth: 800,
                    "tbody tr": {
                        "button.delete-row": {
                            visibility: "hidden"
                        },
                        ".MuiInputBase-root": {
                            borderRadius: 0,
                            "input": {
                                padding: 0
                            }
                        },
                        ".MuiCheckbox-root": {
                            color: "#c6bebe"
                        },
                        // "&:last-child": {
                        //     "td": {
                        //         py: 2,
                        //         fontWeight: "bold"
                        //     }
                        // },
                        "td:nth-child(5)":{
                            borderLeft: "1px solid #fff"
                        }
                    },
                    "thead tr":{
                        "th:nth-child(1)":{
                            minWidth: 100,
                        },
                        "th:nth-child(n+5)":{
                            width: 120
                        }
                    },
                }
            }}
        >
            <Table
                columns={columns}
                rows={positionData}
            />

            <Box display="flex" alignItems="center" justifyContent="space-between" gap={3} mt={3} sx={{ "& button": { textTransform: "initial" } }}>
                <Button variant="outlined" onClick={() => setIsPreviewModelOpen(true)} color="info" startIcon={<PreviewIcon />}>Preview Order</Button>
                <Button onClick={handleSquareOff} variant="contained" color="success">Square Off</Button>
            </Box>

            <PreviewOfPosition
                open={isPreviewModelOpen}
                rows={positionData}
                onClose={() => setIsPreviewModelOpen(false)}
                handleSquareOff={handleSquareOff}
            />
        </Box>
    )
}

export function Position({ socketFuncHandler }) {
    document.title = "Market Watch | Positions";

    return (
        <Box>
            <SectionHeading
                heading={"Position"}
            />
            <PTable socketFuncHandler={socketFuncHandler} />
        </Box>
    )
}