import { SectionHeading } from "helpers/SectionHeading";
import { useSmallScreen } from "helpers/useSmallScreen";
import { IconButton, Tooltip } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { ExportXLSX } from "helpers/ExportXLSX";
import { useEffect, useState } from "react";
import { DropDown } from "helpers/DropDown";
import { mToast } from "Root/Common/Toast";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { LogsList } from "./LogsList";
import Box from "@mui/material/Box";
import { root } from "Root/root";
import moment from "moment";

// logsObj={
//     "id": 25,
//     "userId": 17,
//     "stgId": 7,
//     "stgCode": "S-6",
//     "createdOn": "2023-06-28T06:42:23",
//     "logInfo": "Log created at 2023-06-28 12:12:22.859337"
// }

const logsColumns = [
    { id: "user", label: "User", format(value) { return <Box width={"100%"} marginRight={15}>{value}</Box> } },
    { id: "datetime", label: "Datetime", format(value){ return <Box width={"100%"} marginRight={25}>{moment(value).format("YYYY-MM-DD HH:mm:ss.SSS")}</Box> } },
    { id: "strategy", label: "Strategy", format(value) { return <Box width={"100%"} marginRight={15}>{value}</Box> } },
    { id: "category", label: "Category", format(value) { return <Box width={"100%"} marginRight={15}>{value}</Box> } },
    { id: "message", label: "Status", format(value) {
        return (

            <Box
                display="flex"
                gap={1}
                onClick={() => {
                    window.navigator.clipboard.writeText(value)
                    .then(() => {
                        console.log("Copied")
                    })
                    .catch((error) => {
                        console.log("Copy onClick Error:", error)
                    })
                }}
                alignItems="center"
            >
                <Box>
                    <IconButton
                        sx={{
                            p: 0,
                            m: 0,
                            verticalAlign: "center"
                        }}
                    >
                        <ContentCopy 
                            color="primary"
                            fontSize="small"
                        />
                    </IconButton>
                </Box>
                <Box>
                    <Tooltip
                        title={value}
                        followCursor={true}
                    >
                        <Box
                            sx = {{
                                // display: "-webkit-box",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                // WebkitLineClamp: 2,
                                // wordWrap: "nowrap"
                                whiteSpace: "nowrap",
                                maxWidth: 800
                            }}
                        >
                            {
                                value
                            }
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        )
    } }, // add more btn to show all. if content is greater then cell
    // { id: "logInfo", label: "Log Info", format(value) {
    //     return <Box sx={{ display: "none" }}>{value}</Box>
    // } }
]


export function Logs() {
    document.title = "Logs";

    const isBelowSm = useSmallScreen()
    const [logs, setLogs] = useState([])
    const [users, setUsers] = useState([])
    const [strategy, setStrategy] = useState([])
    const [category, setCategory] = useState([])
    const [filterItems, setFilterItems] = useState({
        user_id: '',
        stg_id: '',
        category: ''
    })


    const onFilter = (type, item={}) =>{
        const newFilters = { ...filterItems, [type]: item.id}
        setFilterItems(newFilters)
        getLogs(newFilters)
    }

    const getLogs = (newFilters, updateFilterItems=false) => {
        if (updateFilterItems) {
            setFilterItems(newFilters)
        }

        root.apis.logs.get(newFilters || filterItems)
        .then(d=>{
            const logsData = d.data.logs
            console.log('logs = ', logsData)
            
            setLogs((logsData || []).map(log=>({
                category: log.category,
                user: log.userId,
                datetime: log.createdAt,
                strategy: log.stgName,
                message: log.message,
                logInfo: log.logInfo
            })))

            setCategory([...new Set((logsData || []).map(log => log.category))].map(obj => ({
                id: obj,
                label: obj,
                value: obj
            })))
        })
        .catch(e=>{
            mToast.error(e.statusText)
        })
    }

    useEffect(()=>{

        // logs
        getLogs()

        // users
        root.apis.users.get()
        .then(d=>{
            setUsers((d.data.users || []).map(user=>({ label: user.userInfo.fullName, value: user.userInfo.fullName, id: user.id })))
        })
        .catch(e=>{
            mToast.error(e.statusText)
        })

        // strategy
        root.apis.strategy.get()
        .then(d=>{
            setStrategy((d.data.strategy || []).map(strategy=>({ label: strategy.name, value: strategy.name, id: strategy.name, code: strategy.code })))
        })
        .catch(e=>{
            mToast.error(e.statusText)
        })
        
    },[])

    console.log("filterItems", filterItems)

    return (
        <Box sx={{ minHeight: '50vh'}}>
            <SectionHeading
                heading={`Logs`}
            >
               <Box display={"flex"} gap={1}>
                    <DropDown
                        defaultValue="Select User"
                        value={users.filter(obj=>obj.selected)}
                        options={users}
                        onSelect={(user, isSelected, isArray)=> {
                            onFilter("user_id", user)

                            users.forEach(obj=>{
                                obj.selected = false
                            })

                            user.selected = isSelected
                            setUsers([...users])
                        }}
                    />

                    <DropDown
                        defaultValue="Select Strategy"
                        value={strategy.filter(obj=>obj.selected)}
                        options={strategy}
                        onSelect={(stg, isSelected, isArray)=> {
                            onFilter("stg_name", stg)

                            strategy.forEach(obj=>{
                                obj.selected = false
                            })

                            stg.selected = isSelected
                            setStrategy([...strategy])
                        }}
                    />

                    <DropDown
                        defaultValue="Select Category"
                        value={category.filter(obj=>obj.selected)}
                        options={category}
                        onSelect={(catg, isSelected, isArray)=> {
                            onFilter("category", catg)

                            category.forEach(obj=>{
                                obj.selected = false
                            })

                            catg.selected = isSelected
                            setCategory([...category])
                        }}
                    />

                    <Box display="flex" gap={1} alignItems="center">
                        <Button
                            size="small"
                            variant="contained"
                            sx={{
                                p: 0,
                                px: 1,
                                textTransform: "initial",
                                bgcolor: "red"
                            }}
                            onClick={() => {
                                users.forEach(obj=>{
                                    obj.selected = false
                                })

                                strategy.forEach(obj=>{
                                    obj.selected = false
                                })

                                category.forEach(obj=>{
                                    obj.selected = false
                                })

                                getLogs({
                                    user_id: '',
                                    stg_id: '',
                                    category: ''
                                }, true)
                            }}
                        >
                            Reset
                        </Button>
                    </Box>
               </Box>

                <Box>
                    <Button
                        variant="contained"
                        sx={{ textTransform: "initial", fontSize: isBelowSm ? 10: 'auto' }}
                        size="medium"
                        onClick={()=>{
                            const columns = [...logsColumns.map(column => column.label), "Log Info"]
                            const rows = [...logsColumns.map(column=>column.id), "logInfo"]

                            ExportXLSX("logs",{
                                columns: columns,
                                rows: logs.map(log=>rows.map(column=>log[column]))
                            })
                        }
                        } 
                    >
                        Export
                    </Button>
                </Box>
            </SectionHeading>

            <Box mt={2}>
                <LogsList 
                    logs={logs}
                    columns={logsColumns}
                />
            </Box>
        </Box>
    )
}