import { SectionHeading } from "helpers/SectionHeading";
import { ChartComponent } from "Root/Common/charts";
import { useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import { formatePrice } from "utils/formatPrice";
import { mToast } from "Root/Common/Toast";
import Box from "@mui/material/Box";
import { root } from "Root/root";

// https://devexpress.github.io/devextreme-reactive/react/chart/docs/guides/zoom-and-pan/
// https://devexpress.github.io/devextreme-reactive/react/chart/demos/area/simple-area/

export function IntradayPAndLChart({ userId, strategyId, socketFunHandler }) {
    const [chartData, setChartData] = useState({
        data: [],
        maxMTM: 0, // max profit & loass
        minMTM: 0 // min profit & loss
    })
    const chartRef = useRef({
        areaChart: undefined
    })

    useEffect(()=>{
        if(userId){
            root.apis.positions.intradayUser(userId, strategyId)
            .then(d=>{
                
                if(d.data.data){
                    var maxMTM = 0, minMTM = undefined

                    setChartData(prev=>({
                        ...prev,
                        data: d.data.data.map((data) => {
                            data.mtm = Number(data.mtm)

                            if (isNaN(data.mtm)) {
                                data.mtm = 0
                                console.warn("intraday data NaN ", data)
                            }

                            if (data.mtm > maxMTM) maxMTM = data.mtm;
                            if (minMTM === undefined) minMTM = data.mtm;
                            else if (data.mtm < minMTM ) minMTM = data.mtm;
                            return {
                                time: new Date(data.date) / 1000,
                                value: data.mtm
                            }
                        }),
                        maxMTM,
                        minMTM
                    }))
                }
                // console.log('api interaday=', d.data)
            })
            .catch(e=>{
                mToast.error(e.message)
            })

        }
    },[userId])

    socketFunHandler.current.liveMtm = (data) => {
        if (chartRef.current.areaChart) {
            chartRef.current.areaChart.update({
                time: new Date(data.date) / 1000,
                value: data.mtm
            })
        }
    }

    // console.log("chartData=  ", chartData)
    
    return (
        <Box>
            <SectionHeading
                heading={
                    <Box sx={{ display: "flex", gap: 1, "& .MuiTypography-root": { fontSize: 18 } }}>
                        <Typography>Indraday P&L</Typography>
                        <Typography>Max:</Typography>
                        <Typography sx={{ fontWeight: "bold" }}>₹{formatePrice(chartData.maxMTM)}</Typography>
                        <Typography>|</Typography>
                        <Typography>Min: </Typography>
                        <Typography sx={{ fontWeight: "bold" }}>₹{formatePrice(chartData.minMTM)}</Typography>
                    </Box>
                }
                withDivider
            />

            <Box
                sx={{
                    width: '100%',
                    mt: 2
                }}
            >
                {
                    chartData.data.length ? <ChartComponent data={chartData.data} chartRef={chartRef} /> : null
                }
            </Box>
        </Box>
    )
}