import { asideMenusWidth, topNavBarHeight } from "./AsideMenus"
import { useSmallScreen } from "helpers/useSmallScreen"
import { Fragment, useEffect, useRef, useState } from "react"
import { useUrlParams } from "utils/useUrlParams"
import { formatePrice } from "utils/formatPrice"
import { green, red } from "@mui/material/colors"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import { Socket } from "Root/Common/Socket"
import { Menu } from "@mui/icons-material"
import AppBar from "@mui/material/AppBar"
// import moment from "moment/moment"
import { useSelector } from "react-redux"
import Box from "@mui/material/Box"
import moment from "moment/moment";
import { root } from "Root/root"

function MenuIcon({ isBelowSm }) {
    const [, updateParams] = useUrlParams()
    return (
        isBelowSm ?
            <Box sx={{ alignSelf: "center", mr: 2 }}>
                <Menu
                    onClick={() => {
                        updateParams({ withDrawerOpen: true })
                    }}
                />
            </Box>
            : null
    )
}


// function SystemTime() {
//     const ref = useRef({
//         timer: null
//     })
//     const [date, setDate] = useState("")

//     useEffect(() => {
//         if (ref.current.timer) window.clearInterval(ref.current.timer)
//         ref.current.timer = window.setInterval(() => {
//             // 6th June, 2023 13:43:20:787 (Wed)
//             setDate(moment().format("HH:mm:ss:SSS"))
//         })
//     }, [])


//     return date
// }

function SystemTime() {
    const ref = useRef({
        timer: null
    })
    const [date, setDate] = useState("")

    useEffect(() => {
        if (ref.current.timer) window.clearInterval(ref.current.timer)
        ref.current.timer = window.setInterval(() => {
            // 6th June, 2023 13:43:20:787 (Wed)
            setDate(moment().format("HH:mm:ss:SSS"))
        })
    }, [])

    return date
}

export function TopNav() {
    const isBelowSm = useSmallScreen()
    const withSideBar = useSelector(s => s.common.withSideBar)
    const [state, setState] = useState({
        indicators: [
            {
                name: "NIFTY 50",
                excInst: 26000,
                price: 0.0,
                symbol: "₹",
                percChange: 0.0,
                futSym: "",
                futPrice: 0,
                futPercChange: 0
            },
            {
                name: "NIFTY BANK",
                excInst: 26001,
                price: 0.0,
                symbol: "₹",
                percChange: 0.0,
                futSym: "",
                futPrice: 0,
                futPercChange: 0
            },
            {
                name: "INDIA VIX",
                excInst: 26002,
                price: 0.0,
                symbol: "₹",
                percChange: 0.0
            },
            {
                name: "FIN NIFTY",
                excInst: 26034,
                price: 0.0,
                symbol: "₹",
                percChange: 0.0,
                futSym: "",
                futPrice: 0,
                futPercChange: 0
            },
            {
                name: "SENSEX",
                excInst: 26065,
                price: 0.0,
                symbol: "₹",
                percChange: 0.0,
                futSym: "",
                futPrice: 0,
                futPercChange: 0
            },
            {
                name: "MIDCAP",
                excInst: 26121,
                price: 0.0,
                symbol: "₹",
                percChange: 0.0,
                futSym: "",
                futPrice: 0,
                futPercChange: 0
            }
        ]
    })

    const hWatchPingPong = async (socket) => {        
        while (socket.socket.readyState === WebSocket.OPEN) {
            console.log("Sending hWatchPingPong request")

            socket.send(JSON.stringify({"connect": true}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const hWatchSocket = () => {
        const connData = {"connect": true}
        const connQuery = new URLSearchParams(connData);

        var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/header_watch/?" + connQuery);
        
        socket.onOpen(async function () {
            console.log("Connecting to header marketwatch websocket")

            socket.send(JSON.stringify({"connect": true}))
            await hWatchPingPong(socket);
        })
        
        socket.onMessage(function (data) {
            let indicatorsData = data.instData;
            let msg = data.msg;

            if (msg) {
                console.log(msg)
            }

            if (indicatorsData) {
                setState(prev => {
                    prev.indicators.forEach(pElem => {
                        indicatorsData.forEach(nElem => {

                            if (pElem.excInst === nElem.excInst) {
                                pElem.price = nElem.price
                                pElem.percChange = nElem.percChange
                                
                                if (nElem.futSym !== "") {
                                    pElem.futSym = nElem.futSym
                                    pElem.futPercChange = nElem.futPercChange
                                    pElem.futPrice = nElem.futPrice
                                }
                            }

                        })
                    })

                    return {...prev}
                })
            }
        })

        var isHWSocketClose = false;
    
        socket.onClose(function() {
            console.log("Header marketwatch websocket connection closed")

            // Attempt to reconnect to the websocket after a delay
            if (!isHWSocketClose) {
                setTimeout(() => {
                    console.log(`Reconnecting to header marketwatch websocket ...`);
    
                    hWatchSocket()
                }, 1000);
            }
        })

        return () => {
            isHWSocketClose = true
            socket.close()
        }
    }

    useEffect(() => {
        return hWatchSocket()
    }, [])

    // console.log("state", state)

    return (
        <AppBar
            className="scrollbar sh-2"
            // position="fixed"
            sx={{
                width: withSideBar ? `calc(100% - ${isBelowSm ? 0 : asideMenusWidth}px)` : '100%',
                // ml: `${isBelowSm ? 0 : asideMenusWidth}px`,
                height: topNavBarHeight + 40,
                overflow: 'auto',
                overflowY: "hidden"
            }}

        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
                pr: 2,
                height: '100%',
                bgcolor: "#000",
                borderBottom: "1px solid #fff",
                position: "relative",
            }}
            >

                <MenuIcon isBelowSm={isBelowSm} />
                {
                    !isBelowSm && !withSideBar ?
                        <Box display="flex" alignItems="center" height={100} bgcolor="#154A8E">
                            <img src={root.constant.ApplicationIcon.MAIN} height={50} width={200} />
                        </Box>
                        : null

                }

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        height: "100%",
                        alignItems: "center",
                        overflow: "auto",
                        pl: 2
                    }}
                    className="scrollbar sh-2"
                >
                    {
                        state.indicators.map((indicator, index) => {
                            return (
                                <Fragment key={indicator.name}>
                                    <Box
                                        sx={{
                                            // display: isBelowSm ? 'block' : "flex",
                                            display: "block",
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            mx: 0,
                                            gap: 1,
                                            textWrap: "nowrap",
                                            width: 150
                                        }}
                                    >
                                        <Typography sx={{ fontSize: isBelowSm ? 12 : 15 }}>
                                            {indicator.name}{!isBelowSm && <>:&nbsp;</>}
                                        </Typography>
                                        <Typography sx={{ fontSize: isBelowSm ? 15 : 18, color: indicator.percChange > 0 ? green[600] : red[600] }}>
                                            {/* {indicator.symbol} */}
                                            {formatePrice(indicator.price)} ({formatePrice(indicator.percChange)} %)
                                        </Typography>
                                        {
                                            indicator.futSym ?
                                                <Typography sx={{ fontSize: isBelowSm ? 15 : 18, color: indicator.futPercChange > 0 ? green[600] : red[600] }}>
                                                    F: {formatePrice(indicator.futPrice)} ({formatePrice(indicator.futPercChange)} %)
                                                </Typography>
                                            : ""
                                        }
                                    </Box>
                                    {
                                        (index === state.indicators.length - 1) ? "" : <Divider orientation="vertical" variant="middle" flexItem color="#6a6666" sx={{ height: "40%", mt: 3.5, mx: 1 }} />
                                    }

                                </Fragment>
                            )
                        })
                    }


                </Box>
            </Box>

            <Box
                sx={{
                    fontSize: isBelowSm ? 13 : 16,
                    bgcolor: "#000",
                    alignItems: "center",
                    pl: 1,
                    pt: 1
                }}
            >
                <Box sx={{ width: isBelowSm ? 134 : 186, pl: 1 }}>
                    <Box>{moment().format("MMMM DD, YYYY  (ddd)")}</Box>
                    <SystemTime />
                </Box>
            </Box>
        </AppBar>
    )
}
