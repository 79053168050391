import { useEffect } from "react";


export function useMkKeyboardEvent(){
    useEffect(()=>{
        const onKeyPress = (e) => {
            if(e.keyCode === 13){ // enter key
                if(document.activeElement){
                    var node = document.activeElement.querySelector(`.mk-tab-event-enter`)

                    if(!node){
                        node = document.activeElement
                    }

                    if(node){
                        if(node.getAttribute("type") === "checkbox"){
                            document.activeElement.dispatchEvent(
                                new MouseEvent('click', {
                                    bubbles: true,
                                    cancelable: true,
                                    view: window,
                                }));
                        }else{
                            node.click()
                        }
                    }
                }
            }
        }

        window.document.body.addEventListener('keyup', onKeyPress);
        return()=>{
            window.document.body.removeEventListener("keyup", onKeyPress)
        }

    },[])

}