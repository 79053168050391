import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AutoModeOutlinedIcon from '@mui/icons-material/AutoModeOutlined';
import { refreshTemplates } from '../common/marketWatchUtils';
import { SectionHeading } from "helpers/SectionHeading";
import { Box, Button, Grid } from "@mui/material";
import { marketWatch } from "redux/Reducer";
import { mToast } from 'Root/Common/Toast';
import { useSelector } from "react-redux";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from "react";
import { root } from 'Root/root';

function RenderTemplateCard({ dispatch = () => undefined, data=[], withDelete=true, commonSel = {} }) {
    const loadTemplate = (template) => {
        const payload = {
            "tmptId": template.id
        }

        root.apis.marketWatch.loadTemplate(payload)
        .then((data) => {
            const resp = data.data

            if (resp.msg) {
                mToast.success(resp.msg)
            }

            // Reset the data in the marketwatch table
            dispatch(marketWatch.actions.setData({
                data: []
            }))
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp) {
                if (err_resp.status === 403) {
                    mToast.error(err_resp.data.err)
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!")
                }
            }
            else {
                mToast.error("Couldn't complete request. Something went wrong!")
            }
        })
    }

    const deleteTemplate = (template) => {
        root.apis.marketWatch.deleteTemplate(template.id)
        .then((data) => {
            const resp = data.data

            if (resp.msg) {
                mToast.success(resp.msg)
            }
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp) {
                if (err_resp.status === 403) {
                    mToast.error(err_resp.data.err)
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!")
                }
            }
            else {
                mToast.error("Couldn't complete request. Something went wrong!")
            }
        })

        refreshTemplates(dispatch, commonSel)
    }

    return (
        <Grid container spacing={3} mt={3}>
            {
                data.map((template, i) => {
                    return (
                        <Grid item key={i}>
                            <Box color="#fff" sx={{ border: "1px solid #fff", padding: "10px" }}>
                                <Box px={1} textAlign="left">{template.tagName}</Box>
                                <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
                                    <Box display="flex" gap={2}>
                                        <Button 
                                            color="info" 
                                            startIcon={<AutoModeOutlinedIcon />}
                                            onClick={ (e) => {loadTemplate(template)} }
                                        >Load Template</Button>
                                        {
                                            withDelete ?
                                                <Button 
                                                    color="error" 
                                                    startIcon={<DeleteForeverOutlinedIcon />}
                                                    onClick={ (e) => {deleteTemplate(template)} }
                                                >Delete</Button>
                                            : null
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    )
                })
            }
        </Grid>

    )
}

export function Template({ dispatch = () => undefined }) {
    const [activeTab, setActiveTab] = useState('1');

    const commonSel = useSelector(selector => selector.common)
    const marketWatchSel = useSelector(selector => selector.marketWatch)

    // const [templateList,] = useState([
    //     { tagName: "Raju Strategy1" },
    //     { tagName: "Raju Strategy2" },
    //     { tagName: "Raju Strategy3" },
    //     { tagName: "Raju Strategy4" },
    //     { tagName: "Raju Strategy5" },
    // ])

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box
            mt={3}
            sx={{
                "& button": {
                    textTransform: "initial"
                }
            }}
            mb={20}
        >
            <SectionHeading heading="Template" withDivider={true}>
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        textColor='inherit'
                    >
                        <Tab value="1" label="Your"/>
                        <Tab value="2" label="Pre-Defined" />
                    </Tabs> 
                </Box>
            </SectionHeading>

            <RenderTemplateCard 
                dispatch={dispatch}
                data={marketWatchSel.templates} 
                withDelete={activeTab === "1"}
                commonSel={commonSel}
            />
        </Box>
    )

}