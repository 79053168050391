import { Button, Container, Divider } from "@mui/material";
import { useUrlParams } from "utils/useUrlParams";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Save from "@mui/icons-material/Save";
import { DropDown } from "helpers/DropDown";
import { useState, useEffect } from "react";
import { mToast } from "Root/Common/Toast";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { root } from "Root/root";

function SaveButton({ setSearchParams, onSumbit, usersList, isStgEditable }) {
    return (
        <Box display={"flex"} justifyContent={"end"} my={2}>
            <Box>
                <Box display={'flex'} justifyContent={'end'} gap={5} mt={2}>
                    <Button
                        variant="outlined"
                        style={{ textTransform: 'initial', color: '#707070' }}
                        onClick={() => {
                            setSearchParams()
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<Save />}
                        onClick={() => {onSumbit(usersList)}}
                        disabled={!isStgEditable}
                        sx={{
                            color: "white !important",
                            backgroundColor: "#2E7D32 !important",
                            opacity: isStgEditable ? 1 : 0.5
                        }}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Box>

    )
}

export function StrategyAssign() {
    const [searchParams, , setSearchParams] = useUrlParams()
    const [usersList, setUsersList] = useState([])
    const [assigned, setAssigned] = useState([])
    const [stgData, setStgData] = useState({})

    const stgId = searchParams.get("stgid")

    const onSumbit = (usersList) => {
        var payload = {
            "stgId": parseInt(stgId),
            "userIds": usersList.filter(u => u.selected).map(u => u.userId)
        }

        root.apis.strategy.assign(payload)
        .then((data) => {
            const resp = data.data.resp

            resp.forEach((r) => {
                if (r.msg) {
                    mToast.success(r.msg)
                }

                if (r.err) {
                    mToast.error(r.err)
                }
            })

            setSearchParams()
        })
        .catch(err => {
            if (err.response) {
                err = err.response.data.err
            }
            else {
                err = err.statusText
            }

            mToast.error(err)
        })
    }

    // Get assigned strategy details
    useEffect(() => {
        root.apis.strategy.get(stgId)
        .then(d => {
            if ("err" in d.data) {
                mToast.error(d.data.err)
            
            } else {
                setStgData(d.data)
            }
        })
        .catch(err => {
            console.log("Get Strategy By ID Error: ", stgId, err)
            mToast.error(err.statusText)
        })
    }, [])

    // Get assigned users
    useEffect(() => {
        root.apis.strategy.getAssignUser(stgId)
        .then((data) => {
            setAssigned(data.data.users)
        })
        .catch(err => { mToast.error(err.message) })
    }, [])

    // Fetch user details
    const createSelUser = (d) => {
        var isSelected = false
        
        if (assigned) {
            isSelected = assigned.includes(d.id);
        }

        return { userId: d.id, value: d.userInfo.fullName, selected: isSelected, label: d.userInfo.fullName }
    }

    useEffect(() => {
        root.apis.users.get()
        .then((d) => {
            setUsersList(d.data.users.map(d => createSelUser(d)))
        })
        .catch(err => { mToast.error(err.message) })
    }, [assigned])

    return (
        <Container disableGutters style={{ position: 'relative' }}  >
            <Box
                sx={{
                    width: '100%',
                }}
                className="custom-inputs"
                noValidate
                autoComplete="off"
            >
                <Box>
                    <Typography style={{
                        fontSize: 18,
                        textDecoration: 'underline',
                        textUnderlineOffset: 5,
                        color: "#5497db"
                    }}>
                        Assign Users To The Strategy
                    </Typography>
                </Box>

                <Box my={3}>
                    <Grid container spacing={{ xs: 2, md: 4 }}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                        >
                            <DropDown
                                multiselect
                                withSelectAllControl
                                defaultValue="Select Users"
                                searchPlaceholder="Search Users..."
                                value={usersList.filter(obj => obj.selected)}
                                options={usersList}
                                onSelect={(user, isSelected, isArray)=> {
                                    if (isArray) {
                                        user.forEach(obj => {
                                            obj.selected = isSelected
                                        })
                                    } else {
                                        user.selected = isSelected
                                    }
                                    setUsersList([...usersList])
                                }}
                            >
                                <TextField
                                    fullWidth
                                    disabled={!stgData.isEditable}
                                />
                            </DropDown>
                        </Grid>
                    </Grid>
                </Box>

                <Divider color="gray" />

                <SaveButton
                    setSearchParams={setSearchParams}
                    onSumbit={onSumbit}
                    usersList={usersList}
                    isStgEditable={stgData.isEditable}
                />
            </Box>
        </Container>
    )
}