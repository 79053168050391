import ReactApexChart from "react-apexcharts";
import React from "react";
import moment from "moment";
import { AreaChart } from "./AreaChart";
import { MChartJs } from "./ChartJs";
import { AreaChartD3 } from "./AreaChartD3";

const seriesData = [];
const seriesLabel = []

function createData(startDate, endDate, type) {
    let fromDate = moment(startDate)
    let toDate = moment(endDate)
    let diff = toDate.diff(fromDate, type)
    let lastNumber = 0
    for (let i = 0; i < diff; i++) {
      let date = moment(startDate).add(i, type).format()
      seriesData.push({
            x: date,
            y: lastNumber += 50 //Math.floor(Math.random() * 100)
            // y: Math.floor(Math.random() * 1000000)
        })
        seriesLabel.push(date)
    }
}

createData("01-Jan-2023", "03-Oct-2023", "days")


class ApexChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: "STOCK ABC",
                data: seriesData
            }],
            options: {
                fill: {
                    type: "gradient",
                    gradient: {
                      shadeIntensity: .8,
                      opacityFrom: 0.7,
                      opacityTo: 1,
                      stops: [0, 90, 100]
                    }
                },
                tooltip: {
                    theme: "dark"
                },
                chart: {
                    type: 'area',
                    height: 350,
                    zoom: {
                        enabled: true,
                        type: 'x',  // x | y | xy 
                        autoScaleYaxis: false,  
                        zoomedArea: {
                            fill: {
                                color: '#90CAF9',
                                opacity: 0.4
                            },
                            stroke: {
                                color: '#0D47A1',
                                opacity: 0.4,
                                width: 1
                            }
                        }
                    },
                    pan: {
                        enabled: true
                    },
                    toolbar: {
                        // autoSelected: "pan",
                        // autoSelected: "zoom",
                        show: false,
                        tools: {
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true,
                        }
                    },
                    event: {
                        // mouseMove: undefined,
                        // mouseLeave: undefined,
                        zoomed: function(chartContext, { xaxis }) {
                            console.log("zoomed", xaxis)
                            // ...
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    // curve: 'straight',
                    curve: 'smooth',
                    // lineCap: 'butt',
                    // colors: "red",
                    width: 0.5,
                    // dashArray: 0,
                },
                // markers: {
                //     enabled: false,
                //     size: 1,
                //     // colors: ["#000524"],
                //     // strokeColor: "#00BAEC",
                //     strokeWidth: 1
                // },
                title: {
                    text: 'Fundamental Analysis of Stocks',
                    align: 'left'
                },
                subtitle: {
                    text: 'Price Movements',
                    align: 'left'
                },
                labels: seriesLabel,
                xaxis: {
                    type: 'datetime',
                    // tickPlacement: "on"
                },
                yaxis: {
                    opposite: true,
                },
                legend: {
                    horizontalAlign: 'left'
                }
            },


        };
    }



    render() {
        return (
            <div id="chart">
                <ReactApexChart 
                    options={this.state.options} 
                    series={this.state.series} 
                    type="area" 
                    height={350} 
                />
            </div>
        )
    }
}

export function AreaChartApex() {
    return (
        // <ApexChart />
        // <AreaChart />
        // <MChartJs />
        <AreaChartD3 />
    )
}


