import { Configuration } from "./Configuration";
import Box from "@mui/material/Box";
import { root } from "Root/root";
import { useEffect, useRef } from "react";
import { Socket } from 'Root/Common/Socket';

import { StrategyModal } from "./StrategyModal";

export function OrderConfiguration() {
    document.title = "Order Configuration";

    const socketFunHandler = useRef({
        mgrData() {},
        mtmData() {},
        stgStatus() {}
    })

    const oMgtClientPingPong = async (socket) => {        
        while (socket.socket.readyState === WebSocket.OPEN) {
            console.log("Sending oMgtClientPingPong request")

            socket.send(JSON.stringify({"connect": true}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const oMgtClientSocket = () => {
        var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/order_mgt/");
        
        socket.onOpen(async function () {
            console.log("Connecting to order management websocket")

            socket.send(JSON.stringify({"connect": true}))
            await oMgtClientPingPong(socket);
        })
        
        socket.onMessage(function (data) {
            let oMgtData = data.data;

            if (oMgtData) {
                for (let tradeKey in oMgtData) {
                    if (typeof socketFunHandler.current[tradeKey] === "function") {
                        socketFunHandler.current[tradeKey] (oMgtData[tradeKey])
                    }
                }
            }
        });

        var isOMgtSocketClose = false;
    
        socket.onClose(function(event) {
            console.log("Order management websocket connection closed", event)

            // Attempt to reconnect to the websocket after a delay
            if (!isOMgtSocketClose) {
                setTimeout(() => {
                    console.log(`Reconnecting to order management websocket ...`);

                    oMgtClientSocket()
                }, 1000);
            }
        })
    
        return () => {
            isOMgtSocketClose = true
            socket.close()
        }
    }

    useEffect(() => {
        return oMgtClientSocket()
    }, [])

    return (
        <Box>
            <Configuration
                socketFunHandler={socketFunHandler}
            />

            {/* <StrategyModal /> */}
        </Box>
    )
}