const Strategy = (self) => ({

    get(stgId, params) {
        return self.axios.get(self.getRestUrl(stgId ? ["strategy", stgId] : "strategy"), { params })
    },
    create(payload={}) {
        return self.axios.post(self.getRestUrl(["strategy", "create"]), payload)
    },
    edit(stgId, payload={}) {
        return self.axios.post(self.getRestUrl(["strategy", "edit", stgId]), payload)
    },
    delete(stgId) {
        return self.axios.delete(self.getRestUrl(["strategy", "delete", stgId]))
    },
    tracker(params) {
        return self.axios.get(self.getRestUrl("strategy_tracker"), { params })
    },
    getPositions(params) {
        return self.axios.get(self.getRestUrl("strategy_pos"), { params })
    },
    getAssignUser(stgId) {
        return self.axios.get(self.getRestUrl(["strategy", "assign", "strategy", stgId]))
    },
    getAssignStg(userId) {
        return self.axios.get(self.getRestUrl(["strategy", "assign", "user", userId]))
    },
    assign(payload={}) {
        return self.axios.post(self.getRestUrl(["strategy", "assign"]), payload)
    },
    startStopProcess(payload, isStart) {
        return self.axios.post(self.getRestUrl("strategy/stg_op/" + (isStart ? "start" : "stop")), payload)
    },
    checkStgProcess() {
        return self.axios.post(self.getRestUrl(["strategy", "stg_op", "check_ps"]))
    }

})


export default Strategy