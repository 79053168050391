import { useSearchParams } from 'react-router-dom';

export function useUrlParams() {
    const [searchParams, setSearchParams] = useSearchParams();

    const updateParams = (paramsKeyValue={}) => {
        const params = {}
        searchParams.forEach((value, name) => {
            params[name] = value
        })
        
        setSearchParams(Object.assign(params, paramsKeyValue || {}))
    }

    const removeParams = () => {
        setSearchParams({})
    }

    return [searchParams, updateParams, removeParams, setSearchParams]
}