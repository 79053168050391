import { Box, IconButton, Typography, Button } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Close from '@mui/icons-material/Close';
import { handleAccElDl } from './Strategy';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EDConfirmModal({ open = false, handleClose = () => undefined, userId = undefined, isAccEnable = undefined }) {

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			PaperProps={{
				sx: {
					minWidth: "40%",
					minHeight: "20%",
					color: "#000"
				}
			}}
		>
			<DialogTitle>
				<Box display="flex" alignContent="center" justifyContent="space-between">
					<Box>Are you sure you want to disable account?</Box>
					<Box><IconButton onClick={handleClose}><Close /></IconButton></Box>
				</Box>
			</DialogTitle>

			<DialogContent>
				<Box display="flex">
					<Box color="red" mr={1.5}>*</Box> 
					<Box display="flex">
						<Typography fontWeight="bold">If you choose to disable your account:</Typography>
					</Box>
				</Box>
				<Box> 
					<ul>
						<li>No further trades will be executed, leaving existing positions open in both manual and strategies.</li>
						<li>All strategies will be permanently stopped. To re-enable them, you'll need to restart from the strategy menu.</li>
					</ul>
				</Box>

				<Box display={'flex'} justifyContent={'center'} gap={3} mt={3}>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                    >
                        No
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => { handleAccElDl(userId, isAccEnable); handleClose(); }}
                    >
                        Yes
                    </Button>
                </Box>
			</DialogContent>
		</Dialog>
	);
}
